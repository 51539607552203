<template>
  <div>
    <avatar-perfil :drawer="drawer" />
    <div class="menu-container">
      <v-list dense>
        <v-list-item-group
          active-class="check"
        >
          <div
            class="menu-container-item"
            v-for="(option, index) in options"
            :key="index"
          >
            <hr>
            <v-tooltip
              right
              color="#F54C11"
              :open-on-hover="true"
              :open-on-click="false"
              :open-on-focus="false"
              content-class="tooltip-container"
              :disabled="tooltipDisabled"
            >
              <template
                v-if="!option.props.collapsible"
                v-slot:activator="{ on, attrs }"
              >
                <v-list-item
                  class="py-2 px-0 my-2"
                  :to="option.name"
                  v-bind="attrs"
                  v-on="on"
                  @click="reset"
                >
                  <v-list-item-icon class="menu-icon-container principal-menu">
                    <v-icon v-text="option.icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="menu-label-container principal-menu">
                    <v-list-item-title
                      class="text-left text-decoration-none font-weight-bold"
                      v-text="option.label"
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template
                v-else
                v-slot:activator="{ on, attrs }"
              >
                <div v-if="option.props.groups.length > 1">
                  <v-list-group
                    class="menu-expansion-list"
                    no-action
                    v-bind="attrs"
                    v-on="on"
                    :ripple="false"
                    append-icon=""
                    prepend-icon=""
                    :value="expandList(option)"
                    sub-group
                    @click="expandMenu"
                    :group="option.name"
                  >
                    <template v-slot:activator>
                      <v-list-item-icon class="menu-icon-container">
                        <v-icon v-text="option.icon"></v-icon>
                      </v-list-item-icon>
                      <v-list-item-content class="menu-label-container">
                        <v-list-item-title
                          class="text-left text-decoration-none font-weight-bold"
                          v-text="option.label"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </template>
                    <div
                      v-for="(group, i) in option.props.groups"
                      :key="i"
                    >
                      <v-list subheader>
                        <v-subheader class="body-2 font-weight-bold">{{group.name}}</v-subheader>
                        <v-list-item
                          v-for="(child, index) in option.children"
                          :key="index"
                          :to="child.name"
                          :class="child.groupId === group.id ? 'd-block' : 'd-none'"
                          class="px-6"
                          active-class="px-6"
                          @click="reset"
                        >
                          <v-list-item-content>
                            <v-list-item-title class="text-left" v-text="child.label"></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </div>
                  </v-list-group>
                </div>
                <div v-else>
                  <v-list-group
                    class="menu-expansion-list"
                    no-action
                    v-bind="attrs"
                    v-on="on"
                    :ripple="false"
                    append-icon=""
                    prepend-icon=""
                    :value="expandList(option)"
                    sub-group
                    @click="expandMenu"
                    :group="option.name"
                  >
                    <template v-slot:activator>
                      <v-list-item-icon class="menu-icon-container">
                        <v-icon v-text="option.icon"></v-icon>
                      </v-list-item-icon>
                      <v-list-item-content class="menu-label-container">
                        <v-list-item-title
                          class="text-left text-decoration-none font-weight-bold"
                          v-text="option.label"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </template>
                    <v-list>
                      <v-list-item
                        :to="option.children[0].name"
                        class="px-6"
                        active-class="px-6"
                        @click="reset"
                      >
                        <v-list-item-content>
                          <v-list-item-title class="text-left" v-text="option.children[0].label"></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-list-group>
                </div>
              </template>
              <span class="font-weight-bold body-1">{{option.label}}</span>
            </v-tooltip>
            <hr style="display: none;">
          </div>
          <div
            class="menu-container-item d-lg-none"
          >
            <hr>
            <v-list-item
              class="py-2 px-0 my-2"
              active-class="check"
              @click="logout"
            >
              <v-list-item-icon class="menu-icon-container principal-menu">
                <v-icon v-text="'mdi-logout'"></v-icon>
              </v-list-item-icon>
              <v-list-item-content class="menu-label-container principal-menu">
                <v-list-item-title
                  class="text-left text-decoration-none font-weight-bold"
                  v-text="'Sair'"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <hr style="display: none;">
          </div>
        </v-list-item-group>
      </v-list>
    </div>
    <box-widgets
      :tooltipDisabled="tooltipDisabled" 
    />
  </div>
</template>

<script>
import AvatarPerfil from '@/components/templates/sidebar/AvatarPerfil.vue';
import BoxWidgets from '@/components/templates/sidebar/BoxWidgets.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'sidebarWidgets',
  components: {
    AvatarPerfil,
    BoxWidgets
  },
  props: {
    drawer: Boolean,
  },
  data: () => ({
    tooltipDisabled: false
  }),
  beforeMount() {
    this.tooltip()
  },
  computed: {
    ...mapGetters('auth', ['getSession', 'getDrawer']),
    options () {
      const profileId = this.getSession.profile_id
      const routes = this.$router.options.routes
      const options = routes
        .map((route) => {
          return {
            name: route.name,
            icon: route.icon,
            path: route.path,
            label: route.label,
            category: route.category,
            children: route.children,
            props: route.props
          }
        })
        .filter(
          (option) => 
            option.category === 'menu' && 
            option.props.visiblePerfil.includes(profileId)
        )
      return options
    },
  },
  watch: {
    getDrawer(value) {
      if (
        this.$vuetify.breakpoint.name === "lg" ||
        this.$vuetify.breakpoint.name === "xl"
      ) {
        if (value === true) {
          this.tooltipDisabled = true
        } else {
          this.tooltipDisabled = false
        }
      } else {
        this.tooltipDisabled = true
      }
    }
  },
  methods: {
    tooltip() {
      if (
        this.$vuetify.breakpoint.name === "lg" ||
        this.$vuetify.breakpoint.name === "xl"
      ) {
        if (this.getDrawer === true) {
          this.tooltipDisabled = true
        } else {
          this.tooltipDisabled = false
        }
      } else {  
        this.tooltipDisabled = true
      }
    },
    logout () {
      this.$store.dispatch('auth/logout')
      this.loading = false
    },
    expandList (option) {
      const current = this.$router.history.current.path
      let childrensPaths = option.children.map((child) => {
        return child.path
      })
      return childrensPaths.includes(current) ? true : false
    },
    reset () {       
      this.$emit('resetMenu')
    },
    expandMenu () {
      if (
        (this.$vuetify.breakpoint.name == 'xl' || this.$vuetify.breakpoint.name == 'lg') &&
        this.getDrawer == false
      ) {
        this.$store.commit('auth/setDrawer', true)
        this.tooltipDisabled = true
      }
    }
  }
}
</script>

<style scoped>
.v-application .check {
  background-color: #F54C11;
  border-radius: .3rem;
  padding-left: 1rem !important;
}
.check.v-list-item--link::before {
  background-color: transparent;
}
.menu-icon-container.principal-menu i {
  color: #F54C11;
}
.check .menu-icon-container.principal-menu i,
.check .menu-label-container.principal-menu .v-list-item__title {
  color: #fff;
}
.menu-container {
  margin-top: 6rem;
}
.menu-container-item hr {
  border: 0;
  border-top: 1px solid #eee;
}
div.menu-container-item:last-child hr:last-child{
  display: block !important;
}
</style>

<style>
@media screen and (min-width: 1264px) {
  #mini-menu .v-list-item {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  #mini-menu .menu-container-item {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }
  #mini-menu .v-list-item .menu-label-container {
    position: absolute !important;
    height: 0px;
    width: 0px;
  }
  #mini-menu .menu-icon-container {
    margin-right: 0px;
  }
  #mini-menu .check .menu-icon-container {
    /* border-right: 4px solid #FFBF00;
    margin-right: 0px; */
    padding-right: 10px;
    padding-left: 10px;
  }
  aside#mini-menu .check .menu-label-container {
    border: none;
  }
  #mini-menu .menu-expansion-list.v-list-group--active .v-list-group__header {
    border-radius: .3rem;
  }
  .v-application #mini-menu.v-navigation-drawer--mini-variant .menu-expansion-list.v-list-group--active.v-list-group--no-action .v-list-group__items {
    display: none;
  }
}
#drawer-mini-menu .v-list-item {
  -webkit-box-pack: initial;
  -ms-flex-pack: initial;
  justify-content: initial;
}
#drawer-mini-menu .menu-container-item {
  padding-right: 24px;
  padding-left: 24px;
}
#drawer-mini-menu .v-list-item .menu-icon-container {
  margin-right: 8px;
}
#drawer-mini-menu .v-list-item .menu-label-container {
  position: initial !important;
  height: initial;
  width: initial;
}
/* #drawer-mini-menu .check .menu-label-container {
  border-right: 4px solid #FFBF00;
} */
#mini-menu .menu-container-item {
  padding-right: 24px;
  padding-left: 24px;
}
/* #mini-menu .check .menu-label-container {
  border-right: 4px solid #FFBF00;
} */

#drawer-mini-menu .v-list-item--link::before, #mini-menu .v-list-item--link::before {
  border-radius: .3rem;
}

.menu-expansion-list .v-list-group__header {
  padding: 8px 0;
  margin: 8px 0;
}

.menu-expansion-list.v-list-group--active {
  background-color: #FF5C01;
  border-radius: .3rem;
  margin: 8px 0;
}

.menu-expansion-list.v-list-group--active .v-list-group__header {
  background-color: #F54C11;
  border-radius: .3rem .3rem 0 0; 
  margin: 0;
}

.menu-expansion-list.v-list-group--active .v-list-group__header .menu-label-container,
.menu-expansion-list.v-list-group--active .v-list-group__items .v-subheader,
.menu-expansion-list.v-list-group--active .v-list-group__items .v-list-item__title {
  color: #fff;
}
.menu-expansion-list.v-list-group--active .v-list--subheader .v-list-item--link::before,
.menu-expansion-list.v-list-group--active .v-list .v-list-item--link::before {
  background-color: #F9820B;
}
.menu-expansion-list.v-list-group--active .v-list--subheader .theme--light.v-list-item--active::before,
.menu-expansion-list.v-list-group--active .v-list .theme--light.v-list-item--active::before {
  opacity: 1;
}
.menu-expansion-list.v-list-group--active .v-list--subheader .theme--light.v-list-item:hover::before,
.menu-expansion-list.v-list-group--active .v-list .theme--light.v-list-item:hover::before {
  opacity: .8;
}
.menu-expansion-list.v-list-group--active .v-list--subheader .theme--light.v-list-item:hover .v-list-item__content,
.menu-expansion-list.v-list-group--active .v-list--subheader .theme--light.v-list-item--active .v-list-item__content,
.menu-expansion-list.v-list-group--active .v-list .theme--light.v-list-item:hover .v-list-item__content,
.menu-expansion-list.v-list-group--active .v-list .theme--light.v-list-item--active .v-list-item__content {
  position: relative;
  z-index: 100;
}
.v-application--is-ltr .v-list--dense .menu-expansion-list.v-list-group--sub-group .v-list-group__header {
  padding-left: 0;
}
.v-application--is-ltr .v-list--dense .menu-expansion-list.v-list-group--active.v-list-group--sub-group .v-list-group__header {
  padding-left: 1rem;
}
.v-navigation-drawer--mini-variant .menu-expansion-list.v-list-group--sub-group,
.v-navigation-drawer--mini-variant .menu-expansion-list.v-list-group--active.v-list-group--sub-group {
  display: flex;
}
.v-navigation-drawer--mini-variant .menu-expansion-list.v-list-group--active.v-list-group--no-action .v-list-group__items {
  display: inline;
}
.menu-expansion-list.v-list-group--active .v-list-item .menu-icon-container .v-icon .v-icon__component path,
.v-list-item--active .menu-icon-container.principal-menu .v-icon .v-icon__component path {
  fill: #fff;
}
.v-tooltip__content.tooltip-container.menuable__content__active {
  opacity: 1;
}
</style>
<template>
  <div>
    <h4 class="font-weight-bold subtitle-2 text-left souPrimary--text mb-2">{{infoField.text}}</h4>
    <v-text-field
      v-model="fieldValue"
      color="souPrimary"
      outlined
      dense
      solo
      flat
    ></v-text-field>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    infoField: Object
  },
  data: () => ({
    fieldValue: ''
  }),
  beforeMount() {
    this.verifyField()
  },
  computed: {
    ...mapGetters('orders',['getFilters'])
  },
  watch: {
    fieldValue (value) {
      this.$store.commit(
        'orders/setFilterTextAndSelect',
        {
          'filter': this.infoField.filterLabel,
          'value': value
        }
      )
    }
  },
  methods: {
    verifyField () {
      switch (this.infoField.filterLabel) {
        case 'filter_order_id':
          this.fieldValue = this.getFilters.filter_order_id
          break
        case 'filter_business_name':
          this.fieldValue = this.getFilters.filter_business_name
          break
        case 'filter_taxvat':
          this.fieldValue = this.getFilters.filter_taxvat
          break
        case 'filter_billing_region':
          this.fieldValue = this.getFilters.filter_billing_region
          break
        case 'filter_billing_full':
          this.fieldValue = this.getFilters.filter_billing_full
          break
        case 'filter_billing_address':
          this.fieldValue = this.getFilters.filter_billing_address
          break
        case 'filter_shipping_address':
          this.fieldValue = this.getFilters.filter_shipping_address
          break
        case 'filter_email_customer':
          this.fieldValue = this.getFilters.filter_email_customer
          break
        case 'filter_id_order_web_service':
          this.fieldValue = this.getFilters.filter_id_order_web_service
          break
        case 'filter_customer_id':
          this.fieldValue = this.getFilters.filter_customer_id
          break
        case 'filter_seller_rca':
          this.fieldValue = this.getFilters.filter_seller_rca
          break
        case 'filter_seller_name':
          this.fieldValue = this.getFilters.filter_seller_name
          break
        case 'filter_status_stock':
          this.fieldValue = this.getFilters.filter_status_stock
          break
        // case 'filter_nsu':
        //   this.fieldValue = this.getFilters.filter_nsu
        //   break
        default :
          this.fieldValue = ''
      }
    }
  }
}
</script>
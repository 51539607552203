<template>
  <v-col cols="12" lg="6" md="8" sm="10">
    <table v-show="!overlay" class="table align-middle">
      <tbody>
        <tr scope="row">
          <td class="text-center border col-6">Código do Cupom</td>
          <td class="text-center border">
            {{ couponDetails.coupon_code }}
          </td>
        </tr>

        <tr scope="row">
          <td class="text-center border col-6">Tipo de Cupom</td>
          <td class="text-center border">
            {{ couponDetails.coupon_type }}
          </td>
        </tr>

        <tr scope="row">
          <td class="text-center border">Valor do Desconto</td>
          <td class="text-center border">
            {{
              parseFloat(couponDetails.discount_value).toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })
            }}
          </td>
        </tr>

        <tr scope="row">
          <td class="text-center border">Porcentagem do Desconto</td>
          <td class="text-center border">{{ couponDetails.discount }}%</td>
        </tr>

        <tr scope="row">
          <td class="text-center border">Nome do Cupom</td>
          <td class="text-center border">
            {{ couponDetails.coupon_name }}
          </td>
        </tr>

        <tr scope="row">
          <td class="text-center border">Observações</td>
          <td class="text-center border">
            {{ couponDetails.description }}
          </td>
        </tr>
      </tbody>
    </table>
  </v-col>
</template>

<script>

export default {
  name: 'DetailsCoupon',
  props: {
    couponDetails: {
      generated_id: Number,
      budget_id: Number,
      budget_value: Number,
      discount: Number,
      discount_value: Number,
      initial_discount: Number,
      discount_total: Number,
      coupon_code: String,
      admin_id: Number,
      rca_id: Number,
      rule_id: Number,
      coupon_name: String,
      integrator_id: Number,
      description: String,
      integrator_trade: String,
      integrator_trade_name: String,
      integrator_name: String,
      active: Boolean,
      max_discount: Number,
      value_equipment: Number,
      budget_sou: undefined,
      generator_type: String,
      competitor: undefined,
      coupon_type: String,
      payment_type: String,
      status: String,
      coupons_added: undefined,
      competition_budget_pdf: String
    },
    overlay: Boolean
  }
}

</script>


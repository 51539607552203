<template>
  <div>
    <h4 class="font-weight-bold subtitle-2 text-left souPrimary--text mb-2">{{infoField.text}}</h4>
    <div class="d-flex">
      <label class="subtitle-2 font-weight-regular label-w text-left" for="valor">De</label>
      <v-text-field
        v-model="fieldValue.min"
        v-money="money"
        prefix="R$ "
        color="souPrimary"
        outlined
        dense
        solo
        flat
      ></v-text-field>
    </div>
    <div class="d-flex">
      <label class="subtitle-2 font-weight-regular label-w text-left" for="valor">Para</label>
      <v-text-field
        v-model="fieldValue.max"
        v-money="money"
        prefix="R$ "
        color="souPrimary"
        outlined
        dense
        solo
        flat
      ></v-text-field>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {VMoney} from 'v-money'

export default {
  directives: {money: VMoney},
  props: {
    infoField: Object
  },
  data: () => ({
    fieldValue: {
      min: '',
      max: ''
    },
    money: {
      decimal: ',',
      thousands: '.',
      prefix: '',
      suffix: '',
      precision: 2,
      masked: false
    }
  }),
  beforeMount() {
    this.verifyField()
  },
  computed: {
    ...mapGetters('orders', ['getFilters'])
  },
  watch: {
    fieldValue: {
      handler (value) {
        console.log(value)
        this.$store.commit(
          'orders/setFilterTextDuo',
          {
            'filter': this.infoField.filterLabel,
            'value': value
          }
        )
      },
      deep: true
    }
  },
  methods: {
    verifyField () {
      switch (this.infoField.filterLabel) {
        case 'filter_base_grand_total':
          this.fieldValue.min = this.getFilters.filter_base_grand_total.min
          this.fieldValue.max = this.getFilters.filter_base_grand_total.max
          break
        case 'filter_grand_total':
          this.fieldValue.min = this.getFilters.filter_grand_total.min
          this.fieldValue.max = this.getFilters.filter_grand_total.max
          break      
        case 'filter_subtotal':
          this.fieldValue.min = this.getFilters.filter_subtotal.min
          this.fieldValue.max = this.getFilters.filter_subtotal.max
          break
        case 'filter_total_refunded':
          this.fieldValue.min = this.getFilters.filter_total_refunded.min
          this.fieldValue.max = this.getFilters.filter_total_refunded.max
          break
      }
    }
  }
}
</script>

<style scoped>
.label-w {
  width: 4rem;
}
</style>
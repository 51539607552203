<template>
  <v-card>
    <v-card-actions class="justify-end">
      <div>
        <v-img
            class="mt-3 mb-1 buttom-close"
            :src="require('@/assets/close.svg')"
            contain
            @click="closeDialog()"
            height="20"
        >
        </v-img>
      </div>
    </v-card-actions>
    <v-card-text>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <h1 class="text-center title-dialog">
              {{ couponMessage.title }}
            </h1>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="10 mx-auto">
            <p class="message-dialog text-center">
              {{ couponMessage.body }}
            </p>
          </v-col>
        </v-row>
<!--        <v-btn
            class="col-12"
            height="50"
            v-show="validCoupon"
            elevation="0"
            color="#FF7D27"
            @click="closeDialog"
        >
          <p class="my-auto title-dialog-generate">Ir para lista de cupons</p>'
        </v-btn> -->
      </v-card-text>
    </v-card-text>
  </v-card>
</template>

<script>

export default {
  name: 'DialogCoupon',
  props: {
    couponMessage: {
      title: String,
      body: String
    },
    validCoupon: {
      type: Boolean
    },
    closeDialog: {
      type: Function
    }
  },
}

</script>

<style>
.title-dialog-generate {
  font-weight: bold;
  color: black !important;
}

.buttom-close:hover {
  cursor: pointer;
}

.message-dialog {
  font-family: "Roboto", sans-serif;
  line-height: 1.5;
}

.buttom-coupon {
  color: white !important;
}
</style>

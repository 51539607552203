
<template>
  <v-card>
    <v-overlay :value="overlay">
      <v-progress-circular
          color="#ff7d27"
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-card-actions class="justify-end">
      <div>
        <v-img
            class="mt-3 mb-1 buttom-close"
            :src="require('../../../assets/close.svg')"
            contain
            @click="closeDialog()"
            height="20"
            style="cursor: pointer"
        >
        </v-img>
      </div>
    </v-card-actions>
    <v-card-text v-show="!overlay">
      <v-row class="text-center mb-5">
        <h1 class="title-dialog">Detalhes do Cupom</h1>
      </v-row>

      <v-row justify="center">
        <details-coupon :overlay="overlay" :couponDetails="couponDetails" />
      </v-row>

      <v-row class="text-center mb-5">
        <h1 class="title-dialog">Detalhes do Orçamento</h1>
      </v-row>

      <v-row
          justify="center"
          v-if="couponDetails.coupon_type !== 'Bater Concorrência'"
      >
        <details-budget-sou :couponDetails="couponDetails" />
      </v-row>

      <v-row justify="center" v-else>
        <details-competitor :coupon="couponDetails" />
      </v-row>

      <v-row
          class="text-center mb-5"
          v-show="couponDetails.coupons_added.length > 0"
      >
        <h1 class="title-dialog">Cupons Atrelados</h1>
      </v-row>

      <v-row justify="center" v-show="couponDetails.coupons_added.length > 0">
        <details-coupon-attached :couponDetails="couponDetails" />
      </v-row>

      <div v-show="couponDetails.status !== 'ATIVO' && isUserSupCorridaOuro()">
        <v-row justify="center" v-show="edit">
          <v-col cols="12" lg="6" md="8" sm="10" class="my-0 py-0">
            <v-icon
                @click="edit = false"
                style="cursor: pointer"
                color="#00bfcf"
            >
              mdi-keyboard-backspace
            </v-icon>
          </v-col>
        </v-row>

        <v-row justify="center" v-show="!edit">
          <v-col cols="12" lg="6" md="8" sm="10" class="text-center">
            <v-btn
                class="mt-2 col-lg-3 col-md-3 col-sm-6 col-12"
                height="50"
                elevation="0"
                color="red"
                @click="
                updateCoupon({
                  discount: parseFloat(couponDetails.discount),
                  discount_value: couponDetails.discount_value,
                  discount_total: couponDetails.discount_total,
                  status: 'REPROVADO',
                  active: false,
                })
              "
            >
              <p class="my-auto text-white">Reprovar</p>
            </v-btn>

            <v-btn
                class="mt-2 col-lg-3 col-md-3 col-sm-6 col-12 ml-2"
                height="50"
                elevation="0"
                color="#00bb2d"
                v-show="percentage <= parseFloat(newDiscountValue)"
                @click="
                updateCoupon({
                  discount: parseFloat(couponDetails.discount),
                  discount_value: couponDetails.discount_value,
                  discount_total: couponDetails.discount_total,
                  status: 'P/ DIRETORIA',
                  active: false,
                })
              "
            >
              <p class="my-auto text-white">P/ Diretoria</p>
            </v-btn>

            <v-btn
                class="mt-2 col-lg-3 col-md-3 col-sm-6 col-12 ml-2"
                height="50"
                elevation="0"
                color="#00bb2d"
                v-show="percentage >= parseFloat(couponDetails.discount)"
                @click="
                updateCoupon({
                  discount: parseFloat(couponDetails.discount),
                  discount_value: couponDetails.discount_value,
                  discount_total: ((couponDetails.discount_total - couponDetails.discount_value) + newValue) ,
                  status: 'APROVADO',
                  active: true,
                })
              "
            >
              <p class="my-auto text-white">Aprovar</p>
            </v-btn>

            <v-btn
                class="mx-buttom mt-2 col-lg-3 col-md-3 col-sm-6 col-12"
                height="50"
                elevation="0"
                color="#FF7D27"
                @click="showEdit()"
            >
              <p class="my-auto text-white">Negociar</p>
            </v-btn>
          </v-col>
        </v-row>

        <v-row justify="center" v-show="edit">
          <v-col cols="12" lg="6" class="d-flex justify-content-center">
            <p class="mt-1">Escolha como prefere o valor:</p>
            <p :class="switchTypeValue ? '' : 'select'" class="mt-1 mx-4">%</p>
            <v-switch
                class="mt-0"
                v-model="switchTypeValue"
                color="#FF9C27"
                inset
            ></v-switch>
            <p :class="switchTypeValue ? 'select' : ''" class="mt-1">R$</p>
          </v-col>
        </v-row>

        <v-row class="py-0 my-0 justify-center" v-show="edit">
          <v-col cols="6" lg="2" md="3" sm="3" class="py-1">
            <p
                :class="validDiscountValue ? 'invalid-discount' : ''"
                class="text-end"
            >
              Valor do Desconto:
            </p>
          </v-col>
          <v-col cols="4" lg="2" md="2" sm="3" class="my-0 py-0">
            <v-text-field
                @keyup="formatDiscount()"
                v-model="newDiscountValue"
                outlined
                :prefix="switchTypeValue ? 'R$' : ''"
                required
                :color="validDiscountValue ? '#FF0000' : ''"
                :placeholder="switchTypeValue ? '000.00' : '0.0'"
                dense
                :suffix="switchTypeValue ? '' : '%'"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row
            v-show="!switchTypeValue && edit"
            class="py-0 my-0 justify-center"
        >
          <v-col cols="6" lg="2" md="3" sm="3" class="py-0">
            <p class="text-end">Valor Total ofertado:</p>
          </v-col>
          <v-col cols="4" lg="2" md="2" sm="3" class="py-0">
            <div :class="validDiscountValue ? 'invalid-discount' : ''">
              {{
                newValue.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })
              }}
            </div>
          </v-col>
        </v-row>

        <v-row justify="center" v-show="edit">
          <v-col cols="12" class="text-center">
            <v-btn
                class="mt-2 col-lg-3 col-md-3 col-sm-6 col-12"
                :disabled = "(newDiscountPerCent>percentage)"
                height="50"
                elevation="0"
                color="#00bb2d"
                @click="
                updateCoupon({
                  discount: newDiscountPerCent,
                  discount_value: newValue,
                  discount_total: ((couponDetails.discount_total - couponDetails.discount_value) + newValue),
                  status: 'APROVADO',
                  active: true,
                })
              "
            >
              <p class="my-auto text-white">
                {{
                  percentage >= parseFloat(newDiscountPerCent)
                      ? "Aprovar e "
                      : ""
                }}Atualizar
              </p>
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import DetailsCoupon from './DetailsCoupon.vue'
import DetailsBudgetSou from './DetailsBudgetSou.vue'
import DetailsCompetitor from './DetailsCompetitor.vue'
import DetailsCouponAttached from './DetailsCouponAttached.vue'
import { mapGetters } from 'vuex'
export default {
  name: 'DialogDetails',
  props: {
    coupon: {
      admin_id: Number,
      budget_id: Number,
      budget_value: String,
      coupon_code: String,
      discount: String,
      discount_value: String,
      discount_total: String,
      initial_discount: String,
      generated_id: Number,
      rule_id: Number,
      dialog: Boolean,
      coupon_name: String,
      description: String,
      integrator_id: Number,
      integrator_name: String,
      integrator_trade: String,
      integrator_trade_name: String,
      active: Boolean,
      max_discount: Number,
      value_equipment: Number,
      unused: Boolean,
      coupon_type: String,
      generator_type: String,
      payment_type: String,
      status: String,
    },
    filters: {
      findGeneratedId: String,
      findBudgetId: String,
      findAdminId: String,
      findAdminName: String,
      findAdminTradeName: String,
      findRuleId: String,
      findDiscount: String,
      findCouponCode: String,
      findCouponType: String,
      findBudgetMin: String,
      findBudgetMax: String,
      findDiscountMax: String,
      findDiscountMin: String,
      findStatus: String,
    }
  },
  components: { DetailsCoupon, DetailsBudgetSou, DetailsCompetitor, DetailsCouponAttached },
  beforeMount() {
    this.overlay = true
    this.loadDetail()
  },
  data: () => {
    return {
      overlay: false,
      edit: false,
      switchTypeValue: false,
      validDiscountValue: false,
      newDiscountValue: 0,
      newDiscountPerCent: 0,
      newValue: 0,
      couponDetails: {
        coupons_added: [],
        competition_budget_pdf: "",
        status: "",
        competitor: {
          name: "",
          brandInverter: "",
          powerInverter: "",
          amountInverter: "",
          powerPanel: "",
          brandPanel: "",
          amountPanel: "",
          powerSystem: "",
          valueBudget: "",
          wattValue: "",
          offer: "",
          pdfCompetitor: "",
          coverDiscount: "",
          valueRoof: "",
          typeStructure: "",
        },
        generated_id: 0,
        payment_type: "",
        coupon_type: "",
        budget_id: 0,
        budget_value: 0,
        discount: 0,
        discount_value: 0,
        discount_total: 0,
        initial_discount: 0,
        coupon_code: "Código",
        admin_id: 1,
        rca_id: 0,
        rule_id: 0,
        coupon_name: "Nome do cupom",
        integrator_id: 0,
        description: "Descrição",
        integrator_trade: "teste",
        integrator_trade_name: "teste",
        integrator_name: "teste",
        active: false,
        max_discount: 0,
        value_equipment: 0,
        budget_sou: {
          id: 100128,
          generators: { type: "multi", brand: "Marca do Inversor" },
          discountsActive: 0,
          totalDiscount: 0,
          value: 0,
          transferValue: 0,
          equipamentValue: 0,
          idIntegrator: 0,
          integratorName: "teste",
          fantasyIntegratorName: "Nome Fantasia",
          reasonIntegratorName: "Razão Social",
          consultantName: "Nome do Consultor",
          discountMaxBrl: 0,
          couponsActive: [],
          discountMaxPercent: 0,
          amountInverters: 0,
          powerInverter: 0,
          brandInverter: "Marca do Inversor",
          brandPanel: "Marca do Painel",
          powerPanel: 0,
          amountPanel: 0,
          powerSystem: 0,
          typeStructure: "Tipo de Estrutura",
          deliveryLocale: "Local de entrega",
          inGroup: false
        },
        generator_type: "",
      },
    }
  },
  watch: {
    switchTypeValue(value) {
      if (isNaN(this.newDiscountValue)) {
        this.newDiscountValue = 0
      }
      if (value) {
        this.newDiscountValue = this.newValue
      } else {
        this.newDiscountValue = (
            (this.newDiscountValue * 100) /
            this.coupon.value_equipment
        ).toFixed(4)
      }
    },
    newDiscountValue() {
      if (isNaN(this.newDiscountValue)) {
        return
      }
      if (parseFloat(this.newDiscountValue) > 100 && !this.switchTypeValue) {
        this.newDiscountValue = 100;
      }
      if (!this.switchTypeValue) {
        this.newValue = parseFloat(
            (
                (this.newDiscountValue * ((this.coupon.value_equipment * this.percentage) / 100)) /
                this.percentage
            ).toFixed(2)
        )
        this.newDiscountPerCent = this.newDiscountValue;
      } else {
        this.newDiscountPerCent =
            parseFloat(
                (this.newDiscountValue * 100) /
                this.coupon.value_equipment
            ).toFixed(4)
        this.newValue = parseFloat(this.newDiscountValue);
      }

      if (isNaN(this.newValue)) {
        this.newValue = 0;
      }

      if (this.newDiscountPerCent > this.percentage) {
        this.validDiscountValue = true;
      } else {
        this.validDiscountValue = false;
      }
    },
  },
  computed: {
    ...mapGetters('coupon', {budget: "getBudget"}),
    ...mapGetters('coupon',{percentage: 'getPercentage'}),
    ...mapGetters('coupon', {userInfo: 'getUserInfo'})
  },
  methods:{
    loadDetail(){
      this.$store.dispatch("coupon/getDetailsCoupon", this.coupon.generated_id).then((response) => {
        this.couponDetails.budget_sou.discountMaxPercent = this.percentage;
        this.couponDetails.budget_sou.discountMaxBrl =
            (this.coupon.value_equipment * this.percentage) / 100;
        this.couponDetails = response;
        this.newDiscountValue = response.discount;
        this.newDiscountPerCent = response.discount;
        this.newValue = response.discount_value;
        this.overlay = false;
      })
    },
    closeDialog(){
      // eslint-disable-next-line vue/no-mutating-props
        this.coupon.dialog = false;
    },
    showEdit(){
      this.edit = true;
    },
    formatDiscount() {
      const stringDiscount = this.newDiscountValue.toString();
      this.newDiscountValue = stringDiscount.replace(
          /[a-zA-Z\u00C0-\u00FF!@#+$%¨&*()/{}[/\]|`´^~;:?ªº§=°', "]+/i,
          ""
      )
    },
    isUserSupCorridaOuro(){
      if(this.couponDetails.budget_sou.inGroup){
        if (this.userInfo.role === 6) {
          return false;
        }else{
          return true;
        }
      }else{
        return true;
      }
    },
    updateCoupon(value){
      this.overlay = true;
      this.$store.dispatch('coupon/putCoupon', {'couponId' : this.couponDetails.generated_id, 'value' : value})
          .then((response) => {
            if (response) {
              this.$store.dispatch('coupon/getDetailsCoupon', this.coupon.generated_id).then((response) => {
                this.couponDetails = response;
                this.newDiscountValue = response.discount
                this.newDiscountPerCent = response.discount
                this.newValue = response.discount_value
                this.$store.dispatch('coupon/loadCoupons', this.filters).then(() => {
                  this.overlay = false
                })
              })
            }
          })
      this.edit = false
    }
  }
}
</script>

<style>
.title-dialog {
  font-weight: bold;
  color: #ff7d27 !important;
}
.theme--light.v-card > .v-card__text,
.theme--light.v-card > .v-card__subtitle {
  color: black;
}
@media (min-width: 576px) {
  .mx-buttom {
    margin-left: 5px;
    margin-right: 5px;
  }
}
.buttom-close:hover {
  cursor: pointer;
}
</style>

import PerfilMixin from '@/mixins/perfil_mixin';
import HomeView from '../views/HomeView.vue'
import LoginView from "@/views/LoginView";
import NotFound from '../views/NotFound.vue'
import BudgetsView from '../views/BudgetsView.vue'
import PromotionsView from '../views/PromotionsView.vue'
import OrdersView from '../views/sales/operations/OrdersView.vue'
import ReceiptsView from '../views/sales/operations/ReceiptsView.vue'
import CouponGeneratorView from '../views/sales/coupons/CouponGeneratorView.vue'
import CouponListView from '../views/sales/coupons/CouponListView.vue'
import CustomersListView from '../views/customers/CustomersListView.vue'
import SalesView from '../views/SalesView.vue'
import CustomersView from '../views/CustomersView.vue'
import MyAccountView from '../views/MyAccountView.vue'
import BusinessRulesView from '../views/BusinessRulesView.vue'

const routes = [
    {
      path: '/auth',
      name: 'login',
      label: 'Login',
      component: LoginView,
      category: '',
      props: {
        visiblePerfil: [],
        collapsible: false
      }
    },
    {
      path: '/',
      name: '/',
      label: 'Gestão Comercial',
      icon: '$gestaoComercial',
      component: HomeView,
      category: 'menu',
      props: {
        visiblePerfil: PerfilMixin.computed.homeViewPermissions(),
        collapsible: false
      }
    },
    {
      path: '/sales',
      name: 'sales',
      component: SalesView,
      label: 'Vendas',
      icon: '$vendas',
      category: 'menu',
      props: {
        visiblePerfil: PerfilMixin.computed.salesViewPermissions(),
        collapsible: true,
        groups: [
          {name: 'Operações', id: 1},
          {name: 'Cupons', id: 2}
        ]
      },
      children: [
        {
          path: '/orders',
          name: 'orders',
          label: 'Pedidos',
          component: OrdersView,
          groupId: 1
        },
        {
          path: '/receipts',
          name: 'receipts',
          label: 'Comprovantes',
          component: ReceiptsView,
          groupId: 1
        },
        {
          path: '/coupons-generator',
          name: 'coupons-generator',
          label: 'Gerador de cupons',
          component: CouponGeneratorView,
          groupId: 2
        },
        {
          path: '/coupons-list',
          name: 'coupons-list',
          label: 'Lista de cupons',
          component: CouponListView,
          groupId: 2
        }
      ]
    },
    {
      path: '/customers',
      name: 'customers',
      label: 'Clientes',
      icon: '$clientes',
      component: CustomersView,
      category: 'menu',
      props: {
        visiblePerfil: PerfilMixin.computed.customersViewPermissions(),
        collapsible: true,
        groups: [{name: 'Clientes', id: 1}]
      },
      children: [
        {
          path: '/list',
          name: 'list',
          label: 'Todos os clientes',
          component: CustomersListView,
          groupId: 1
        }
      ]
    },
    {
      path: '/budgets',
      name: 'budgets',
      label: 'Orçamentos',
      icon: '$orcamentos',
      component: BudgetsView,
      category: 'menu',
      props: {
        visiblePerfil: PerfilMixin.computed.budgetsViewPermissions(),
        collapsible: false
      }
    },
    {
      path: '/promotions',
      name: 'promotions',
      label: 'Promoções',
      icon: '$promocoes',
      component: PromotionsView,
      category: 'menu',
      props: {
        visiblePerfil: PerfilMixin.computed.promotionsViewPermissions(),
        collapsible: false
      }
    },
    {
      path: '/my-account',
      name: 'my-account',
      label: 'Minha conta',
      icon: '',
      component: MyAccountView,
      category: '',
      props: {
        visiblePerfil: PerfilMixin.computed.myAccountViewPermissions(),
        collapsible: false
      }
    },
    {
      path: '/business-rules',
      name: 'business-rules',
      label: 'Regras de negócio',
      icon: '',
      component: BusinessRulesView,
      category: '',
      props: {
        visiblePerfil: PerfilMixin.computed.businessRulesViewPermissions(),
        collapsible: false
      }
    },
    {
      path: '*',
      name: 'not-found',
      label: 'Not Found',
      component: NotFound,
      category: '',
      props: {
        visiblePerfil: [],
        collapsible: false
      }
    },
]

  export default routes

export default {
  computed: {
    initialHeaders() {
      return [
        'ID do Pedido',
        'Razão Social',
        'CNPJ',
        'Estado',
        'Valor Total',
        'Endereço da Empresa',
        'Status do Pedido',
        'Endereço de Entrega',
        'Forma de Pagamento',
        'Repasse',
        'Pré-venda'
      ]
    },
    defaultHeaders() {
      return [
        {
          width: '120px',
          text: 'ID do Pedido',
          fieldType: 'text',
          filterLabel: 'filter_order_id',
          align: 'start',
          value: 'order_id',
          active: true
        },
        { width: '120px', text: 'Razão Social', fieldType: 'text', filterLabel: 'filter_business_name',  value: 'business_name', active: true },
        { width: '120px', text: 'CNPJ', fieldType: 'text', filterLabel: 'filter_taxvat', value: 'taxvat', active: true },
        { width: '120px', text: 'Estado', fieldType: 'text', filterLabel: 'filter_billing_region', value: 'billing_region', active: true },
        { width: '120px', text: 'Valor Total', fieldType: 'textDuo', filterLabel: 'filter_base_grand_total', value: 'base_grand_total', active: true },
        { width: '120px', text: 'Endereço da Empresa', fieldType: 'text', filterLabel: 'filter_billing_full', value: 'billing_full', active: true },
        { width: '120px', text: 'Status do Pedido', fieldType: 'select', filterLabel: 'filter_order_status', value: 'order_status', active: true },
        { width: '120px', text: 'Forma de Pagamento', fieldType: 'select', filterLabel: 'filter_payment_method', value: 'payment_method', active: true },
        { width: '120px', text: 'Repasse', fieldType: 'date', filterLabel: 'filter_commission', value: 'commission', active: true },
        { width: '120px', text: 'Data de Compra', fieldType: 'date', filterLabel: 'filter_created_at', value: 'created_at', active: false },
        { width: '120px', text: 'Total Geral (Comprado)', fieldType: 'textDuo', filterLabel: 'filter_grand_total', value: 'grand_total', active: false },
        { width: '120px', text: 'Endereço de Cobrança', fieldType: 'text', filterLabel: 'filter_billing_address', value: 'billing_address', active: false },
        { width: '120px', text: 'Endereço de Entrega', fieldType: 'text', filterLabel: 'filter_shipping_address', value: 'shipping_address', active: true },
        { width: '120px', text: 'E-mail do Cliente', fieldType: 'text', filterLabel: 'filter_email_customer', value: 'email_customer', active: false },
        { width: '120px', text: 'Grupo de Clientes', fieldType: 'select', filterLabel: 'filter_customer_group', value: 'customer_group', active: false },
        { width: '120px', text: 'Subtotal', fieldType: 'textDuo', filterLabel: 'filter_subtotal', value: 'subtotal', active: false },
        { width: '120px', text: 'Total Reembolsado', fieldType: 'textDuo', filterLabel: 'filter_total_refunded', value: 'total_refunded', active: false },
        { width: '120px', text: 'Cod Pedido ERP', fieldType: 'text', filterLabel: 'filter_id_order_web_service', value: 'id_order_web_service', active: false },
        { width: '120px', text: 'ID do Cliente', fieldType: 'text', filterLabel: 'filter_customer_id', value: 'customer_id', active: false },
        { width: '120px', text: 'RCA Vendedor', fieldType: 'text', filterLabel: 'filter_seller_rca', value: 'seller_rca', active: false },
        { width: '120px', text: 'Nome do Vendedor', fieldType: 'text', filterLabel: 'filter_seller_name', value: 'seller_name', active: false },
        { width: '120px', text: 'Pré-venda', fieldType: 'date', filterLabel: 'filter_prevenda', value: 'prevenda', active: true },
        { width: '120px', text: 'Previsão de Expedição', fieldType: 'date', filterLabel: 'filter_shipping_forecast', value: 'shipping_forecast', active: false },
        { width: '120px', text: 'Situação de Estoque', fieldType: 'text', filterLabel: 'filter_status_stock', value: 'status_stock', active: false },
        { width: '120px', text: 'Previsão de Entrega', fieldType: 'date', filterLabel: 'filter_delivery_forecast', value: 'delivery_forecast', active: false },
        // { width: '120px', text: 'NSU', fieldType: 'text', filterLabel: 'filter_nsu', value: 'nsu', active: false },
        // { width: '120px', text: 'Método Offiline', fieldType: 'select', filterLabel: 'filter_offline_method', value: 'offline_method', active: false },
        { width: '120px', text: 'Carta de Crédito', fieldType: 'select', filterLabel: 'filter_credit_letter', value: 'credit_letter', active: false }
      ]
    },
    rcaHeaders() {
      return [
        {
          width: '120px',
          text: 'ID do Pedido',
          fieldType: 'text',
          filterLabel: 'filter_order_id',
          align: 'start',
          value: 'order_id',
          active: true
        },
        { width: '120px', text: 'Razão Social', fieldType: 'text', filterLabel: 'filter_business_name', value:'business_name', active: true },
        { width: '120px', text: 'CNPJ', fieldType: 'text', filterLabel: 'filter_taxvat', value:'taxvat', active: true },
        { width: '120px', text: 'Estado', fieldType: 'text', filterLabel: 'filter_billing_region', value:'billing_region', active: true },
        { width: '120px', text: 'Valor Total', fieldType: 'textDuo', filterLabel: 'filter_base_grand_total', value:'base_grand_total', active: true },
        { width: '120px', text: 'Endereço da Empresa', fieldType: 'text', filterLabel: 'filter_billing_full', value:'billing_full', active: true },
        { width: '120px', text: 'Status do Pedido', fieldType: 'select', filterLabel: 'filter_order_status', value:'order_status', active: true },
        { width: '120px', text: 'Forma de Pagamento', fieldType: 'select', filterLabel: 'filter_payment_method', value:'payment_method', active: true },
        { width: '120px', text: 'Repasse', fieldType: 'date', filterLabel: 'filter_commission', value:'commission', active: true },
        { width: '120px', text: 'Data de Compra', fieldType: 'date', filterLabel: 'filter_created_at', value:'created_at', active: false },
        { width: '120px', text: 'Total Geral (Comprado)', fieldType: 'textDuo', filterLabel: 'filter_grand_total', value:'grand_total', active: false },
        { width: '120px', text: 'Endereço de Cobrança', fieldType: 'text', filterLabel: 'filter_billing_address', value:'billing_address', active: false },
        { width: '120px', text: 'Endereço de Entrega', fieldType: 'text', filterLabel: 'filter_shipping_address', value:'shipping_address', active: true },
        { width: '120px', text: 'E-mail do Cliente', fieldType: 'text', filterLabel: 'filter_email_customer', value:'email_customer', active: false },
        { width: '120px', text: 'Grupo de Clientes', fieldType: 'select', filterLabel: 'filter_customer_group', value:'customer_group', active: false },
        { width: '120px', text: 'Subtotal', fieldType: 'textDuo', filterLabel: 'filter_subtotal', value:'subtotal', active: false },
        { width: '120px', text: 'Total Reembolsado', fieldType: 'textDuo', filterLabel: 'filter_total_refunded', value:'total_refunded', active: false },
        { width: '120px', text: 'Cod Pedido ERP', fieldType: 'text', filterLabel: 'filter_id_order_web_service', value:'id_order_web_service', active: false },
        { width: '120px', text: 'ID do Cliente', fieldType: 'text', filterLabel: 'filter_customer_id', value:'customer_id', active: false },
        { width: '120px', text: 'RCA Vendedor', fieldType: 'text', filterLabel: 'filter_seller_rca', value:'seller_rca', active: false },
        { width: '120px', text: 'Nome do Vendedor', fieldType: 'text', filterLabel: 'filter_seller_name', value:'seller_name', active: false },
        { width: '120px', text: 'Pré-venda', fieldType: 'date', filterLabel: 'filter_prevenda', value:'prevenda', active: true },
        { width: '120px', text: 'Previsão de Expedição', fieldType: 'date', filterLabel: 'filter_shipping_forecast', value:'shipping_forecast', active: false },
        { width: '120px', text: 'Situação de Estoque', fieldType: 'text', filterLabel: 'filter_status_stock', value:'status_stock', active: false },
        { width: '120px', text: 'Previsão de Entrega', fieldType: 'date', filterLabel: 'filter_delivery_forecast', value:'delivery_forecast', active: false },
        // { width: '120px', text: 'NSU', fieldType: 'text', filterLabel: 'filter_nsu', value:'nsu', active: false },
        // { width: '120px', text: 'Método Offiline', fieldType: 'select', filterLabel: 'filter_offline_method', value:'offline_method', active: false },
        { width: '120px', text: 'Carta de Crédito', fieldType: 'select', filterLabel: 'filter_credit_letter', value:'credit_letter', active: false }
      ]
    },
    customersHeaders() {
      return [
        'ID do Pedido',
        'Razão Social',
        'Status do Pedido',
        'Forma de Pagamento',
        'Repasse',
        'Data de Compra',
        'Total Geral (Comprado)',
        'Endereço de Entrega',
        'Pré-venda',
        'Previsão de Expedição',
        'Previsão de Entrega'
      ]
    }
  }
}

export default {
    computed: {
        ImagesCarrosselLogin () {
            const imagesLogin = [

                {
                    img: require('@/assets/TelaLogin/Sol.png'),
                    description: '',
                    title: 'ENERGIA QUE ',
                    subtitle: 'DÁ VIDA',
                    titleTag: 'h1'
                },

                {
                    img: require('@/assets/TelaLogin/Mandala.png'),
                    description: 'Acompanhar pedidos, despachar pedidos, ver e definir formas de pagamentos.',
                    title: 'VENDAS',
                    titleTag: 'h2',
                    class: 'title-carousel'
                },

                {
                    img: require('@/assets/TelaLogin/Frame.png'),
                    description: 'Pode visualizar o catálogo de produtos e suas determinadas categorias.',
                    title: 'PRODUTOS',
                    titleTag: 'h2',
                    class: 'secondary-titles'
                },

                {
                    img: require('@/assets/TelaLogin/1610 1.png'),
                    description: 'Ajuda a resolver qualquer eventualidade da sua loja sem precisar sair do painel de administração.',
                    title: 'SUPORTE',
                    titleTag: 'h2',
                    class: 'secondary-titles'
                }
            ]
            return imagesLogin
        }
    }
}

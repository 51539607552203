const config = require('../../../../config')

export default {

    async getSessionUser({rootState, commit}) {
        const myHeaders = new Headers();
        const token = rootState.auth.session.token;
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${token}`);
        //eslint-disable-next-line
        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };
        const url = config.urlGenerator.concat(`index/${token}`);
        return await fetch(url, requestOptions).then(async (response) => {
            const resp = response.json();
            return resp.then((body) => {
                if (body.success) {
                    commit("setListCompetitor", body.competitions)
                    commit("setUserData", { role: body.user_role, token, rca_id: body.user_id })
                    return true;
                }
                else {
                    return false
                }
            });
        });
    },

    async getBudget({rootState, commit}, idBudget) {
        let myHeaders = new Headers()
        const token = rootState.auth.session.token;
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer `.concat(token));
        const raw = JSON.stringify({
            id: parseInt(idBudget.budget),
            user_role: rootState.coupon.userData,
        });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        const url = config.urlGenerator.concat('budget')
        return await fetch(url, requestOptions)
            .then(async (response) => {
                const resp = response.json();
                if (response.status >= 500) {
                    commit("setExistCoupon", true);
                    const respError = {
                        success: false,
                        couponMessage: {
                            title: "Erro!",
                            body: "Entre em contato com o responsável pelo site!",
                        },
                    };
                    return respError;
                }
                return resp.then((body) => {
                    if (body.success) {
                        const budget = body.info;
                        const inverters = {
                             amount: 0,
                             power: 0,
                             brand: "N/A",
                         };
                        const panels = {
                            amount: 0,
                            power: 0,
                            brand: "N/A",
                        };
                        let structures = "N/A";
                        if (typeof body.budget_analise !== "undefined") {
                            if (typeof body.budget_analise.inverters !== "undefined") {
                                inverters.brand = "";
                                body.budget_analise.inverters.forEach(inverter => {
                                    inverters.amount += inverter.amount;
                                    inverters.power = inverter.potency;
                                    inverters.brand = inverter.name + " \n " + inverters.brand;
                                });
                            }
                            if (typeof body.budget_analise.panels !== "undefined") {
                                panels.brand = ""
                                body.budget_analise.panels.forEach(panel => {
                                    panels.amount += panel.amount;
                                    panels.power += panel.potency;
                                    panels.brand = panel.name;
                                });
                            }
                            if (typeof body.budget_analise.structures !== "undefined") {
                                body.budget_analise.structures.forEach((structure, index) => {
                                    if (index === 0) {
                                        structures = ""
                                    }
                                    structures = structure.name + " \n " + structures;
                                });
                            }
                        }
                        const generators = {
                            type: "N/A",
                            brand: "N/A",
                        };
                        body.budget_analise.generators.forEach(
                            (generator, index) => {
                                if(index === 0) {
                                    generators.brand = ""
                                    generators.type = ""
                                }
                                generators.brand =
                                    generators.brand +
                                    (generator.brand !== "" ? generators.brand + (index + 1 < body.budget_analise.generators.length ? "," : "") : "");
                                if (index > 0 && generators.type !== generator.type) {
                                    generators.type = "Múltiplos";
                                } else {
                                    generators.type = generator.type;
                                }
                            }
                        );
                        commit("setGenerators", body.budget_analise.generators);
                        const couponsActive = []
                        if (body.coupons.length !== 0) {
                            body.coupons.forEach((coupon, index) => {
                                couponsActive[index] = {
                                    id: coupon.rule_id,
                                    name: coupon.name,
                                    value: parseFloat(coupon.discount_amount),
                                    use: 0,
                                    maxUse: coupon.uses_per_customer - coupon.times_used,
                                };
                            });
                        }
                        const setBudget = {
                            id: body.info.id,
                            generators: generators,
                            discountsActive: 0,
                            totalDiscount: body.value_total_discount,
                            value: body.value_budget,
                            transferValue: body.value_transfer,
                            equipamentValue: body.value_equipament,
                            idIntegrator: budget.id_integrador,
                            integratorName: body.integrator_name,
                            fantasyIntegratorName: body.trade_name,
                            reasonIntegratorName: body.trade,
                            consultantName: budget.name_admin_seler,
                            discountMaxBrl: body.value_max_discount,
                            couponsActive: couponsActive,
                            discountMaxPercent: parseFloat(((body.value_max_discount * 100) / body.value_equipament).toFixed(4)),
                            amountInverters: inverters.amount,
                            powerInverter: inverters.power,
                            brandInverter: inverters.brand,
                            brandPanel: panels.brand,
                            powerPanel: panels.power,
                            amountPanel: panels.amount,
                            powerSystem: (panels.amount * panels.power) / 1000,
                            typeStructure: structures,
                            deliveryLocale: body.info.city + ", " + body.info.uf,
                            inGroup:body.group_ouro,
                        }
                        const codeCoupon = String(setBudget.id) + setBudget.consultantName.substr(0, 3).toUpperCase();

                        if (setBudget.couponsActive.length > 0) {
                            const options = setBudget.inGroup ? ["Ajuste de Valor"]: ["Desconto Comercial","Bater Concorrência","Soma de descontos"];
                            commit("setTypesCoupon", options)
                        } else {
                            const options2 = setBudget.inGroup ? ["Ajuste de Valor"]: ["Desconto Comercial","Bater Concorrência"];
                            commit("setTypesCoupon", options2)
                        }
                        commit("setCodeCoupon", codeCoupon);
                        commit("cleanBudget");
                        commit("setBudget", setBudget);
                        commit("setExistCoupon", false);
                        const respSuccess = {
                            success: true,
                            couponMessage: {
                                title: "",
                                body: "",
                            },
                        };
                        return respSuccess;
                    } else {
                        commit("setExistCoupon", true);
                        const respError = {
                            success: false,
                            couponMessage: {
                                title: "Erro!",
                                body: "Já existe um cupom para esse orçamento!",
                            },
                        };
                        return respError;
                    }
                });
            })
            .catch(() => {
                commit("setExistCoupon", true);
                const respError = {
                    success: false,
                    couponMessage: {
                        title: "Erro!",
                        body: "Ocorreu um erro com o Id do orçamento! Verifique se o Id do orçamento está correto!",
                    },
                };
                return respError;
            });
    },

    async storeCoupon({rootState, commit}, coupon ) {
        const token = rootState.auth.session.token;
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);
        const data = new FormData();
        data.append("info_generator", JSON.stringify([rootState.coupon.budget.generators]));
        data.append("coupon_name", coupon.nameCoupon);
        data.append("description", coupon.description);
        data.append("coupon_code", coupon.codeCoupon);
        data.append("coupon_discount", coupon.couponTotalValueBrl.toString());
        data.append("budget_value", coupon.budgetValue.toString());
        data.append("budget_id", rootState.coupon.budget.id);
        data.append("admin_id", rootState.coupon.userData.role.toString());
        data.append("rca_id", rootState.coupon.userData.rca_id.toString());
        data.append("integrator_id", rootState.coupon.budget.idIntegrator.toString());
        data.append("integrator_name", rootState.coupon.budget.integratorName);
        data.append("integrator_trade", rootState.coupon.budget.fantasyIntegratorName);
        data.append("payment_type", coupon.typePayment);
        data.append("coupon_type", coupon.typeCoupon);
        data.append("info_generator_type", rootState.coupon.budget.generators.type);
        const couponsUsed = [];
        rootState.coupon.budget.couponsActive.forEach((value, index) => {
            if (value.use > 0) {
                couponsUsed[index] = {
                    rule_id: value.id,
                    qtd_used: value.use,
                };
            }
        });
        if (couponsUsed.length > 0) {
            data.append("coupons_used", JSON.stringify(couponsUsed.filter((el) => { return el != null })));
        }
        data.append("integrator_trade_name", rootState.coupon.budget.reasonIntegratorName);
        data.append("active", coupon.validCoupon ? "true" : "false");
        data.append("max_discount", rootState.coupon.budget.discountMaxPercent.toString());
        data.append("discount_value", coupon.couponValueBrl.toString());
        data.append("discount", coupon.discountPerCent);
        data.append("value_equipment", rootState.coupon.budget.equipamentValue.toString());
        data.append("type_generator", "tri");
        data.append("brand_generator", rootState.coupon.budget.brandInverter);
        data.append("budget_sou", JSON.stringify(rootState.coupon.budget));
        if (rootState.coupon.competitor.name !== "") {
            data.append("competition_budget_pdf", rootState.coupon.competitor.pdfCompetitor);
            data.append("competition", JSON.stringify(rootState.coupon.competitor));
        }
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: data,
            redirect: "follow",
        }
        const url = config.urlGenerator.concat('generate-coupon')
        return await fetch(url, requestOptions).then(async (response) => {
            const resp = response.json();
            if (response.status >= 500) {
                return false
            }
            return resp.then((body) => {
                if (body.success) {
                    commit("setCodeCoupon", "Código do Cupom");
                    const competitor = {
                        typeStructure: "",
                        valueRoof: 0,
                        coverDiscount: 0,
                        name: "",
                        brandInverter: "",
                        powerInverter: "",
                        amountInverter: "",
                        powerPanel: "",
                        brandPanel: "",
                        amountPanel: "",
                        powerSystem: 0,
                        valueBudget: "",
                        wattValue: 0,
                        offer: "",
                        pdfCompetitor: null,
                    };
                    commit("setCompetitor", competitor);
                    return true;
                } else {
                    return false;
                }
            });
        });
    },

    async getlist({rootState, commit}, params ){
        let headers = new Headers()
        headers.append("Content-Type", "application/json")
        headers.append('Authorization', 'Bearer '.concat(rootState.auth.session.token))
        const requestOptions = {
            method: "GET",
            headers: headers,
            redirect: "follow",
        };
        const urlFilters = `coupons/${rootState.auth.session.token}?find_budget_id=${params.filters.findBudgetId}&per_page=${rootState.coupon.paginate.per_page}&page=${params.page}&find_generated_id=${params.filters.findGeneratedId}&find_admin_id=${params.filters.findAdminId}&find_rule_id=${params.filters.findRuleId}&find_discount=${params.filters.findDiscount}&find_coupon_code=${params.filters.findCouponCode}&find_budget_min=${params.filters.findBudgetMin}&find_budget_max=${params.filters.findBudgetMax}&find_discount_min=${params.filters.findDiscountMin}&find_discount_max=${params.filters.findDiscountMax}&find_status=${params.filters.findStatus}&find_coupon_type=${params.filters.findCouponType}&find_admin_name=${params.filters.findAdminName}&find_admin_trade_name=${params.filters.findAdminTradeName}`
        const url = config.urlGenerator.concat(urlFilters);
        const resp = await fetch(url, requestOptions)
        if (!resp.ok) {
            let message = resp.status==404?`Error! status: ${resp.status}`:(await resp.json()).message
            console.log(new Error(`Error! status: ${resp.status}`));
            return {"ok": false, "message": message}
        }else{
            const data = await resp.json()
            data.coupons.forEach((coupon) => {
                coupon.dialog = false
            })
            commit('setListCoupons', data.coupons )
            commit('setPercentage', parseFloat(data.percentage) )
            commit('setUserRole', parseFloat(data.user_role) )
            commit('setPaginate', {page: data.page, per_page: data.per_page, total_pages: data.total_page, total_cupons: data.total_cupons } )
            return  {"ok": true, "message": data.message}
        }
    },

    async getDetailsCoupon( {rootState }, couponId ){
        const headers = new Headers();
        headers.append("Authorization", `Bearer ${rootState.auth.session.token}`);
        const requestOptions = {
          method: "GET",
          headers: headers,
          redirect: "follow",
        };
        const url = config.urlGenerator.concat(`coupons/${couponId}/edit`)
        const resp = await fetch(url, requestOptions)
        if (!resp.ok) {
            let message = resp.status==404?`Error! status: ${resp.status}`:(await resp.json()).message
            console.log(new Error(`Error! status: ${resp.status}`));
            return {"ok": false, "message": message}
        }else{
            const data = await resp.json()
            const budget = data.coupon.budget_sou
            const coupon = {
                competition_budget_pdf: data.coupon.competition_budget_pdf,
                status: data.coupon.status,
                payment_type: data.coupon.payment_type,
                competitor: data.coupon.competition,
                generated_id: parseInt(data.coupon.generated_id),
                budget_id: parseInt(data.coupon.budget_id),
                budget_value: parseFloat(data.coupon.budget_value),
                discount: data.coupon.discount,
                discount_value: parseFloat(data.coupon.discount_value),
                discount_total: parseFloat(data.coupon.discount_total),
                initial_discount: parseFloat(data.coupon.initial_discount),
                coupon_code: data.coupon.coupon_code,
                admin_id: data.coupon.admin_id,
                rca_id: data.coupon.rca_id,
                rule_id: data.coupon.rule_id,
                coupon_name: data.coupon.coupon_name,
                integrator_id: data.coupon.integrator_id,
                description: data.coupon.description,
                integrator_trade: data.coupon.integrator_trade,
                integrator_trade_name: data.coupon.integrator_trade_name,
                integrator_name: data.coupon.integrator_name,
                active: data.coupon.active,
                max_discount: parseFloat(data.coupon.max_discount),
                value_equipment: parseFloat(data.coupon.value_equipment),
                budget_sou: budget,
                coupon_type: data.coupon.coupon_type,
                generator_type: data.coupon.generator_type,
                coupons_added: data.coupons_added
            }
            if (coupon.competitor == null) {
                coupon.competitor = {
                  name: "",
                  brandInverter: "",
                  powerInverter: "",
                  amountInverter: "",
                  powerPanel: "",
                  brandPanel: "",
                  amountPanel: "",
                  powerSystem: "",
                  valueBudget: "",
                  wattValue: "",
                  offer: "",
                  pdfCompetitor: "",
                  coverDiscount: "",
                  valueRoof: "",
                  typeStructure: "",
                }
            } else {
                coupon.competitor = JSON.parse(data.coupon.competition)
            }
            return coupon;
        }
    },

    async downloadPdf({rootState}, path) {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${rootState.auth.session.token}`);
        const raw = JSON.stringify({
            "path": path
        });
        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        const url = config.urlGenerator.concat("download")
        const resp = await fetch(url, requestOptions)
        if (!resp.ok){
            let message = resp.status==404?`Error! status: ${resp.status}`:(await resp.json()).message
            console.log(new Error(`Error! status: ${resp.status}`));
            return {"ok": false, "message": message}
        } else {
            return await fetch(url, requestOptions)
                .then(response => {
                    return response.blob();
                })
        }
    },

    async loadCoupons({rootState, commit}, filters) {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer '.concat(rootState.auth.session.token))
        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };
        const urlFilters = `coupons/${rootState.auth.session.token}?find_budget_id=${filters.findBudgetId}&per_page=${rootState.coupon.paginate.per_page}&page=${rootState.coupon.paginate.page}&find_generated_id=${filters.findGeneratedId}&find_admin_id=${filters.findAdminId}&find_rule_id=${filters.findRuleId}&find_discount=${filters.findDiscount}&find_coupon_code=${filters.findCouponCode}&find_budget_min=${filters.findBudgetMin}&find_budget_max=${filters.findBudgetMax}&find_discount_min=${filters.findDiscountMin}&find_discount_max=${filters.findDiscountMax}&find_status=${filters.findStatus}&find_coupon_type=${filters.findCouponType}&find_admin_name=${filters.findAdminName}&find_admin_trade_name=${filters.findAdminTradeName}`
        const url = config.urlGenerator.concat(urlFilters)
        const resp = await fetch(url, requestOptions)
        if (!resp.ok) {
            let message = resp.status==404?`Error! status: ${resp.status}`:(await resp.json()).message
            console.log(new Error(`Error! status: ${resp.status}`));
            return {"ok": false, "message": message}
        } else {
            return await fetch(url, requestOptions)
                .then(async (response) => {
                    const resp = response.json();
                    return resp.then((body) => {
                        if (body.success) {
                            const paginate = {
                                page: body.page,
                                per_page: body.per_page,
                                total_coupons: body.total_cupons,
                                total_pages: body.total_page,
                            };
                            body.coupons.forEach((coupon) => {
                                coupon.dialog = false;
                            });
                            commit("setUserData", {role: body.user_role, session: rootState.auth.session.token})
                            commit("setPercentage", parseFloat(body.percentage))
                            commit("setPaginate", paginate);
                            commit("setCoupons", body.coupons);
                            return true;
                        } else {
                            return false;
                        }
                    });
                })
                .catch(() => {
                    commit("setCoupons", []);
                    return false;
                });
        }
    },

    async putCoupon({rootState}, data = {} ) {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer '.concat(rootState.auth.session.token))
        const raw = JSON.stringify({
            "discount": data.value.discount,
            "discount_value": data.value.discount_value,
            "discount_total": data.value.discount_total,
            "status": data.value.status,
            "active": data.value.active
        })
        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        }
        const url = config.urlGenerator.concat(`coupons/${data.couponId}`)
        const resp = await fetch(url, requestOptions)
        if (!resp.ok) {
            let message = resp.status==404?`Error! status: ${resp.status}`:(await resp.json()).message
            console.log(new Error(`Error! status: ${resp.status}`));
            return {"ok": false, "message": message}
        } else {
            return await fetch(url, requestOptions)
                .then(async response => {
                        const resp = response.json()
                        return resp.then(() => {
                            return true
                        })
                    }
                )
        }
    },

}

<template>
  <div class="hello px-6">
    <div class="text-left mt-12 mb-8">
      <h2 class="font-weight-bold">{{ msg }}</h2>
    </div>
    <div class="filters mb-8">
      <v-row>
        <v-col cols="12">
          <v-row>
            <v-col cols="5" class="text-left">
              <div class="text-left mb-4">
                <span class="souPrimary--text font-weight-bold subtitle-2">Filtrar por:</span>
              </div>
              <v-row>
                <v-col cols="6">
                  <all-filters :filters="headers" @applyFilters="listOrders"/>
                </v-col>
                <v-col cols="6"></v-col>
              </v-row>
            </v-col>
            <v-col cols="7">
              <div class="text-left mb-4">
                <span class="souPrimary--text font-weight-bold subtitle-2">Por Página:</span>
              </div>
              <v-row>
                <v-col class="px-lg-2" cols="2">
                  <v-select
                    class="itemsPorPage"
                    color="souPrimary"
                    v-model="perPage"
                    :items="selectItems"
                    :hide-details="true"
                    :menu-props="{
                      bottom: true,
                      offsetY: true,
                      nudgeBottom: '10'
                    }"
                    item-color="souPrimary"
                    dense
                    solo
                    flat
                    height="28px"
                    outlined
                  ></v-select>
                </v-col>
                <v-col class="px-lg-2" cols="4">
                  <v-btn
                    class="btn-filter-theme"
                    elevation="0"
                    :hide-details="true"
                    color="#fff"
                    block
                    small
                    :disabled="defaultViewDisabled"
                    @click="reset"
                  >
                    <v-icon
                      color="#000"
                      dense
                      left
                    >mdi-eye-outline</v-icon>
                    <span class="black--text text-transform-none">Visualização Padrão</span>
                  </v-btn>
                </v-col>
                <v-col class="px-lg-2" cols="3">
                  <filter-columns
                    :columns="headers"
                    :viewRca="viewRca"
                    @reset="reset"
                    @replaceView="replace($event)"
                  />
                </v-col>
                <v-col class="px-lg-2" cols="3">
                  <export />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <v-card
      class="mb-4 rounded-lg px-0 px-lg-6"
      elevation="0"
    >
      <v-data-table
        :headers="activeHeaders"
        :items="getOrders"
        :page.sync="getParamsOrders.page"
        :items-per-page="perPage"
        class="elevation-0 table-progress-bar-color responsive-table"
        hide-default-footer
        no-data-text="Não há pedidos no momento."
      ></v-data-table>
      <div class="text-center pt-2">
        <div v-if="getParamsOrders.page < 5">
          <v-pagination
            class="mt-10 pb-6"
            v-model="getParamsOrders.page"
            :length="pageLength(getParamsOrders.page, getParamsOrders.totalPage)"
            @input="nextPage(getParamsOrders.page)"
            color="#F54C11"
          ></v-pagination>
        </div>
        <div v-else>
          <v-pagination
            class="mt-10 pb-6"
            v-model="getParamsOrders.page"
            :length="getParamsOrders.totalPage"
            @input="nextPage(getParamsOrders.page)"
            total-visible="9"
            color="#F54C11"
          ></v-pagination>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import store from '@/store'
import PerfilMixin from '@/mixins/perfil_mixin'
import OrdersHeadersMixin from '@/mixins/orders_headers_mixin'
import FilterColumns from '@/components/templates/orders/Columns'
import AllFilters from '@/components/templates/orders/AllFilters'
import Export from '@/components/templates/orders/Export'
import { mapGetters } from 'vuex'

export default {
  mixins: [
    PerfilMixin,
    OrdersHeadersMixin
  ],
  components: {
    FilterColumns,
    AllFilters,
    Export
  },
  beforeRouteEnter (to, from, next) {
    const permission = PerfilMixin.computed.ordersViewPermissions()
    const userInfo = store('auth/getSession').getters['auth/getSession']
    if (permission.includes(userInfo.profile_id)) {
      next()
    } else {
      next({ path: '/' })
    }
  },
  beforeMount() {
    this.headers = this.rcaHeaders
    this.perPage = this.getParamsOrders.perPage
    this.listOrders()
  },
  data: () => ({
    msg: 'Pedidos',
    selectItems: [
      20, 40, 60, 80, 100
    ],
    perPage: 20,
    viewRca: true,
    headers: [],
    defaultViewDisabled: false
  }),
  computed: {
    ...mapGetters('orders', ['getOrders', 'getFilters', 'getParamsOrders']),
    activeHeaders() {
      return this.headers.filter(header => header.active)
    }
  },
  watch: {
    headers: {
      handler(newValue) {
        this.defaultViewDisabled = JSON.stringify(newValue) !== JSON.stringify(this.defaultHeaders) ? false : true
      },
      deep: true
    },
    perPage (value) {
      this.$store.commit('orders/setPerPage', value)
      this.listOrders()
    }
  },
  methods: {
    listOrders() {
      this.$store.dispatch('orders/getList')
    },
    nextPage (page) {
      this.$store.commit('orders/setPage', page)
      this.listOrders()
    },
    pageLength (page, totalPage) {
      return (totalPage >= 5) ? 5 : totalPage
    },
    replace (data) {
      if (data.view == 'visão cliente') {
        this.headers.forEach(
          (header) => this.customersHeaders.includes(header.text) ? header.active = true : header.active = false
        )
        this.viewRca = false
      } else if (data.view == 'visão rca') {
        this.headers.forEach(
          (header) => this.initialHeaders.includes(header.text) ? header.active = true : header.active = false
        )
        this.viewRca = true
      }
    },
    reset () {
      this.headers.forEach(
        (header) => this.initialHeaders.includes(header.text) ? header.active = true : header.active = false
      )
      this.viewRca = true
    }
  }
}
</script>

<style>
.add-order {
  color: #434343;
}
.button-add-order {
  border: solid 1px #D9D9D9;
}
.itemsPorPage.v-text-field.v-text-field--solo.v-input--dense > .v-input__control,
.itemsPorPage.v-text-field.v-text-field--solo.v-input--dense > .v-input__control > .v-input__slot {
  min-height: 28px;
}
</style>

import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import pt from 'vuetify/lib/locale/pt';
import FaturamentoIcon from '@/assets/icons/svg/icon-faturamento';
import CartIcon from '@/assets/icons/svg/icon-cart';
import DeverIcon from '@/assets/icons/svg/icon-dever-de-casa';
import RaioIcon from '@/assets/icons/svg/icon-raio-x-do-cliente';
import CuponIcon from '@/assets/icons/svg/icon-cupons';
import GestaoComercialIcon from '@/assets/icons/svg/menu/icon-gestao-comercial';
import VendasIcon from '@/assets/icons/svg/menu/icon-vendas';
import ClientesIcon from '@/assets/icons/svg/menu/icon-clientes';
import OrcamentosIcon from '@/assets/icons/svg/menu/icon-orcamentos';
import PromocoesIcon from '@/assets/icons/svg/menu/icon-promocoes';

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { pt },
    current: 'pt'
  },
  theme: {
    themes: {
      light: {
        souPrimary: '#F54C11'
      },
      dark: {
        souPrimary: '#F54C11'
      }
    }
  },
  icons: {
    values: {
      faturamento: {
        component: FaturamentoIcon,
        props: {
          with: 18,
          height: 18,
          iconColor: '#000000'
        },
      },
      cupons: {
        component: CuponIcon,
        props: {
          with: 18,
          height: 18,
          iconColor: '#000000'
        },
      },
      cart: {
        component: CartIcon,
        props: {
          with: 18,
          height: 18,
          iconColor: '#000000'
        },
      },
      dever: {
        component: DeverIcon,
        props: {
          with: 18,
          height: 18,
          iconColor: '#000000'
        },
      },
      raiox: {
        component: RaioIcon,
        props: {
          with: 18,
          height: 18,
          iconColor: '#000000'
        },
      },
      gestaoComercial: {
        component: GestaoComercialIcon
      },
      vendas: {
        component: VendasIcon
      },
      clientes: {
        component: ClientesIcon
      },
      orcamentos: {
        component: OrcamentosIcon
      },
      promocoes: {
        component: PromocoesIcon
      }
    },
  },
});

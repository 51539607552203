export default {
  computed: {
    homeViewPermissions () {
      return ['1','6']
    },
    customersListViewPermissions () {
      return ['1','6']
    },
    couponGeneratorViewPermissions () {
      return ['1','6']
    },
    couponListViewPermissions () {
      return ['1','6']
    },
    ordersViewPermissions () {
      return ['1','6']
    },
    receiptsViewPermissions () {
      return ['1','6']
    },
    budgetsViewPermissions () {
      return ['1', '6']
    },
    customersViewPermissions () {
      return ['1','6']
    },
    promotionsViewPermissions () {
      return ['1','6']
    },
    salesViewPermissions () {
      return ['1','6']
    },
    myAccountViewPermissions () {
      return ['1', '6']
    },
    businessRulesViewPermissions () {
      return ['1', '6']
    }
  }
}
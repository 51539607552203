<template>
  <div>
    <div class="perfil-avatar">
      <div v-if="visible">
        <router-link to="my-account">
          <v-badge
            class="avatar-user"
            bordered
            bottom
            overlap
            color="#F54C11"
            icon="mdi-camera"
            offset-x="40"
            offset-y="40"
          >
            <v-avatar color="primary" size="128">
              <v-img
                src="https://bad.src/not/valid"
                :lazy-src="require('@/assets/avatar.png')"
                alt="Logo"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-avatar>
          </v-badge>
        </router-link>
        <router-link class="text-decoration-none" to="my-account">
          <h3 class="f-orange mt-2">{{ getSession.profile }}</h3>
        </router-link>
      </div>
      <div v-else>
        <router-link to="my-account">
          <v-tooltip
            right
            color="#F54C11"
            :open-on-hover="tooltip"
            :open-on-click="false"
            :open-on-focus="false"
            content-class="tooltip-container"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-avatar v-bind="attrs" v-on="on">
                <v-img
                  src="https://bad.src/not/valid"
                  :lazy-src="require('@/assets/avatar.png')"
                  alt="Logo"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>
            </template>
            <span class="font-weight-bold body-1">Alterar Foto</span>
          </v-tooltip>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'AvatarPerfil',
  props: {
    drawer: Boolean,
  },
  data: () => ({
    name: ""
  }),
  computed: {
    ...mapGetters('auth', ['getSession']),
    tooltip() {
      if (
        this.$vuetify.breakpoint.name === "lg" ||
        this.$vuetify.breakpoint.name === "xl"
      ) {
        if (this.drawer === true) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    visible() {
      if (
        this.$vuetify.breakpoint.name === "lg" ||
        this.$vuetify.breakpoint.name === "xl"
      ) {
        if (this.drawer === false) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
  },
  methods: {
    loadProfile() {
      // let token = this.$store.getters["auth/getSession"].token;
      // this.$store.dispatch("account/getAvatar", token);
    }
  }
}
</script>

<style scoped>
.perfil-avatar {
  margin-top: 6rem;
}
.f-orange {
  color: #F54C11;
}
.w-100 {
  width: 100%;
}
.v-tooltip__content.tooltip-container.menuable__content__active {
  opacity: 1;
}
</style>

<style>
.avatar-user .v-badge__badge {
  height: 40px;
  min-width: 40px;
  border-radius: 2rem;
}
.avatar-user .v-badge__badge::after {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.avatar-user .v-badge__badge .v-icon {
  font-size: 22px;
  height: 30px;
  width: 30px;
}
</style>
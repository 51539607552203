<template>
  <v-col cols="12" lg="8 " md="10" sm="11" class="col-competitor">
    <v-simple-table fixed-header dense class="table-competitor">
      <thead>
      <tr>
        <th
            class="
              col-4
              text-h5 text-center
              informations
              header-form-competitor
            "
        >
          <p class="informations">Dados Para Comparação:</p>
        </th>
        <th class="col-4 text-center header-form-competitor"></th>
        <th class="col-4 text-start header-form-competitor"></th>
      </tr>
      </thead>
      <tbody>
      <tr class="border-top">
        <td class="text-center informations">Nome da Organização</td>
        <td class="text-center border-left">Sou Energy</td>
        <td class="text-center border-left py-1">
          <v-select
              v-model="competitor.name"
              :items="listCompetitor"
              placeholder="Nome Concorrente"
              color="#FF9C27"
              class="mt-1 mb-0 pb-0"
              item-color="#FF9C27"
              outlined
              dense
          ></v-select>
        </td>
      </tr>
      <tr class="border-top">
        <td class="text-center informations">Valor do Equipamento</td>
        <td class="text-center border-left" style="white-space: pre-wrap">
          {{
            budget.equipamentValue.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })
          }}
        </td>
        <td class="text-center border-left py-1">
          <input
              @keyup="generatePowerSystemCompetitor()"
              v-model="competitor.valueBudget"
              type="text"
              class="input-competitor"
              placeholder="Valor do Orçamento"
          />
        </td>
      </tr>
      <tr class="border-top">
        <td class="text-center informations">Marca do Inversor</td>
        <td class="text-center border-left" style="white-space: pre-wrap">
          {{ budget.brandInverter }}
        </td>
        <td class="text-center border-left py-1">
          <v-autocomplete
              v-model="competitor.brandInverter"
              :items="listInverter"
              placeholder="Marca do Inversor"
              color="#FF9C27"
              class="mt-1 mb-0 pb-0"
              item-color="#FF9C27"
              outlined
              multiple
              dense
          ></v-autocomplete>
        </td>
      </tr>
      <tr class="border-top">
        <td class="text-center informations">Potência do(s) Inversor(s)</td>
        <td class="text-center border-left">
          <input
              @keyup="formatPowerInvertersSou()"
              v-model="budget.powerInverter"
              type="text"
              class="input-sou"
          />kWp
        </td>
        <td class="text-center border-left py-1">
          <input
              @keyup="formatPowerInveters()"
              v-model="competitor.powerInverter"
              type="text"
              class="input-competitor"
              placeholder="Potência do(s) Inversor(es)"
          />
        </td>
      </tr>
      <tr class="border-top">
        <td class="text-center informations">Quantidade de Inversores</td>
        <td class="text-center border-left">
          {{ budget.amountInverters }}
        </td>
        <td class="text-center border-left py-1">
          <input
              @keyup="formatAmountInverters()"
              v-model="competitor.amountInverter"
              type="text"
              class="input-competitor"
              placeholder="Quantidade de Inversor(es)"
          />
        </td>
      </tr>

      <tr class="border-top">
        <td class="text-center informations">Tipo de Estrutura</td>
        <td class="text-center border-left">
          {{ budget.typeStructure }}
        </td>
        <td class="text-center border-left py-1">
          <v-autocomplete
              v-model="competitor.typeStructure"
              :items="listStructure"
              placeholder="Tipo de estrutura"
              color="#FF9C27"
              class="mt-1 mb-0 pb-0"
              item-color="#FF9C27"
              outlined
              multiple
              dense
          ></v-autocomplete>
        </td>
      </tr>

      <tr class="border-top">
        <td class="text-center informations">Marca dos Painéis</td>
        <td class="text-center border-left">
          {{ budget.brandPanel }}
        </td>
        <td class="text-center border-left py-1">
          <v-autocomplete
              v-model="competitor.brandPanel"
              :items="listPanel"
              placeholder="Marca dos Painéis"
              color="#FF9C27"
              class="mt-1 mb-0 pb-0"
              item-color="#FF9C27"
              outlined
              multiple
              dense
          ></v-autocomplete>
        </td>
      </tr>

      <tr class="border-top">
        <td class="text-center informations">Potência do Painel</td>
        <td class="text-center border-left">
          <input
              @keyup="formatPowerPanelSou()"
              v-model="budget.powerPanel"
              type="text"
              class="input-sou"
          />W
        </td>
        <td class="text-center border-left py-1">
          <input
              @keyup="generatePowerSystemCompetitor()"
              v-model="competitor.powerPanel"
              type="text"
              class="input-competitor"
              placeholder="Potência do Painel"
          />
        </td>
      </tr>

      <tr class="border-top">
        <td class="text-center informations">Quantidade de Painéis</td>
        <td class="text-center border-left">
          {{ budget.amountPanel }}
        </td>
        <td class="text-center border-left py-1">
          <input
              @keyup="generatePowerSystemCompetitor()"
              v-model="competitor.amountPanel"
              type="text"
              class="input-competitor"
              placeholder="Quantidade de Painéis"
          />
        </td>
      </tr>

      <tr class="border-top">
        <td class="text-center informations">Potência do Sistema</td>
        <td class="text-center border-left">
          {{ budget.powerSystem }}
          kWp</td>
        <td class="text-center border-left py-1">
          {{ competitor.powerSystem }}
          kWp
        </td>
      </tr>

      <tr class="border-top">
        <td class="text-center informations">Valor do kWp</td>
        <td class="text-center border-left">
          {{
            budget.powerPanel !== 0 &&
            budget.powerPanel > 0 &&
            budget.amountPanel > 0
                ? (
                    budget.equipamentValue /
                    ((budget.powerPanel * budget.amountPanel) / 1000)
                ).toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })
                : "Não foi possível mapear essa informação"
          }}
          {{
            budget.powerPanel >= 0 &&
            budget.powerPanel != "" &&
            budget.amountPanel > 0
                ? "/kWp"
                : ""
          }}
        </td>
        <td class="text-center border-left py-1">
          {{
            competitor.wattValue.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })
          }}
          /kWp
        </td>
      </tr>

      <tr class="border-top">
        <td class="text-center informations">Cobertura</td>
        <td class="text-end py-1 border-left">
          {{
            competitor.valueRoof.toLocaleString("pr-br", {
              style: "currency",
              currency: "BRL",
            })
          }}
        </td>
        <td></td>
      </tr>

      <tr class="border-top">
        <td class="text-center informations">Desconto/Cupons Usados</td>
        <td class="text-center py-1 border-left">
          {{
            competitor.coverDiscount.toLocaleString("pr-br", {
              style: "currency",
              currency: "BRL",
            })
          }}
        </td>
        <td class="text-center py-1 border-left">
          <p
              class="my-0"
              v-for="(coupon, i) in budget.couponsActive"
              :key="i"
          >
            {{ coupon.use }}x {{ coupon.name }} =
            {{
              (coupon.use * coupon.value).toLocaleString("pr-br", {
                style: "currency",
                currency: "BRL",
              })
            }}
          </p>
        </td>
      </tr>

      <tr class="border-top border-bottom">
        <td class="text-center informations">PDF da Concorrêcia</td>
        <td class="py-1 border-left"></td>
        <td class="text-center py-1 border-left">
          <v-file-input
              v-model="competitor.pdfCompetitor"
              solo
              accept="application/pdf"
              flat
              placeholder="PDF Concorrente"
          ></v-file-input>
        </td>
      </tr>
      </tbody>
    </v-simple-table>
  </v-col>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data: () => {
    return {
      listInverter: [
        'Fronius', 'Growatt',
        'Renovigi', 'Sungroe',
        'Solis', 'APSystems',
        'SMA', 'ABB(Fimer)',
        'WEG', 'SolarEdge',
        'Hoymiles', 'PHB',
        'Goodwe', 'Canadian',
        'Refusol', 'Sofar',
        'SAJ', 'Deye',
        'Intelbras', 'Huawei',
        'KSTAR', 'Kehua'
      ],
      listStructure: [
        'Solar Group', 'Romagnole',
        'K2 Systems', 'SSM',
        'Spin', 'PHB',
        'Politec', '2p acessórios',
        'Renovigi', 'Fotofix',
        'Sices', 'Perfil Solar',
        'GF2', 'Tritec',
        'Solarfix', 'Artsing',
        'Intelbras', 'WEG',
        'IBRAP', 'NTC',
        'Metal light', 'Fotus'
      ],
      listPanel: [
        'Canadian', 'BYD',
        'Trina', 'Jinko',
        'Risen', 'DAH Solar',
        'Ulica', 'JA solar',
        'Sunova', 'Longi',
        'GCL', 'Intelbras',
        'Renovigi', 'ZNShine',
        'Amerisolar', 'Osda',
        'Q-Cells', 'Talesun',
        'Jetion', 'Yingli',
        'Kript', 'Luxen',
        'Astronergy', 'WEG',
        'Helius', 'Axitec',
        'Sunergy', 'Balfar',
        'QXPV', 'Empalux',
        'PHB', 'Seraphim',
        'Sices', 'Belenergy',
        'Renesola', 'Resun',
        'Leapton'
      ],
      discountActive: 0,
    }
  },
  watch: {
    discountsActive() {
      this.formatOffer();
      if (
          this.competitor.valueBudget !== "" &&
          this.competitor.powerPanel !== "" &&
          this.competitor.amountPanel !== ""
      )
        this.coverValue();
    }
  },
  computed: {
    ...mapGetters('coupon', {competitor: "getCompetitor"}),
    ...mapGetters('coupon', {listCompetitor: "getListCompetitor"}),
    ...mapGetters('coupon', {budget: "getBudget"})
  },
  methods: {
    plusUseCoupon(index) {
      if (
          !(
              this.budget.couponsActive[index].use >=
              this.budget.couponsActive[index].maxUse
          )
      ) {
        this.budget.couponsActive[index].use += 1;
        this.budget.discountsActive += this.budget.couponsActive[index].value;
      }
    },
    formatPowerInvertersSou() {
      const stringPowerInveters = this.budget.powerInverter.toString();
      this.budget.powerInverter = stringPowerInveters.replace(
          /[a-zA-Z\u00C0-\u00FF!@#+$%¨&*()/{}[/\]|`´^~;:?ªº§=°'-, "]+/i,
          ""
      );
    },
    generatePowerSystemCompetitor() {
      this.formatOffer();
      this.formatPowerPanel();
      this.formatAmountPanel();
      this.formatBudgetValue();
      let wattValueCompetitor = 0;
      let wattValueSou =
          this.budget.powerPanel !== 0
              ? this.budget.equipamentValue /
              ((this.budget.powerPanel * this.budget.amountPanel) / 1000)
              : 0;

      this.competitor.powerSystem =
          (this.competitor.amountPanel * this.competitor.powerPanel) / 1000;

      if (this.competitor.powerSystem !== 0) {
        this.competitor.wattValue =
            this.competitor.valueBudget / this.competitor.powerSystem;
        if (!isNaN(this.competitor.wattValue)) {
          wattValueCompetitor = this.competitor.wattValue;
        }
      }

      if (wattValueSou <= wattValueCompetitor) {
        this.competitor.valueRoof = this.budget.value;
      } else {
        if (
            wattValueCompetitor * this.budget.powerSystem <=
            this.competitor.valueBudget
        ) {
          if (this.competitor.valueBudget === "") {
            this.competitor.valueRoof = 0;
          } else {
            this.competitor.valueRoof = parseFloat(this.competitor.valueBudget);
          }
        } else {
          this.competitor.valueRoof =
              wattValueCompetitor * this.budget.powerSystem;
        }
      }
      this.coverValue();
    },
    formatPowerPanelSou() {
      const stringPowerPanel = this.budget.powerPanel.toString();
      if (this.budget.powerPanel === "") {
        return;
      } else {
        this.budget.powerPanel = stringPowerPanel.replace(
            /[a-zA-Z\u00C0-\u00FF!@#+$%¨&*()/{}[/\]|`´^~;:?ªº§=°'-, "]+/i,
            ""
        );
        if (
            !isNaN(this.budget.powerPanel) &&
            this.budget.powerPanel !== "" &&
            this.budget.amountPanel > 0
        ) {
          this.budget.powerSystem =
              (this.budget.powerPanel * this.budget.amountPanel) / 1000;
        }
      }
    },
    formatOffer() {
      const stringOffer = this.competitor.offer.toString();
      this.competitor.offer = stringOffer.replace(
          /[a-zA-Z\u00C0-\u00FF!@#+$%¨&*()/{}[/\]|`´^~;:?ªº§=°'-,. "]+/i,
          ""
      );
    },
    formatPowerPanel() {
      const stringPowerPanel = this.competitor.powerPanel.toString();
      this.competitor.powerPanel = stringPowerPanel.replace(
          /[a-zA-Z\u00C0-\u00FF!@#+$%¨&*()/{}[/\]|`´^~;:?ªº§=°'-, "]+/i,
          ""
      );
    },
    formatAmountPanel() {
      const stringAmountPanel = this.competitor.amountPanel.toString();
      this.competitor.amountPanel = stringAmountPanel.replace(
          /[a-zA-Z\u00C0-\u00FF!@#+$%¨&*()/{}[/\]|`´^~;:?ªº§=°'-,. "]+/i,
          ""
      );
    },
    formatBudgetValue() {
      const stringBudgetValue = this.competitor.valueBudget.toString();
      this.competitor.valueBudget = stringBudgetValue.replace(
          /[a-zA-Z\u00C0-\u00FF!@#+$%¨&*()/{}[/\]|`´^~;:?ªº§=°'-, "]+/i,
          ""
      );

    },
    formatPowerInveters() {
      const stringPowerInveters = this.competitor.powerInverter.toString();
      this.competitor.powerInverter = stringPowerInveters.replace(
          /[a-zA-Z\u00C0-\u00FF!@#+$%¨&*()/{}[/\]|`´^~;:?ªº§=°'-, "]+/i,
          ""
      );
    },
    formatAmountInverters() {
      const stringAmountInverters = this.competitor.amountInverter.toString();
      this.competitor.amountInverter = stringAmountInverters.replace(
          /[a-zA-Z\u00C0-\u00FF!@#+$%¨&*()/{}[/\]|`´^~;:?ªº§=°'-,. "]+/i,
          ""
      );
    }
  }

}
</script>

<style>
.theme--light.v-input--switch .v-input--switch__thumb {
  color: #ff7d27 !important;
}

.border-top {
  border-top: 1px solid #adadad;
}

.border-left {
  border-left: 1px solid #adadad;
}

.border-bottom {
  border-bottom: 1px solid #adadad;
}

.input-competitor {
  border: 1.8px solid #adadad;
  border-radius: 5px;
  padding: 1px 4px;
}

.input-sou {
  border: 1.8px solid #adadad;
  border-radius: 5px;
  padding: 1px 4px;
  width: 50px;
}

.col-competitor {
  background-color: #f8f8f8;
  border-top: 1px solid #adadad;
  border-bottom: 1px solid #adadad;
}
</style>

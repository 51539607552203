<template>
  <div class="containe">
    <title-view title="Sou Analytics" />
    <v-container>
      <div class="logo">
        <img width="150" height="45" src="@/assets/logo_sou.jpeg" alt="">
      </div>
      <v-row class="mx-auto my-8">
        <v-col cols="12">
          <h1 class="sub-title">GESTÃO COMERCIAL</h1>
        </v-col>
      </v-row>
      <v-row class="mt-8 links" no-gutters align="center" justify="center">
        <v-hover class="m-3" v-slot="{ hover }">
          <link-card  class="link-card" text="Análise de Faturamento" @click.native="goTo('/areaLogada')">
            <icon-faturamento width="40" height="40" hoverColor="#FFFFFF" :hover="hover"></icon-faturamento>
          </link-card>
        </v-hover>
        <v-hover v-slot="{ hover }">
          <link-card  class="link-card" @click.native="goTo('/areaLogada')" text="Dashboard Loja" >
            <icon-cart width="40" height="40" hoverColor="#FFFFFF" :hover="hover"></icon-cart>
          </link-card>
        </v-hover>
        <v-hover v-slot="{ hover }">
          <link-card  class="link-card" @click.native="goTo('/about')" text="Dever de Casa" >
            <icon-dever width="40" height="40" hoverColor="#FFFFFF" :hover="hover" ></icon-dever>
          </link-card>
        </v-hover>
      </v-row>
      <v-row class="mt-8 links" no-gutters align="center" justify="center">
        <v-hover v-slot="{ hover }">
          <link-card  class="link-card" @click.native="goTo('/about')" text="Raio-X do Cliente" >
            <icon-raio-x width="40" height="40" hoverColor="#FFFFFF" :hover="hover"></icon-raio-x>
          </link-card>
        </v-hover>
        <v-hover v-slot="{ hover }">
          <link-card  class="link-card" @click.native="goTo('/about')" text="Cupons" >
            <icon-cupons width="40" height="40" hoverColor="#FFFFFF" :hover="hover"></icon-cupons>
          </link-card>
        </v-hover>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import TitleView from '@/components/templates/Title.vue'
import LinkCard from '@/components/templates/LinkCard.vue'
import iconFaturamento from '@/assets/icons/svg/icon-faturamento.vue'
import iconDever from '@/assets/icons/svg/icon-dever-de-casa.vue'
import iconCart from '@/assets/icons/svg/icon-cart.vue'
import iconCupons from '@/assets/icons/svg/icon-cupons.vue'
import iconRaioX from '@/assets/icons/svg/icon-raio-x-do-cliente.vue'
import store from "@/store"
import PerfilMixin from "@/mixins/perfil_mixin"

export default {
  name: 'Home-View',
  mixins: [PerfilMixin],
  components: {
    TitleView,
    LinkCard,
    iconFaturamento,
    iconDever,
    iconCart,
    iconCupons,
    iconRaioX
  },
  beforeRouteEnter (to, from, next) {
    const permission = PerfilMixin.computed.homeViewPermissions()
    const userInfo = store('auth/getSession').getters['auth/getSession']
    if (permission.includes(userInfo.profile_id)) {
      next()
    } else {
      next({ path: '/' })
    }
  },
  methods:{
    goTo (route){
      this.$router.replace({path: route})
    }
  }
}
</script>

<style scoped>
  .logo{
    margin-top: 2.4rem;
  }
  .sub-title{
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 2.95rem;
    line-height: 2.205rem;

    /* identical to box height */
    text-align: center;
    text-transform: uppercase;

    color: #6E6E6E;


  }
  /*.link-card{
    padding-right: 0;
    margin: 0.5rem;
    width: 2rem;
  } */

  /* .containe{
    min-height: 250vh;
  } */

  .links{
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }
  @media (min-width: 360px ) and (max-width: 1264px) {
    .links{
      flex-direction: column;
    }
  }
  @media (min-width: 960px) {
    .links{
      flex-direction: row;
    }
  }
</style>

<template>
  <v-col cols="12" lg="6" md="8" sm="10">
    <table v-show="!overlay" class="table align-middle">
      <tbody>
        <tr scope="row">
          <td class="text-center border">Id do Orçamento</td>
          <td class="text-center border">
            {{ couponDetails.budget_id }}
          </td>
        </tr>

        <tr scope="row">
          <td class="text-center border">Nome do Consultor</td>
          <td class="text-center border">
            {{ couponDetails.budget_sou.consultantName }}
          </td>
        </tr>

        <tr scope="row">
          <td class="text-center border col-6">Tipo de Pagamento</td>
          <td class="text-center border">
            {{ couponDetails.payment_type }}
          </td>
        </tr>

        <tr scope="row">
          <td class="text-center border">Valor do Orçamento</td>
          <td class="text-center border">
            {{
              parseFloat(couponDetails.budget_value).toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })
            }}
          </td>
        </tr>
        <tr scope="row">
          <td class="text-center border">Valor do Equipamento</td>
          <td class="text-center border">
            {{
              parseFloat(couponDetails.value_equipment).toLocaleString(
                "pt-br",
                {
                  style: "currency",
                  currency: "BRL",
                }
              )
            }}
          </td>
        </tr>
        <tr scope="row">
          <td class="text-center border">Valor do Repasse</td>
          <td class="text-center border">
            {{
              parseFloat(this.budget.transferValue).toLocaleString(
                "pt-br",
                {
                  style: "currency",
                  currency: "BRL",
                }
              )
            }}
          </td>
        </tr>

        <tr scope="row">
          <td class="text-center border">Tipo do Gerador</td>
          <td class="text-center border">
            {{
              couponDetails.generator_type == "Múltiplos"
                ? couponDetails.generator_type
                : couponDetails.generator_type == "mono"
                ? "Monofásico"
                : "Trifásico"
            }}
          </td>
        </tr>

        <tr scope="row">
          <td class="text-center border col-6">Marca do(s) Inversore(s)</td>
          <td class="text-center border">
            {{ couponDetails.budget_sou.brandInverter }}
          </td>
        </tr>

        <tr scope="row">
          <td class="text-center border col-6">Quantidade de Inversores</td>
          <td class="text-center border">
            {{ couponDetails.budget_sou.amountInverters }}
          </td>
        </tr>

        <tr scope="row">
          <td class="text-center border col-6">Potência do(s) Inversor(es)</td>
          <td class="text-center border">
            {{ couponDetails.budget_sou.powerInverter }} kWp
          </td>
        </tr>
        <tr scope="row">
          <td class="text-center border">Marca dos Painéis</td>
          <td class="text-center border">
            {{ couponDetails.budget_sou.brandPanel }}
          </td>
        </tr>

        <tr scope="row">
          <td class="text-center border">Quantidade de Painéis</td>
          <td class="text-center border">
            {{ couponDetails.budget_sou.amountPanel }}
          </td>
        </tr>

        <tr scope="row">
          <td class="text-center border">Potência dos Painéis</td>
          <td class="text-center border">
            {{ couponDetails.budget_sou.powerPanel }} W
          </td>
        </tr>
      </tbody>
    </table>
  </v-col>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'DetailsBudgetSou',
  props: {
    couponDetails: {
      generated_id: Number,
      budget_id: Number,
      budget_value: Number,
      discount: Number,
      discount_value: Number,
      initial_discount: Number,
      discount_total: Number,
      coupon_code: String,
      admin_id: Number,
      rca_id: Number,
      rule_id: Number,
      coupon_name: String,
      integrator_id: Number,
      description: String,
      integrator_trade: String,
      integrator_trade_name: String,
      integrator_name: String,
      active: Boolean,
      max_discount: Number,
      value_equipment: Number,
      budget_sou: undefined,
      generator_type: String,
      competitor: undefined,
      coupon_type: String,
      payment_type: String,
      status: String,
      coupons_added: undefined,
      competition_budget_pdf: String
    },
    overlay: Boolean
  },
  computed: {
    ...mapGetters('coupon', {budget: "getBudget"}),
  }

}

</script>

export default {
  computed: {
    dateRangeTextCommissionDate() {
      if (this.commissionDates) {
        if (this.commissionDates.length > 1) {
          if (this.commissionDates[0] !== '' && this.commissionDates[1] !== '') {
            let arrDates = this.commissionDates
            const arrFormatDates = arrDates
              .map((date) => {
                return this.formatDateRange(date)
              })
            return arrFormatDates.join(' ~ ')
          } else {
            return ''
          }
        } else if (this.commissionDates.length === 1) {
          return this.formatDateRange(this.commissionDates)
        } else if (this.commissionDates.length <= 0) {
          return ''
        }
      }
    },
    dateRangeTextPreSaleDate() {
      if (this.preSaleDates) {
        if (this.preSaleDates.length > 1) {
          if (this.preSaleDates[0] !== '' && this.preSaleDates[1] !== '') {
            let arrDates = this.preSaleDates
            const arrFormatDates = arrDates
              .map((date) => {
                return this.formatDateRange(date)
              })
            return arrFormatDates.join(' ~ ')
          } else {
            return ''
          }
        } else if (this.preSaleDates.length === 1) {
          return this.formatDateRange(this.preSaleDates)
        } else if (this.preSaleDates.length <= 0) {
          return ''
        }
      }
    },
    dateRangeTextNewPreSaleDate() {
      if (this.newPreSaleDates) {
        if (this.newPreSaleDates.length > 1) {
          if (this.newPreSaleDates[0] !== '' && this.newPreSaleDates[1] !== '') {
            let arrDates = this.newPreSaleDates
            const arrFormatDates = arrDates
              .map((date) => {
                return this.formatDateRange(date)
              })
            return arrFormatDates.join(' ~ ')
          } else {
            return ''
          }
        } else if (this.newPreSaleDates.length === 1) {
          return this.formatDateRange(this.newPreSaleDates)
        } else if (this.newPreSaleDates.length <= 0) {
          return ''
        }
      }
    },
    dateRangeTextCreatedDate() {
      if (this.createdDates) {
        if (this.createdDates.length > 1) {
          if (this.createdDates[0] !== '' && this.createdDates[1] !== '') {
            let arrDates = this.createdDates
            const arrFormatDates = arrDates
              .map((date) => {
                return this.formatDateRange(date)
              })
            return arrFormatDates.join(' ~ ')
          } else {
            return ''
          }
        } else if (this.createdDates.length === 1) {
          return this.formatDateRange(this.createdDates)
        } else if (this.createdDates.length <= 0) {
          return ''
        }
      }
    }
  },
  methods: {
    formatDateRange (dateValue) {
      let arr = dateValue.toString().split('-')
      let day = arr[2]
      let month = arr[1]
      let year = arr[0]
      return `${day}/${month}/${year}`
    }
  }
}
<template>
  <div>
    <h1>Regras de Negócio</h1>
  </div>
</template>

<script>
import store from "@/store"
import PerfilMixin from "@/mixins/perfil_mixin"

export default {
  mixins: [PerfilMixin],
  beforeRouteEnter (to, from, next) {
    const permission = PerfilMixin.computed.businessRulesViewPermissions()
    const userInfo = store('auth/getSession').getters['auth/getSession']
    if (permission.includes(userInfo.profile_id)) {
      next()
    } else {
      next({ path: '/' })
    }
  }
}
</script>
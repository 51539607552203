export default {
    setToken(state, token) {
        state.session.token = token
    },
    setUserInfo(state, params) {
        state.session.id = params.admin_id
        state.session.profile = params.profile_name
        state.session.profile_id = params.profile_id
        state.session.role_id = params.role_id
    },
    setDrawer(state, drawer) {
        state.drawer = drawer
    },
    setEmailAddress(state, params) {
        state.emailAddress = params.emailAddress
    }
}

<template>
    <svg :width="width" :height="height" viewBox="0 0 69 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M24.5693 39.8482H58.5199C59.3169 39.8482 60.0228 39.3245 60.2505 38.5503L68.2656 10.2695C68.425 9.72296 68.3112 9.1537 67.9696 8.69829C67.6281 8.24288 67.1044 7.99241 66.5351 7.99241H15.5294L13.6395 1.29791C13.4118 0.523719 12.7287 0 11.9089 0H1.79886C0.819734 0 0 0.796964 0 1.79886C0 2.80076 0.796964 3.59772 1.79886 3.59772H10.5655L20.334 38.1404C19.3321 39.1879 18.7173 40.6224 18.7173 42.1708C18.7173 45.4042 21.3586 48.0455 24.592 48.0455H58.6793C59.6584 48.0455 60.4782 47.2486 60.4782 46.2467C60.4782 45.2448 59.6812 44.4478 58.6793 44.4478H24.5693C23.2941 44.4478 22.2694 43.4231 22.2694 42.148C22.2694 40.8729 23.2941 39.8482 24.5693 39.8482ZM64.167 11.5674L57.1765 36.2732H23.5218L16.5313 11.5674H64.167Z" :fill="hover?hoverColor:iconColor"/>
        <path d="M50.5274 60.0001C47.2712 60.0001 44.6299 57.3588 44.6299 54.1026C44.6299 50.8464 47.2712 48.2051 50.5274 48.2051C53.7836 48.2051 56.4249 50.8464 56.4249 54.1026C56.4249 57.3588 53.7836 60.0001 50.5274 60.0001ZM50.5274 51.78C49.2523 51.78 48.2048 52.8275 48.2048 54.1026C48.2048 55.3778 49.2523 56.4252 50.5274 56.4252C51.8026 56.4252 52.85 55.3778 52.85 54.1026C52.85 52.8275 51.8026 51.78 50.5274 51.78Z" :fill="hover?hoverColor:iconColor"/>
        <path d="M30.1934 60.0001C26.9373 60.0001 24.2959 57.3588 24.2959 54.1026C24.2959 50.8464 26.9373 48.2051 30.1934 48.2051C33.4496 48.2051 36.091 50.8464 36.091 54.1026C36.091 57.3588 33.4496 60.0001 30.1934 60.0001ZM30.1934 51.78C28.9183 51.78 27.8709 52.8275 27.8709 54.1026C27.8709 55.3778 28.9183 56.4252 30.1934 56.4252C31.4686 56.4252 32.516 55.3778 32.516 54.1026C32.516 52.8275 31.4686 51.78 30.1934 51.78Z" :fill="hover?hoverColor:iconColor"/>
    </svg>
</template>
<script>
export default {
    props: {
        width: {
            type: [Number, String],
            default: 18
        },
        height: {
            type: [Number, String],
            default: 18
        },
        iconColor: {
            type: String,
            default: '#F9820B'
        },
        hoverColor: {
            type: String,
            default: '#F9820B'
        },
        hover:{
            type: Boolean,
            default: false
        }
    }
}
</script>

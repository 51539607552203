import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from '@/router/routes'
import  store  from '@/store/index.js'; 


Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) =>{
  if (to.name == 'login') {
    if (isAuthenticated()){
      next ({ path: '/' })
    }else {
      next ()
    }
  }else {
    if (isAuthenticated()){
      next ()
    }else {
      next ({ path: '/auth' })
    }
  }
})

function isAuthenticated() {
  let session = store('auth/getSession').getters['auth/getSession']
  if (session.token === '' || session.token === undefined) {
    return false
  }
  return true
}
export default router

<template>
<div>
    <v-overlay :value="overlay">
      <v-progress-circular
          color="#ff7d27"
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
  <v-row class="py-0 my-0 justify-center">
    <v-col cols="4" lg="2 " md="3" sm="3 " class="py-2">
      <p class="text-end my-auto">Tipo de Cupom:</p>
    </v-col>
    <v-col cols="8" lg="6" md="7" sm="8" class="my-0 py-0">
      <v-select
          :items="typesCoupon"
          v-model="coupon.typeCoupon"
          :disabled="existCoupon"
          placeholder="Tipo de cupom"
          color="#FF9C27"
          item-color="#FF9C27"
          outlined
          dense
      ></v-select>
    </v-col>
  </v-row>

  <v-row
      v-show="coupon.typeCoupon === 'Bater Concorrência'"
      class="py-0 my-2 justify-center"
  >
    <form-competitor :coverValue="coverValue" />
  </v-row>

  <v-row class="py-0 my-0 justify-center">
    <v-col cols="4" lg="2 " md="3" sm="3 " class="py-2">
      <p class="text-end my-auto">Tipo de Pagamento:</p>
    </v-col>
    <v-col cols="8" lg="6" md="7" sm="8" class="my-0 py-0">
      <v-select
          v-model="coupon.typePayment"
          :items="typesPayment"
          :disabled="existCoupon"
          placeholder="Tipo de Pagamento"
          color="#FF9C27"
          item-color="#FF9C27"
          outlined
          dense
      ></v-select>
    </v-col>
  </v-row>

  <v-row class="py-0 my-0 justify-center" v-if="showBanck">
    <v-col cols="4" lg="2 " md="3" sm="3 " class="py-2">
      <p class="text-end my-auto">Bancos:</p>
    </v-col>
    <v-col cols="8" lg="6" md="7" sm="8" class="my-0 py-0">
      <v-autocomplete
          v-model="financing"
          :items="banks"
          :item-text="['name']"
          :item-value="['value']"
          :disabled="existCoupon"
          placeholder="Tipo de Financiamento"
          color="#FF9C27"
          item-color="#FF9C27"
          outlined
          dense
      ></v-autocomplete>
    </v-col>
  </v-row>

  <v-row
      v-show="budget.couponsActive.length !== 0"
      class="py-0 mt-0 mb-4 justify-center col-competitor"
  >
    <v-col cols="12">
      <p class="mt-1 mr-0 text-center text-h6 mb-0">
        Selecione quantos cupons que você quer atrelar ao cupom gerado
      </p>
    </v-col>
    <v-col cols="12" lg="8" md="8" sm="10" class="d-flex flex-column">
      <div
          class="mx-auto d-flex"
          v-for="(couponActive, i) in budget.couponsActive"
          :key="i"
      >
        <p class="informations pr-2" v-show="couponActive.maxUse >0">
          {{ couponActive.maxUse }}x {{ couponActive.name }}
          {{
            couponActive.value.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })
          }}
        </p>
        <p class="use-coupon" v-show="couponActive.maxUse >0">
          <v-icon
              @click="minusUseCoupon(i)"
              style="cursor: pointer"
              color="#ffb583"
          >mdi-minus-box-outline
          </v-icon>
          {{ couponActive.use }}
          <v-icon
              @click="plusUseCoupon(i)"
              style="cursor: pointer"
              color="#ff7d27"
          >mdi-plus-box-outline
          </v-icon>
        </p>
      </div>
    </v-col>
  </v-row>

  <v-row class="py-0 my-0 justify-center">
    <v-col cols="8" lg="3" md="4" sm="4" class="px-0" style="padding: 0">
      <p class="mt-1 mr-0 text-center">Escolha como prefere o valor:</p>
    </v-col>
    <p :class="switchTypeValue ? '' : 'select'" class="mt-1">%</p>
    <v-switch
        class="mt-0 ml-3"
        v-model="switchTypeValue"
        color="#FF9C27"
        :disabled="existCoupon"
        inset
    ></v-switch>
    <p :class="switchTypeValue ? 'select' : ''" class="mt-1 mr-2">R$</p>
  </v-row>

  <v-row class="py-0 my-0 justify-center">
    <v-col cols="6" lg="2" md="3" sm="3" class="py-1">
      <p
          :class="validDiscountValue ? 'invalid-discount' : ''"
          class="text-end"
      >
        Valor do Desconto:
      </p>
    </v-col>
    <v-col cols="4" lg="2" md="2" sm="3" class="my-0 py-0">
      <v-text-field
          @keyup="formatDiscount()"
          v-model="discountValue"
          outlined
          :prefix="switchTypeValue ? 'R$' : ''"
          :disabled="existCoupon"
          required
          :color="validDiscountValue ? '#FF0000' : ''"
          :placeholder="switchTypeValue ? '000.00' : '0.0'"
          dense
          :suffix="switchTypeValue ? '' : '%'"
      ></v-text-field>
    </v-col>
  </v-row>

  <v-row v-show="!switchTypeValue" class="py-0 my-0 justify-center">
    <v-col cols="6" lg="2" md="3" sm="3" class="py-0">
      <p class="text-end">Valor Total ofertado:</p>
    </v-col>
    <v-col cols="4" lg="2" md="2" sm="3" class="py-0">
      <div :class="validDiscountValue ? 'invalid-discount' : ''">
        {{
          coupon.couponValueBrl.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          })
        }}
      </div>
    </v-col>
  </v-row>

  <v-row class="py-0 my-0 justify-center">
    <v-col cols="6" lg="2" md="3" sm="3" class="py-0">
      <p class="text-end">Soma de dencontos:</p>
    </v-col>
    <v-col cols="4" lg="2" md="2" sm="3" class="py-0">
      <div>
        {{
          (budget.discountsActive + coupon.couponValueBrl).toLocaleString(
              "pt-br",
              {
                style: "currency",
                currency: "BRL",
              }
          )
        }}
      </div>
    </v-col>
  </v-row>

  <v-row class="py-0 my-0 justify-center">
    <v-col cols="6" lg="2" md="3" sm="3" class="py-0">
      <p class="text-end">Valor final:</p>
    </v-col>
    <v-col cols="4" lg="2" md="2" sm="3" class="py-0">
      <div>
        {{
          (
              budget.value -
              budget.discountsActive -
              coupon.couponValueBrl
          ).toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          })
        }}
      </div>
    </v-col>
  </v-row>

  <v-row class="py-0 my-0 justify-center">
    <v-col cols="4" lg="2" md="3" sm="3" class="py-2">
      <p class="text-end">Nome do Cupom:</p>
    </v-col>
    <v-col cols="8" lg="6" md="7" sm="8" class="my-0 py-0">
      <v-text-field
          :disabled="existCoupon"
          v-model="coupon.nameCoupon"
          outlined
          dense
          placeholder="Nome do Cupom"
      ></v-text-field>
    </v-col>
  </v-row>

  <v-row class="py-0 my-0 justify-center">
    <v-col cols="4" lg="2" md="3" sm="3">
      <p class="text-end">Observações:</p>
    </v-col>
    <v-col cols="8" lg="6" md="7" sm="8" class="my-0 py-0">
      <v-textarea
          :disabled="existCoupon"
          v-model="coupon.description"
          outlined
          dense
          placeholder="Observações"
      ></v-textarea>
    </v-col>
  </v-row>

  <v-row class="py-0 my-0 text-center">
    <v-col cols="12">
      <v-btn
          class="col-10 col-lg-4 col-md-4 col-sm-5 buttom-coupon"
          height="50"
          :disabled="existCoupon"
          elevation="0"
          color="#FF7D27"
          @click="generateCoupon()"
      >
        <h2 class="text-light">Gerar Cupom</h2>
      </v-btn>
      <v-dialog
          v-model="dialog"
          transition="dialog-bottom-transition"
          max-width="350"
      >
        <dialog-coupon
            :validCoupon="validateCoupon"
            :couponMessage="couponMessage"
            :closeDialog="closeDialog"
        />
      </v-dialog>
    </v-col>
  </v-row>
</div>
</template>

<script>
import DialogCoupon from "@/components/templates/GeneratorCoupon/DialogCoupon";
import FormCompetitor from "@/components/templates/GeneratorCoupon/FormCompetitor";
import { mapGetters } from 'vuex';

export default {
  components: {
    DialogCoupon,
    FormCompetitor
  },
  data: () => {
    return {
      dialog: false,
      coverDiscount: 0,
      typesPayment: [
        "Cartão de Crédito",
        "Pix",
        "Boleto",
        "Financiamento",
        // "Múltiplos",
      ],
      showBanck: false,
      financing: "",
      banks: [
        {
          name: 'Banco do Brasil',
          value: 'Financiamento/Banco do Brasil'
        },
        {
          name: 'Sol Fácil',
          value: 'Financiamento/Sol Fácil'
        },
        {
          name: 'Losango',
          value: 'Financiamento/Losango',
        },
        {
          name: 'BV Financeira',
          value: 'Financiamento/BV Financeira',
        },
      ],
      coupon: {
        budgetValue: 0,
        description: "",
        nameCoupon: "",
        codeCoupon: "",
        couponValueBrl: 0,
        couponTotalValueBrl: 0,
        typeCoupon: "",
        competitionName: "",
        validCoupon: true,
        discountPerCent: "",
        typePayment: "",
      },
      couponMessage: {
        title: "",
        body: "",
      },
      overlay: false,
      validateCoupon: false,
      validDiscountValue: false,
      switchTypeValue: false,
      discountValue: 0,
    }
  },
  watch: {
    'coupon.typePayment'(value) {
      if (value === "Financiamento") {
        this.showBanck = true
      } else {
        this.showBanck = false
        this.financing = ""
      }
    },
    typeCoupon(value, old) {
      if (old === "Bater Concorrência") {
        const competitor = {
          name: "",
          typeStructure: "",
          brandInverter: "",
          powerInverter: "",
          amountInverter: "",
          powerPanel: "",
          brandPanel: "",
          amountPanel: "",
          powerSystem: 0,
          valueBudget: "",
          wattValue: 0,
          offer: "",
          pdfCompetitor: null,
          coverDiscount: 0,
          valueRoof: 0,
        };
        this.$store.commit('coupon/setCompetitor', competitor)
      }
    },
    switchTypeValue(value) {
      if (isNaN(this.discountValue)) {
        this.discountValue = 0;
      }
      if (value) {
        this.discountValue = this.coupon.couponValueBrl;
      } else {
        this.discountValue = (
            (this.discountValue * 100) /
            this.budget.value
        ).toFixed(4);
      }
    },
    discountValue() {
      if (isNaN(this.discountValue)) {
        return;
      }

      if (parseFloat(this.discountValue) > 100 && !this.switchTypeValue) {
        this.discountValue = 100;
      }
      if (!this.switchTypeValue) {
        this.coupon.couponValueBrl = parseFloat(
            (
                (this.discountValue * this.budget.discountMaxBrl) /
                this.budget.discountMaxPercent
            ).toFixed(2)
        );
        this.coupon.discountPerCent = this.discountValue;
      } else {
        this.coupon.discountPerCent = (
            (this.discountValue * 100) /
            this.budget.equipamentValue
        ).toFixed(4);
        this.coupon.couponValueBrl = parseFloat(this.discountValue);
      }

      if (isNaN(this.coupon.couponValueBrl)) {
        this.coupon.couponValueBrl = 0;
      }

      if (this.coupon.couponValueBrl > this.budget.discountMaxBrl) {
        this.validDiscountValue = true;
      } else {
        this.validDiscountValue = false;
      }
    }

  },
  computed: {
    ...mapGetters('coupon', {budget: "getBudget"}),
    ...mapGetters('coupon', {competitor: "getCompetitor"}),
    ...mapGetters('coupon', {existCoupon: "getExistCoupon"}),
    ...mapGetters('coupon', {typesCoupon: "getTypesCoupon"}),
  },
  methods: {
    coverValue() {
      if (this.competitor.offer <= this.competitor.valueBudget) {
        if (
            this.budget.discountsActive >
            this.budget.equipamentValue - this.competitor.valueRoof
        ) {
          this.competitor.coverDiscount = 0;
        } else {
          this.competitor.coverDiscount =
              this.budget.equipamentValue -
              this.competitor.valueRoof -
              this.budget.discountsActive;
        }
      } else {
        if (
            this.budget.discountsActive >
            this.budget.equipamentValue - this.competitor.offer
        ) {
          this.competitor.coverDiscount = 0;
        } else {
          this.competitor.coverDiscount =
              this.budget.equipamentValue -
              this.competitor.offer -
              this.budget.discountsActive;
        }
      }
    },
    plusUseCoupon(index) {
      if (
          !(
              this.budget.couponsActive[index].use >=
              this.budget.couponsActive[index].maxUse
          )
      ) {
        this.budget.couponsActive[index].use += 1;
        this.budget.discountsActive += this.budget.couponsActive[index].value;
      }
    },
    minusUseCoupon(index) {
      if (!(this.budget.couponsActive[index].use <= 0)) {
        this.budget.couponsActive[index].use -= 1;
        this.budget.discountsActive -= this.budget.couponsActive[index].value;
      }
    },
    closeDialog() {
      this.dialog = false;
    },
    generateCoupon() {
      this.overlay = true
      if (
          this.coupon.description !== "" &&
          this.coupon.nameCoupon !== "" &&
          this.coupon.couponValueBrl > 0 &&
          this.coupon.typeCoupon !== "" &&
          this.coupon.typePayment !== "" &&
          !(
              this.coupon.typeCoupon === "Bater Concorrência" &&
              (this.competitor.name === "" ||
                  this.budget.powerInverter === "" ||
                  this.budget.powerPanel === "" ||
                  this.competitor.brandInverter === "" ||
                  this.competitor.powerInverter === "" ||
                  this.competitor.amountInverter === "" ||
                  this.competitor.amountPanel === "" ||
                  this.competitor.powerPanel === "" ||
                  this.competitor.brandPanel === "" ||
                  this.competitor.powerSystem === 0 ||
                  this.competitor.wattValue === 0 ||
                  this.competitor.pdfCompetitor === "" ||
                  this.competitor.pdfCompetitor == null)
          )
      ) {
        if (this.coupon.typePayment === 'Financiamento') {
          if (this.financing !== '') {
            this.coupon.typePayment = this.financing
          } else {
            this.couponMessage = {
              title: "Ops!",
              body: "Informe o banco do financiamento",
            };
            this.validateCoupon = false;
            this.dialog = true;
            return
          }
        }
        if (this.coupon.typePayment === 'Cartão de Crédito') {
          this.couponMessage = {
            title: "Ops!",
            body: "Forma de pagamento não disponível para geração de cupom. Verifique as possibilidades com seu supervisor.",
          };
          this.validateCoupon = false;
          this.dialog = true;
          return
        }
        this.coupon.budgetValue = this.budget.value;
        this.coupon.validCoupon = !this.validDiscountValue;
        if (this.coupon.typeCoupon === "Ajuste de Valor") {
          this.coupon.validCoupon = false;
        }
        this.coupon.couponTotalValueBrl = this.budget.discountsActive + this.coupon.couponValueBrl;
        const codeConsultant = this.budget.consultantName
            .substr(0, 3)
            .toUpperCase();
        this.coupon.codeCoupon = String(this.budget.id) + codeConsultant;
        this.overlay = true;
        const newCoupon = {
          "coupon": this.coupon,
          "competitor": this.competitor
        }
        this.$store.dispatch('coupon/storeCoupon', newCoupon.coupon).then((response) => {
          if(response) {
            if (!this.validDiscountValue) {
              this.couponMessage = {
                title: "Sucesso!",
                body: "Seu cupom foi criado com sucesso! Você já pode acompanhar ele disponível em nossa lista",
              };
              this.dialog = true;
            } else {
              this.couponMessage = {
                title: "Atenção!",
                body: "Você solicitou um valor de desconto superior ao permitido. Entre em contato com seu supervisor",
              };
              this.dialog = true;
            }
            this.validateCoupon = true
            this.discountValue = "0"
            this.coupon = {
              typePayment: "",
              budgetValue: 0,
              description: "",
              nameCoupon: "",
              codeCoupon: "",
              couponValueBrl: 0,
              couponTotalValueBrl: 0,
              typeCoupon: "",
              competitionName: "",
              validCoupon: true,
              discountPerCent: "",
            }
            this.$store.commit("coupon/setExistCoupon", true)
            const competitor = {
              name: "",
              typeStructure: "",
              brandInverter: "",
              powerInverter: "",
              amountInverter: "",
              powerPanel: "",
              brandPanel: "",
              amountPanel: "",
              powerSystem: 0,
              valueBudget: "",
              wattValue: 0,
              offer: "",
              pdfCompetitor: null,
              coverDiscount: 0,
              valueRoof: 0,
            }
            this.$store.commit("coupon/setCompetitor", competitor)
            const params = {
              generators: [],
              id: "",
              transferValue: 0,
              equipamentValue: 0,
              couponsActive: [],
              discountsActive: 0,
              idIntegrator: 0,
              value: 0.0,
              discountMaxBrl: 0,
              discountMaxPercent: 0,
              consultantName: "Nome do Consultor",
              integratorName: "Nome do Integrador",
              fantasyIntegratorName: "Nome Fantasia",
              reasonIntegratorName: "Razão Social",
              amountInverters: 0,
              powerInverter: 0,
              brandInverter: "Marca do inversor",
              brandPanel: "Marda do painel",
              powerPanel: 0,
              amountPanel: 0,
              powerSystem: 0,
              typeStructure: "Estrutura",
              deliveryLocale: "Local de entrega",
              totalDiscount: 0,
              inGroup: false,
            }
            this.$store.commit("coupon/setBudget", params)
          }
          this.overlay = false;
          this.dialog = true;
        })
      } else {
        this.couponMessage = {
          title: "Erro!",
          body: " Preencha todos os campos para gerar o cupom!",
        };
        this.validateCoupon = false;
        this.overlay = false;
        this.dialog = true;
      }
    },
    formatDiscount() {
      const stringDiscount = this.discountValue.toString()
      this.discountValue = stringDiscount.replace(
          /[a-zA-Z\u00C0-\u00FF!@#+$%¨&*()/{}[/\]|`´^~;:?ªº§=°', "]+/i,
          ""
      );
    }
  }
}
</script>

import Vue from 'vue'
import Vuex from 'vuex'
import auth from "@/store/modules/auth"
import coupon from "../store/modules/coupon"
import orders from "@/store/modules/orders"
import createPersistedState from 'vuex-persistedstate'


Vue.use(Vuex)

const createStore = () => {
    return new Vuex.Store({
        modules: {
            auth: auth,
            coupon: coupon,
            orders: orders
        },
        plugins: [
            createPersistedState({
                storage: window.sessionStorage
            })
        ]
    })
}


export default createStore

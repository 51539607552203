<template>
    <v-row
      class="title-view"
      align="start"
    >
      {{ title }}
    </v-row>
</template>

<script>

export default {
  name: 'titleView',
  props:['title'],
}
</script>

<style scoped>
.title-view {
  background-color: #f5f5f5;
  margin-top: 3.438rem;
  margin-left: 2.063rem;
  /* Desktop/H1 */
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.938rem;
  display: flex;
  align-items: center;

  /* preto */
  color: #434343;
}
</style>

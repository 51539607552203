<template>
    <svg :width="width" :height="height" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M30 20C35.5 20 40 15.5 40 10C40 4.5 35.5 0 30 0C24.5 0 20 4.5 20 10C20 15.5 24.5 20 30 20ZM30 3C33.9 3 37 6.1 37 10C37 13.9 33.9 17 30 17C26.1 17 23 13.9 23 10C23 6.1 26.1 3 30 3Z" :fill="hover?hoverColor:iconColor"/>
    <path d="M47.7 29.1998C52 29.1998 55.4 25.7998 55.4 21.4998C55.4 17.1998 52 13.7998 47.7 13.7998C43.4 13.7998 40 17.2998 40 21.4998C40 25.7998 43.4 29.1998 47.7 29.1998ZM47.7 16.7998C50.3 16.7998 52.4 18.8998 52.4 21.4998C52.4 24.0998 50.3 26.1998 47.7 26.1998C45.1 26.1998 43 24.0998 43 21.4998C43 18.8998 45.1 16.7998 47.7 16.7998Z" :fill="hover?hoverColor:iconColor"/>
    <path d="M47.7 32.8998C46.5 32.8998 45.3 33.0998 44.2 33.3998C41.5 28.2998 36.2 24.7998 30 24.7998C23.8 24.7998 18.5 28.2998 15.8 33.3998C14.7 33.0998 13.5 32.8998 12.3 32.8998C5.5 32.8998 0 38.3998 0 45.1998V59.9998H14H15.6H44.9H46H60V45.1998C60 38.3998 54.5 32.8998 47.7 32.8998ZM3 56.9998V45.1998C3 40.0998 7.2 35.8998 12.3 35.8998C13.1 35.8998 13.9 35.9998 14.7 36.1998C14.3 37.6998 14 39.1998 14 40.7998V56.9998H3ZM43 56.9998H17V40.7998C17 33.5998 22.8 27.7998 30 27.7998C37.2 27.7998 43 33.5998 43 40.7998V56.9998ZM57 56.9998H46V40.7998C46 39.1998 45.8 37.6998 45.3 36.1998C46.1 35.9998 46.8 35.8998 47.7 35.8998C52.8 35.8998 57 40.0998 57 45.1998V56.9998Z" :fill="hover?hoverColor:iconColor"/>
    <path d="M12.3001 29.1998C16.6001 29.1998 20.0001 25.7998 20.0001 21.4998C20.0001 17.1998 16.6001 13.7998 12.3001 13.7998C8.0001 13.7998 4.6001 17.1998 4.6001 21.4998C4.6001 25.7998 8.1001 29.1998 12.3001 29.1998ZM12.3001 16.7998C14.9001 16.7998 17.0001 18.8998 17.0001 21.4998C17.0001 24.0998 14.9001 26.1998 12.3001 26.1998C9.7001 26.1998 7.6001 24.0998 7.6001 21.4998C7.6001 18.8998 9.7001 16.7998 12.3001 16.7998Z" :fill="hover?hoverColor:iconColor"/>
    </svg>
</template>
<script>
export default {
    props: {
        width: {
            type: [Number, String],
            default: 18
        },
        height: {
            type: [Number, String],
            default: 18
        },
        iconColor: {
            type: String,
            default: '#F9820B'
        },
        hoverColor: {
            type: String,
            default: '#F9820B'
        },
        hover:{
            type: Boolean,
            default: false
        }
    }
}
</script>
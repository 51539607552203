export default {
  getOrders (state) {
    return state.orders
  },
  getFilters (state) {
    return state.filters
  },
  getParamsOrders (state) {
    return state.paramsOrders
  },
  getOrderStatusOptions (state) {
    return state.order_status_options
  },
  getPaymentMethodsOptions (state) {
    return state.payment_method_options
  },
  getCustomerGroupOptions (state) {
    return state.customer_group_options
  },
  getOffilineMethodOptions (state) {
    return state.offline_method_options
  },
  getCreditLetterOptions (state) {
    return state.credit_letter_options
  }
}
export default {
    getTypesCoupon(state){
        return state.typesCoupon
    },
    getExistCoupon(state) {
        return state.existCoupon;
    },
    getBudget(state) {
        return state.budget;
    },
    getCodeCoupon(state) {
        return state.codeCoupon;
    },
    getCompetitor(state) {
        return state.competitor;
    },
    getListCompetitor(state) {
        return state.listCompetitor
    },
    getPercentage(state) {
        return state.userData.percentage
    },
    getUserInfo(state) {
        return state.userData
    },
    getCouponsList(state) {
        return state.listCoupons
    },
    getPaginate(state) {
        return state.paginate
    },
}

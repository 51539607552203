<template>
  <div>
    <div class="box-widgets-container">
      <v-card
        class="px-6 py-8"
        color="#F54C11"
        tile
      >
        <div class="box-buttons">
          <v-tooltip
            right
            color="#F54C11"
            :open-on-hover="true"
            :open-on-click="false"
            :open-on-focus="false"
            content-class="tooltip-container"
            :disabled="tooltipDisabled"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="px-2 mb-5"
                color="#FFF"
                v-bind="attrs"
                v-on="on"
                block
                x-large
              >
                <v-icon
                  left
                  color="souPrimary"
                >
                  mdi-face-agent
                </v-icon>
                <span class="text-transform-none">Gerenciar seu Suporte</span>
              </v-btn>
            </template>
            <span class="font-weight-bold body-1">Gerenciar seu Suporte</span>
          </v-tooltip>
          <v-tooltip
            right
            color="#F54C11"
            :open-on-hover="true"
            :open-on-click="false"
            :open-on-focus="false"
            content-class="tooltip-container"
            :disabled="tooltipDisabled"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="px-2"
                color="#FFF"
                v-bind="attrs"
                v-on="on"
                block
                x-large
                to="business-rules"
              >
                <v-icon
                  left
                  color="souPrimary"
                >
                  mdi-file-document-outline
                </v-icon>
                <span class="text-transform-none">Regras de Negócio</span>
              </v-btn>
            </template>
            <span class="font-weight-bold body-1">Regras de Negócio</span>
          </v-tooltip>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'boxWidgets',
  props: {
    tooltipDisabled: Boolean
  }
}
</script>

<style scoped>
.box-widgets-container {
  margin-top: 10rem;
}
.box-buttons span {
  font-size: .75rem;
}
</style>

<style>
@media screen and (min-width: 1264px) {
  #mini-menu .box-buttons .v-btn__content {
    justify-content: center;
  }
  #mini-menu .box-buttons .v-btn__content i{
    margin-right: 0;
    margin-left: 0;
  }
  #mini-menu .box-buttons .v-btn__content span {
    display: none;
  }
}
</style>
export default {
    cleanBudget(state) {
        state.budget = {
            discountsActive: 0,
            generators: [],
            id: "",
            totalDiscount: 0,
            couponsActive: [],
            transferValue: 0,
            equipamentValue: 0,
            idIntegrator: 0,
            value: 0.0,
            discountMaxBrl: 0.0,
            discountMaxPercent: 0,
            consultantName: "Nome do Consultor",
            integratorName: "Nome do Integrador",
            fantasyIntegratorName: "Nome Fantasia",
            reasonIntegratorName: "Razão Social",
            amountInverters: 0,
            powerInverter: 0,
            brandInverter: "Marca do inversor",
            brandPanel: "Marda do painel",
            powerPanel: 0,
            amountPanel: 0,
            powerSystem: 0,
            typeStructure: "Estrutura",
            deliveryLocale: "Local de entrega",
            inGroup: false,
        };
    },
    setCouponsUndefined(state) {
        state.coupons = [];
    },
    setBudget(state, params){
        state.budget = params;
    },
    setExistCoupon(state, param) {
        state.existCoupon = param;
    },
    setUserData(state, params) {
        state.userData = params;
    },
    setListCompetitor(state, params) {
        state.listCompetitor = params
    },
    setGenerators(state, param) {
        state.budget.generators = param;
    },
    setTypesCoupon(state, params){
        state.typesCoupon = params
    },
    setCodeCoupon(state, param) {
        state.codeCoupon = param
    },
    setCompetitor(state, params) {
        state.competitor = params
    },
    setListCoupons(state, params) {
        state.listCoupons = params
    },
    setPercentage(state, params) {
        state.userData.percentage = params
    },
    setUserRole(state, params) {
        state.userData.user_role = params
    },
    setPaginate(state, params) {
        state.paginate = params
    },
    setCoupons(state, params) {
        state.coupons = params;
    },

}

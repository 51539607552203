export default {
    userData: {
        user_role: undefined,
        percentage: undefined
    },
    codeCoupon: "Código do Cupom",
    listCompetitor: [],
    existCoupon: true,
    typesCoupon: [
        "Desconto Comercial",
        "Bater Concorrência",
        "Soma de descontos",
    ],
    budget: {
        id: "",
        transferValue: 0,
        equipamentValue: 0,
        idIntegrator: 0,
        value: 0.0,
        discountMaxBrl: 0.0,
        discountMaxPercent: 0,
        consultantName: "Nome do Consultor",
        integratorName: "Nome do Integrador",
        fantasyIntegratorName: "Nome Fantasia",
        reasonIntegratorName: "Razão Social",
        amountInverters: 0,
        powerInverter: 0,
        brandInverter: "Marca do inversor",
        brandPanel: "Marda do painel",
        powerPanel: 0,
        amountPanel: 0,
        powerSystem: 0,
        typeStructure: "Estrutura",
        deliveryLocale: "Local de entrega",
        couponsActive: [],
        discountsActive: 0,
        generators: {},
        totalDiscount: 0
    },
    competitor: {
        typeStructure: "",
        name: "",
        brandInverter: "",
        powerInverter: "",
        amountInverter: "",
        powerPanel: "",
        brandPanel: "",
        amountPanel: "",
        powerSystem: 0,
        valueBudget: "",
        wattValue: 0,
        offer: "",
        pdfCompetitor: null,
        coverDiscount: 0,
        valueRoof: 0,
    },
    listCoupons: [],
    paginate: {
        page: 1,
        per_page: 10,
        total_pages: 1,
        total_cupons: 10,
    },
    coupons: [],
}

<template>
  <div>
    <v-row class="py-0 my-0 justify-center">
      <v-col cols="4" lg="2" md="3" sm="3" class="px-0 py-2">
        <p class="text-end">ID do Orçamento*</p>
      </v-col>
      <v-col cols="7" lg="8" md="7" sm="6" class="my-0 py-0">
        <v-text-field
            @keypress="_isSubmit"
            @keyup="formatBudgetId()"
            v-model="budget.id"
            placeholder="ID do Orçamento"
            outlined
            dense
        ></v-text-field>
      </v-col>
    </v-row>

    <v-overlay :value="overlay">
      <v-progress-circular
          color="#ff7d27"
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-dialog
        v-model="dialog"
        transition="dialog-bottom-transition"
        max-width="350"
    >
      <dialog-coupon
          :validCoupon="false"
          :couponMessage="couponMessage"
          :closeDialog="closeDialog"
      />
    </v-dialog>
  </div>
</template>

<script>
import DialogCoupon from "@/components/templates/GeneratorCoupon/DialogCoupon";
import { mapGetters } from 'vuex';

export default {
  name: 'FormBudget',
  components: {
    DialogCoupon
  },
  data: () => {
    return {
      budget_id: "",
      overlay: false,
      dialog:false,
      couponMessage: {
        title: "",
        body: "",
      }
    }

  },
  computed: {
    ...mapGetters('coupon', {budget: "getBudget"})
  },
  methods: {
    formatBudgetId() {
      const budgetId = this.budget.id.toString()
      this.budget.id = budgetId.replace(
          /[a-zA-Z\u00C0-\u00FF!@#+$%¨&*()/{}[/\]|`´^~;:?ªº§=°'-,. "]+/i,
          ""
      );
    },
    getBudget() {
      if (this.budget.id !== "") {
        this.overlay = true;
        this.$store.dispatch('coupon/getBudget', {'budget': this.budget.id})
            .then((resp) => {
              if (resp === false) {
                this.overlay = false
              } else if (resp.couponMessage.body !== ""){
                this.overlay = false;
                this.couponMessage = resp.couponMessage;
                this.dialog = true;
              }
              this.overlay = false;
            })
      }
    },
    _isSubmit(e){
      if (e.keyCode === 13) {
        this.getBudget()
      }
    },
    closeDialog(){
      this.dialog = false;
    }
  }
}
</script>

<template>
  <div class="text-left">
    <v-btn
        class="btn-filter-theme"
        elevation="0"
        small
        color="#fff"
        block
        @click="dialog = true"
    >
      <v-icon
          color="#000"
          dense
          left
      >mdi-arrow-expand-up</v-icon>
      <span class="black--text text-transform-none">Exportar</span>
    </v-btn>
    <v-dialog
        v-model="dialog"
        hide-overlay
        persistent
        width="600"
    >
      <v-card
          color="#fff"
          max-width="900px"
      >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-icon
              color="souPrimary"
              large
              @click="dialog = false"
          >mdi-close-circle-outline</v-icon>
        </v-card-actions>
        <div class="mb-10 ml-4 text-left">
          <span class="black--text text-transform-none">Exportar Excel</span>
        </div>
        <div class="mb-16 ml-4 text-left">
          <p class="black--text text-transform-none">Exportar tabela para formato .csv</p>
        </div>
        <v-card-actions>
          <v-btn
              class="pl-10 pr-10 ml-2 mb-2"
              elevation="0"
              color="souPrimary"
              @click="dialog = false"
          >
            <span class="white--text text-transform-none">
              Exportar
            </span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "Export.vue",
  data: () => ({
    dialog: false
  })
}
</script>

<style scoped>

</style>

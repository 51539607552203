<template>
<div>
  <div class="values-integrator py-6">
    <v-row class="justify-center">
      <v-col class="col-data-integrator" cols="5" lg="4" md="5" sm="4">
        <p class="text-end">Total de descontos ativos</p>
      </v-col>

      <v-col class="col-data-integrator" cols="6" sm="6">
        <p class="text-start informations text-truncate px-3">
          {{
            budget.totalDiscount.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })
          }}
        </p>
      </v-col>
    </v-row>

    <v-row class="justify-center">
      <v-col class="col-data-integrator" cols="5" lg="4" md="5" sm="4">
        <p class="text-end">Código do Cupom gerado</p>
      </v-col>

      <v-col class="col-data-integrator" cols="6" sm="6">
        <p class="text-start informations text-truncate px-3">
          {{ codeCoupon }}
        </p>
      </v-col>
    </v-row>

    <v-row class="justify-center">
      <v-col class="col-data-integrator" cols="5" lg="4" md="5" sm="4">
        <p class="text-end">Nome do Integrador</p>
      </v-col>

      <v-col class="col-data-integrator" cols="6" sm="6">
        <p class="text-start informations text-truncate px-3">
          {{ budget.integratorName }}
        </p>
      </v-col>
    </v-row>

    <v-row class="justify-center">
      <v-col class="col-data-integrator" cols="5" lg="4" md="5" sm="4">
        <p class="text-end">Razão Social</p>
      </v-col>

      <v-col class="col-data-integrator" cols="6" sm="6">
        <p class="text-start informations text-truncate px-3">
          {{ budget.reasonIntegratorName }}
        </p>
      </v-col>
    </v-row>

    <v-row class="justify-center">
      <v-col class="col-data-integrator" cols="5" lg="4" md="5" sm="4">
        <p class="text-end">Nome Fantasia</p>
      </v-col>

      <v-col class="col-data-integrator" cols="6" sm="6">
        <p class="text-start informations text-truncate px-3">
          {{ budget.fantasyIntegratorName }}
        </p>
      </v-col>
    </v-row>

    <v-row class="justify-center">
      <v-col class="col-data-integrator" cols="5" lg="4" md="5" sm="4">
        <p class="text-end">Endereço do Integrador</p>
      </v-col>

      <v-col class="col-data-integrator" cols="6" sm="6">
        <p class="text-start informations text-truncate px-3">
          {{ budget.deliveryLocale }}
        </p>
      </v-col>
    </v-row>

    <v-row class="justify-center">
      <v-col class="col-data-integrator" cols="5" lg="4" md="5" sm="4">
        <p class="text-end">ID do Integrador</p>
      </v-col>

      <v-col class="col-data-integrator" cols="6" sm="6">
        <p class="text-start informations text-truncate px-3">
          {{ budget.idIntegrator }}
        </p>
      </v-col>
    </v-row>
  </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters('coupon', {budget: "getBudget"}),
    ...mapGetters('coupon', {codeCoupon: "getCodeCoupon"})
  }
}
</script>
<style>
.values-integrator {
  background: #f8f8f8;
  border-top: 1px solid #adadad;
  border-bottom: 1px solid #adadad;
}

.col-data-integrator {
  padding: 1px !important;
}

.informations {
  color: #00bfcf;
  font-weight: bolder;
}
</style>

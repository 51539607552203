<template>
  <v-footer
    padless
  >
    <div class="px-6 pt-12 pb-6 pa-lg-16 bg-white w-100">
      <v-row>
        <v-col cols="12" lg="5" md="12" sm="12">
          <div class="logo-footer">
            <img src="@/assets/logo-footer.png" alt="">
          </div>
          <div class="points-sou-energy text-left mb-6">
            <p class="body-2 mb-0">
              ☀️ Energia para novos tempos
            </p>
            <p class="body-2 mb-0">
              ⚡️ Maior distribuidora de equipamentos fotovoltaicos do N/NE
            </p>
            <p class="body-2 mb-0">
              🏅 Top 3 Distribuidores segundo Pesquisa Greener
            </p>
          </div>
          <div class="contact-sou-energy">
            <v-list dense>
              <v-list-item
                class="px-0"
                :href="``"
                target="_blank"
              >
                <v-list-item-icon class="mr-2">
                  <v-icon color="#F54C11">mdi-phone</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    class="text-left text-decoration-none font-weight-bold body-2"
                    v-text="phone"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                class="px-0"
                :href="`mailto:${email}`"
                target="_blank"
              >
                <v-list-item-icon class="mr-2">
                  <v-icon color="#F54C11">mdi-email-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    class="text-left text-decoration-none font-weight-bold bold body-2"
                    v-text="email"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>
          <div class="secure-site d-flex justify-center justify-md-start">
            <div class="secure-site-box d-flex align-center px-16 py-4">
              <v-icon class="mr-4">mdi-lock</v-icon>
              <span class="text-uppercase">Site Seguro</span>
            </div>
          </div>
        </v-col>
        <v-col class="mt-lg-12" cols="12" lg="7" md="12" sm="12">
          <v-row>
            <v-col cols="12" lg="4" md="4" sm="12">
              <div class="box-footer-menu text-left">
                <h3>Institucional</h3>
                <ul>
                  <li>
                    <a
                      :href="`${urlSite}sobre`"
                      target="_blank"  
                    >
                      Sobre
                    </a>
                  </li>
                  <li>
                    <a
                      :href="`${urlSite}sou-revendedor`"
                      target="_blank"
                    >
                      Sou Revendedor
                    </a>
                  </li>
                  <li>
                    <a 
                      :href="`${urlSite}contact`"
                      target="_blank"
                    >
                      Fale Conosco
                    </a>
                  </li>
                </ul>
              </div>
            </v-col>
            <v-col cols="12" lg="4" md="4" sm="12">
              <div class="box-footer-menu text-left">
                <h3>Conta</h3>
                <ul>
                  <li>
                    <router-link to="my-account">
                      Minha Conta
                    </router-link>
                  </li>
                  <li>
                    <a @click="resetMenu">
                      Meus Pedidos
                    </a>
                  </li>
                </ul>
              </div>
            </v-col>
            <v-col cols="12" lg="4" md="4" sm="12">
              <div class="box-footer-menu text-left">
                <h3>Nossa Loja</h3>
                <ul>
                  <li>
                    <a 
                      :href="`${urlSite}formas-de-pagamento`"
                      target="_blank"
                    >
                      Formas de pagamento
                    </a>
                  </li>
                  <li>
                    <a 
                      :href="`${urlSite}troca-e-devolucao`"
                      target="_blank"
                    >
                      Troca e devolução
                    </a>
                  </li>
                  <li>
                    <a 
                      :href="`${urlSite}politica-de-vendas`"
                      target="_blank"
                    >
                      Política de Vendas
                    </a>
                  </li>
                  <li>
                    <a 
                      :href="`${urlSite}perguntas-frequentes.html`"
                      target="_blank"
                    >
                      Perguntas Frequentes
                    </a>
                  </li>
                  <li>
                    <a 
                      :href="`${urlSite}politica-de-privacidade`"
                      target="_blank"
                    >
                      Política de Privacidade e Cookies
                    </a>
                  </li>
                  <li>
                    <a 
                      :href="`${urlSite}tutoriais-distribuicao`"
                      target="_blank"
                    >
                      Tutoriais
                    </a>
                  </li>
                </ul>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <hr class="my-8">
      <div class="sub-footer d-flex justify-space-between">
        <div class="address-souenergy text-left">
          <p class="body-2 mb-0">Rua Paulo Amaral - Quadra 14 E Parte DA 17, 411, Santo Antônio, Eusébio-CE, Brasil 61.767-690</p>
          <p class="body-2 mb-0">CNPJ: 27.568.657/0001-06</p>
        </div>
        <div class="social-media text-right">
          <v-btn
            class="ml-8 mb-3 mb-md-0"
            fab
            small
            outlined
            :href="facebook"
            target="_blank"
            color="#F54C11"
          >
            <v-icon>
              mdi-facebook
            </v-icon>
          </v-btn>
          <v-btn
            class="ml-8 mb-3 mb-md-0"
            fab
            small
            outlined
            :href="instagram"
            target="_blank"
            color="#F54C11"
          >
            <v-icon>
              mdi-instagram
            </v-icon>
          </v-btn>
        </div>
      </div>
      <div class="rights-reserved pt-10">
        Sou Energy - Todos os direitos reservados <span>{{rightsReseverd()}}</span>
      </div>
    </div>
  </v-footer>
</template>

<script>
const config = require('../../../config')

export default {
  name: 'footerWidgets',
  data: () => ({
    phone: '(11) 4003-4343',
    email: 'ecommerce@souenergy.com.br',
    facebook: 'https://www.facebook.com/souenergyoficial/',
    instagram: 'https://www.instagram.com/souenergy/?hl=pt-br'
  }),
  computed: {
    urlSite () {
      return config.baseUrlSite
    }
  },
  methods: {
    rightsReseverd () {
      let currentTime = new Date()
      let currentYear = currentTime.getFullYear()
      let nextYear = currentYear + 1
      return `${currentYear} - ${nextYear}`
    },
    resetMenu() {
      if (this.$router.history.current.name !== 'orders') {
        this.$router.replace({name: 'orders'})
        this.$emit('resetMenu')
      } 
    }
  }
}
</script>

<style scoped>
.bg-white {
  background-color: #fff;
}
.w-100 {
  width: 100%;
}
.logo-footer {
  text-align: left;
}
.secure-site {
  margin-top: 4rem;
}
.secure-site-box {
  width: max-content;
  border: 1px solid #E5E5E5;
  border-radius: 0.2rem;
}
.secure-site-box i {
  font-size: 1.4rem;
}
.box-footer-menu ul {
  margin-top: 1rem;
  list-style: none;
  padding-left: 0;
}
.box-footer-menu ul li {
  margin-bottom: 0.4rem;
}
.box-footer-menu ul li a {
  text-decoration: none;
  color: inherit;
}
footer hr {
  border: 0;
  border-top: 1px solid #eee;
}
</style>

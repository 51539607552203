<template>
  <div class="widgets">
    <v-row
      align="center"
    >
      <v-col
        cols="2"
        sm="3"
        md="3"
        lg="2"
        align-self="center"
      >
        <div class="d-flex align-center">
          <v-app-bar-nav-icon
            color="#F54C11"
            @click="retractMenu"
          ></v-app-bar-nav-icon>
          <div class="d-none d-sm-block">
            <a @click="redirect('/')">
              <img
                src="@/assets/Logo-Sou-Energy.webp"
                width="100%"
                align = "center"
              />
            </a>
          </div>
        </div>
      </v-col>
      <v-col
        class="d-flex d-sm-none justify-center"
        cols="4"
        sm="4"
      >
        <div>
          <a @click="redirect('/')">
            <img
              src="@/assets/Logo-Sou-Energy.webp"
              width="100%"
              align = "center"
            />
          </a>
        </div>
      </v-col>
      <v-col
        cols="8"
        sm="4"
        md="4"
        lg="8"
      ></v-col>
      <v-col
        class="d-none d-lg-block"
        cols="2"
        align-self="center"
      >
        <div class="d-flex justify-end">
          <v-menu
            :content-class="'menu-user'"
            min-width="190px"
            absolute
            :nudge-left="90"
          >
            <template v-slot:activator="{ on, attrs}">
              <div
                v-bind="attrs"
                v-on="on"
                class="pa-2 text-right max-content"
              >
                <v-list-item-title>
                  <p class="greetings mb-0 font-weight-bold">
                    <span>Olá,</span>
                    <span class="f-orange mr-2"> {{getSession.profile}}</span>
                    <span class="f-orange">
                      <v-icon>mdi-account-multiple</v-icon>
                    </span>
                  </p>
                </v-list-item-title>
              </div>
            </template>
            <div class="menu-user-container text-left px-4 pt-6">
              <ul class="menu-account pl-0 mt-4 pt-4">
                <li class="d-flex align-center mb-2">
                  <a @click="redirect('my-account')">
                    <span class="f-orange mr-6" >
                      <v-icon>mdi-account-multiple</v-icon>
                    </span>
                    <span>Meus dados</span>
                  </a>
                </li>
                <li class="d-flex align-center mb-2">
                  <a @click="logout">
                    <span class="f-orange mr-6">
                      <v-icon>mdi-logout</v-icon>
                    </span>
                    <span>Sair</span>
                  </a>
                </li>
              </ul>
            </div>
          </v-menu>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'headerWidgets',
  data: () => ({}),
  computed: {
    ...mapGetters('auth', ['getSession'])
  },
  methods: {
    retractMenu () {
      this.$emit('retract')
    },
    redirect (rota) {
      this.$router.replace({name: rota})
    },
    logout () {
      this.$store.dispatch('auth/logout')
      this.loading = false
    }
  }
}
</script>

<style scoped>
.widgets {
  width: 100%;
}

.menu-user-container {
  background-color: #fff;
}

.greetings span {
  text-transform: none;
}

.greetings .f-orange,
.greetings .v-icon,
.f-orange .v-icon,
.f-orange {
  color: #F54C11;
}

.menu-account {
  border-top: 1px solid #dcdcdc;
}

.menu-account li span:last-child {
  color: #000;
}
.max-content {
  width: max-content;
}
</style>

<style>
.menu-user {
  top: 85px !important;
  background-color: #fff;
}
</style>

<template>
    <svg :width="width" :height="height" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M58.3029 60H17.6706C16.7721 60 16.0732 59.2953 16.0732 58.3893V1.61074C16.0732 0.704698 16.7721 0 17.6706 0H58.4027C59.3012 0 60 0.704698 60 1.61074V58.4899C59.9002 59.2953 59.2014 60 58.3029 60ZM19.1681 56.8792H56.7055V3.12081H19.1681V56.8792Z" :fill="hover?hoverColor:iconColor"/>
        <path d="M9.08486 4.22803H1.59734C0.698835 4.22803 0 4.93273 0 5.83877V12.7851V43.9931V48.02C0 48.322 0.0998336 48.624 0.299501 48.926L3.99334 54.6643C4.29285 55.067 4.79201 55.369 5.29118 55.369C5.79035 55.369 6.28952 55.067 6.58902 54.6643L10.3827 48.8253C10.5824 48.5233 10.6822 48.2213 10.6822 47.9193V43.8925V12.6844V5.83877C10.6822 4.93273 9.98336 4.22803 9.08486 4.22803ZM3.09484 14.2951H7.48752V42.2817H3.09484V14.2951ZM3.09484 7.4495H7.48752V11.1743H3.09484V7.4495ZM7.48752 47.4159L5.29118 50.8388L3.09484 47.4159V45.5032H7.48752V47.4159Z" :fill="hover?hoverColor:iconColor"/>
        <path d="M37.2379 41.5772V39.8658C33.6439 39.6645 31.3477 38.0537 30.7487 36.0403C30.6489 35.7383 30.5491 35.4363 30.5491 35.1343C30.5491 34.1276 31.2479 33.4229 32.3461 33.4229C33.2446 33.4229 33.7437 33.8256 34.1431 34.6309C34.5424 35.7383 35.4409 36.5437 37.2379 36.6444V31.6108L36.2396 31.4095C32.6456 30.6041 30.6489 28.9933 30.6489 25.9732C30.6489 22.6511 33.4442 20.4363 37.2379 20.235V18.5235C37.2379 18.0202 37.5374 17.8188 37.9368 17.8188C38.3361 17.8188 38.6356 18.1209 38.6356 18.5235V20.235C41.9301 20.4363 44.2263 22.047 44.8253 24.2618C44.9251 24.5638 44.9251 24.8658 44.9251 25.0672C44.9251 25.9732 44.2263 26.5772 43.2279 26.5772C42.3294 26.5772 41.7304 26.1746 41.3311 25.3692C40.8319 24.2618 39.9334 23.5571 38.6356 23.3558V28.2886L39.7338 28.49C43.5274 29.396 45.5241 30.9061 45.5241 33.9262C45.5241 37.4497 42.6289 39.5638 38.7354 39.8658V41.5772C38.7354 42.0806 38.4359 42.2819 38.0366 42.2819C37.5374 42.2819 37.2379 41.9799 37.2379 41.5772ZM37.2379 27.9866V23.3558C35.4409 23.5571 34.4426 24.4631 34.4426 25.5705C34.4426 26.6779 35.2413 27.4833 37.2379 27.9866ZM38.6356 31.9128V36.6444C40.6323 36.443 41.6306 35.6376 41.6306 34.3289C41.5308 33.1209 40.8319 32.5168 38.6356 31.9128Z" :fill="hover?hoverColor:iconColor"/>
    </svg>
</template>
<script>
export default {
    props: {
        width: {
            type: [Number, String],
            default: 18
        },
        height: {
            type: [Number, String],
            default: 18
        },
        iconColor: {
            type: String,
            default: '#F9820B'
        },
        hoverColor: {
            type: String,
            default: '#F9820B'
        },
        hover:{
            type: Boolean,
            default: false
        }
    }
}
</script>

<template class="link-card">
  <v-col cols="2" >
    <v-hover v-slot="{ hover }" class="pt-6 size-default" >
      <v-card :class="{ 'on-hover': hover }" elevation="0">
        <slot />
        <v-card-text class="py-0 text-center text-h12" :class="{ 'white--text': hover }">
          {{ text }}
        </v-card-text>
      </v-card>
    </v-hover>
  </v-col>
</template>

<script>

export default {
  name: 'LinkCard',
  props:['text'],
}
</script>

<style scoped>
.link-card{
  margin: 1rem;
  max-width: 8rem;
}
.size-default{
  min-height: 8rem;
  width: 8rem;
}
.v-card.on-hover{
  background: #FF5C01;
  color: #FFFFFF;
}
.v-sheet.v-card {
 border-radius: .5rem;
}
</style>

<template>
  <div class="container">
    <v-row class="mt-10">
      <link
        href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.0/dist/css/bootstrap.min.css"
        rel="stylesheet"
        integrity="sha384-KyZXEAg3QhqLMpG8r+8fhAXLRk2vvoC2f3B09zVXn8CA5QIVfZOJ3BCsw2P0p/We"
        crossorigin="anonymous"
      />
      <v-row>
        <v-col cols="12" lg="9" sm="12" md="8">
          <!-- <top-list-coupons :session="session" /> -->
        </v-col>
        <v-col cols="6" lg="3" sm="4" md="4" class="mr-0 pr-0 text-end">
          <v-menu auto offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="#FF9C27" dark v-bind="attrs" v-on="on">
                <p class="pt-4" style="color: #002233 !important">
                  Filtrar por Colunas
                  <v-icon class="pb-1"
                    >mdi-arrow-down-drop-circle-outline</v-icon
                  >
                </p>
              </v-btn>
            </template>
            <v-list width="250" class="d-flex flex-wrap">
              <v-list-item
                v-for="(header, index) in headers"
                :key="index"
                class="py-0 my-0"
              >
                <v-list-item-title class="py-0 my-0">
                  <v-checkbox
                    class="py-0 my-0"
                    v-model="header.show"
                    :label="header.text"
                  ></v-checkbox>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
      <v-dialog
        v-model="dialog"
        transition="dialog-bottom-transition"
        max-width="350"
      >
        <dialog-coupon
          :closeDialog="closeDialog"
          :couponMessage="message"
          :validCoupon="false"
        />
      </v-dialog>

      <v-col class="mt-5">
        <v-row class="col-12  justify-space-between p-0">
          <v-select
            v-model="paginate.per_page"
            :items="amountPerPage"
            color="#FF9C27"
            class="col-6 col-lg-2 col-md-3 col-sm-4 ml-2"
            placeholder="Itens por Página"
            item-color="#FF9C27"
            outlined
            dense
          ></v-select>
          <div class="lg-col-8 d-flex flex-row justify-lg-end justify-md-end " style="padding: 1px;">
            <button
              class="col-6 col-lg-2 col-md-3 col-sm-5 lg-ml-2 lg-mt-1 justify-sm-center  btn-search mb-lg-5 btn-lg btn"
              @click="loadList(1)"
            >
              <h5 class="btn-search-text">Pesquisar</h5>
            </button>
          </div>
        </v-row>
        <v-overlay :value="overlay">
          <v-progress-circular
            color="#ff7d27"
            indeterminate
            size="64"
          ></v-progress-circular>
        </v-overlay>
        <v-row class="col-12 d-flex flex-row ">
          <div class="col-8 col-lg-4 col-md-3 col-sm-4 ml-md-auto text-lg-end text-md-end text-sm-center">
            <button class="clean-filter" @click="cleanFilter()">Limpar Filtros</button>
          </div>
        </v-row>

        <v-row>
          <div
            class="
              col-lg-12 col-md-12 col-sm-11 col-11
              table-responsive table-height
            "
          >
            <table
              class="table table-hover table-striped align-middle table-sticky"
              v-show="true"
            >
              <thead class="table-header-color">
                <tr class="">
                  <th scope="col" class="py-4 px-2 text-truncate">
                    <p class="header px-1">ID do Orçamento</p>
                  </th>
                  <th
                    scope="col"
                    v-for="(header, i) in headers"
                    :key="i"
                    class="py-4 text-truncate"
                    v-show="header.show"
                  >
                    <p class="header pl-5 header-border">
                      {{ header.text }}
                    </p>
                  </th>
                  <th scope="col" class="py-4 px-2 text-truncate">
                    <p class="header px-1 header-border">Detalhes</p>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text-center">
                    <input
                      type="text"
                      class="input-table col-lg-10 col-12"
                      placeholder="Buscar"
                      v-model="filters.findBudgetId"
                    />
                  </td>
                  <td class="text-center" v-show="headers[0].show">
                    <input
                      type="text"
                      class="input-table col-lg-10 col-12"
                      placeholder="Buscar"
                      v-model="filters.findCouponCode"
                    />
                  </td>
                  <td class="text-center" v-show="headers[1].show">
                    <input
                      type="text"
                      class="input-table col-lg-8 col-12"
                      placeholder="De"
                      v-model="filters.findBudgetMin"
                    />
                    <input
                      type="text"
                      class="input-table col-lg-8 col-12"
                      placeholder="Até"
                      v-model="filters.findBudgetMax"
                    />
                  </td>
                  <td class="text-center" v-show="headers[2].show">
                     <v-select
                        v-model="filters.findCouponType"
                        :items= typesCoupon
                        class="mt-4"
                        small
                        placeholder="Filtrar"
                        color="#FF9C27"
                        item-color="#FF9C27"
                        outlined
                        dense
                        title="Filtrar por tipo de cupom"
                      >
                      </v-select>
                  </td>
                  <td class="text-center" v-show="headers[3].show">
                  </td>
                  <td class="text-center" v-show="headers[4].show">
                  </td>
                  <td class="text-center" v-show="headers[5].show">
                  </td>
                  <td class="text-center" v-show="headers[6].show">
                  </td>
                  <td class="text-center" v-show="headers[7].show">
                    <input
                      type="text"
                      class="input-table col-lg-10 col-12"
                      placeholder="Buscar"
                      v-model="filters.findAdminName"
                    />
                  </td>
                  <td class="text-center" v-show="headers[8].show">
                    <input
                      type="text"
                      class="input-table col-lg-10 col-12"
                      placeholder="Buscar"
                      v-model="filters.findAdminTradeName"
                    />
                  </td>
                  <td class="text-center" v-show="headers[9].show">
                    <v-select
                      v-model="filters.findStatus"
                      :items= listStatus
                      class="mt-4"
                      small
                      placeholder="Filtrar por status"
                      color="#FF9C27"
                      item-color="#FF9C27"
                      outlined
                      dense
                      title="Filtrar por status"
                    >
                    </v-select>
                  </td>
                  <td class="text-center" ></td>
                  <td class="text-center" ></td>
                </tr>

                <tr scope="row" v-for="(coupon, i) in this.getCouponsList" :key="i">
                  <td class="text-center py-3 pl-5">
                    {{ coupon.budget_id }}
                  </td>
                  <td class="text-center py-3 pl-5" v-show="headers[0].show">
                    {{ coupon.coupon_code }}
                  </td>
                  <td class="text-center py-3 pl-5" v-show="headers[1].show">
                    {{
                      parseFloat(coupon.budget_value).toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      })
                    }}
                  </td>
                  <td class="text-center py-3 pl-5" v-show="headers[2].show">
                    {{ coupon.coupon_type }}
                  </td>
                  <td class="text-center py-3 pl-5" v-show="headers[3].show">
                    {{
                      coupon.generator_type == "Múltiplos"
                        ? coupon.generator_type
                        : coupon.generator_type == "mono"
                        ? "Monofásico"
                        : "Trifásico"
                    }}
                  </td>

                  <td class="text-center py-3 pl-5" v-show="headers[4].show">
                    {{
                      parseFloat(coupon.initial_discount).toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      })
                    }} ({{ calcPercentBudget(parseFloat(coupon.budget_value), parseFloat(coupon.initial_discount)) }}%)
                  </td>

                  <td class="text-center py-3 pl-5" v-show="headers[5].show">
                    {{
                      parseFloat(coupon.discount_value).toLocaleString(
                        "pt-br",
                        {
                          style: "currency",
                          currency: "BRL",
                        }
                      )
                    }} ({{ parseFloat(coupon.discount).toFixed(2) }}%)
                  </td>

                  <td class="text-center py-3 pl-5" v-show="headers[6].show">
                    {{
                      parseFloat(coupon.discount_total).toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      })
                    }} ({{ calcPercentBudget(parseFloat(coupon.budget_value), parseFloat(coupon.discount_total)) }}%)
                  </td>

                  <td class="text-center py-3 pl-5" v-show="headers[7].show">
                    {{ coupon.integrator_name }}
                  </td>

                  <td class="text-center py-3 pl-5" v-show="headers[8].show">
                    {{ coupon.integrator_trade }}
                  </td>

                  <td class="text-center py-3 pl-5" v-show="headers[9].show"
                    :class="coupon.active ? 'active' : 'pending'"
                  >
                    {{ definedStatus(coupon) }}
                  </td>
                  <td class="text-center py-3 pl-5" v-show="headers[10].show">
                    {{ coupon.unused ? "Não" : "Sim" }}
                  </td>
                  <td class="text-center py-3 pl-5" >
                    <button
                      class="btn-search btn-sm btn"
                      @click="coupon.dialog = true"
                    >
                      <h4 class="btn-search-text">Detalhes</h4>
                    </button>
                    <v-dialog
                      fullscreen
                      v-model="coupon.dialog"
                      transition="dialog-bottom-transition"
                    >
                      <dialog-details :coupon="coupon" :filters="filters" />
                    </v-dialog>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </v-row>
      </v-col>

      <v-col cols="12">
        <v-pagination
          class="paginate"
          v-model="paginate.page"
          :length="paginate.total_pages"
          color="#FF7D27"
          :total-visible="7"
          @input="loadList(paginate.page)"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
        ></v-pagination>
      </v-col>
    </v-row>
  </div>
</template>


<script>
import store from "@/store"
import PerfilMixin from "@/mixins/perfil_mixin"
import DialogDetails from '@/components/templates/GeneratorCoupon/DialogDetails.vue'
import { mapGetters } from 'vuex'

export default {
  mixins: [PerfilMixin],
  components: { DialogDetails },
  beforeRouteEnter (to, from, next) {
    const permission = PerfilMixin.computed.couponListViewPermissions()
    const userInfo = store('auth/getSession').getters['auth/getSession']
    if (permission.includes(userInfo.profile_id)) {
      next()
    } else {
      next({ path: '/' })
    }
  },
  mounted () {
    this.loadList(1)
  },
  data: () => {
    return {
      amountPerPage: [10, 20, 50, 70],
      listStatus : ['TODOS', 'PENDENTE', 'APROVADO', 'REPROVADO', 'NEGOCIADO', 'P/ DIRETORIA'],
      headers : [
      {
        text: "Código do Cupom",
        show: true,
      },
      {
        text: "Valor Orçamento",
        show: false,
      },
      {
        text: "Tipo de Cupom",
        show: true,
      },
      {
        text: "Tipo do Gerador",
        show: false,
      },
      {
        text: "Desconto Inicial Pelo Consultor",
        show: true,
      },
      {
        text: "Desconto Aprovado",
        show: true,
      },
      {
        text: "Total do Desconto Concedido",
        show: true,
      },
      {
        text: "Nome do Responsável",
        show: false,
      },
      {
        text: "Nome do Integrador",
        show: false,
      },
      {
        text: "Status",
        show: true,
      },
      {
        text: "Usado",
        show: true,
      },
      ],
      overlay : false,
      dialog : false,
      couponId : 0,
      dialogDetails : false,
      coupons: [],
      message:{
        title:"",
        body: ""
      },
      filters: {
        findGeneratedId: "",
        findBudgetId: "",
        findAdminId: "",
        findAdminName: "",
        findAdminTradeName: "",
        findRuleId: "",
        findDiscount: "",
        findCouponCode: "",
        findCouponType: "",
        findBudgetMin: "",
        findBudgetMax: "",
        findDiscountMax: "",
        findDiscountMin: "",
        findStatus: "",
      }
    }
  },
  computed: {
    ...mapGetters('coupon', ['getCouponsList' ]),
    ...mapGetters('coupon', { paginate: 'getPaginate' , typesCoupon: 'getTypesCoupon'})
  },
  methods: {
    loadList(page){
      this.overlay = true
      this.$store.dispatch('coupon/getlist', {filters: this.filters, page: page}).then(()=>{
        this.overlay = false
      })
    },
    calcPercentBudget(budget_value, value){
      let discount = ((100*value)/budget_value).toFixed(2)
      return discount
    },
    definedStatus(coupon){
      if(coupon.status == 'APROVADO'){
        if(coupon.initial_discount != coupon.discount_total ){
          return "NEGOCIADO"
        }else{
          return coupon.status
        }
      }else{
        return coupon.status
      }
    },
    closeDialog(){
      this.dialog = false;
    },
    cleanFilter() {
      this.filters = {
        findGeneratedId: "",
        findBudgetId: "",
        findAdminId: "",
        findAdminName: "",
        findAdminTradeName: "",
        findRuleId: "",
        findDiscount: "",
        findCouponCode: "",
        findCouponType: "",
        findBudgetMin: "",
        findBudgetMax: "",
        findDiscountMax: "",
        findDiscountMin: "",
        findStatus: "",
      }
    }
  }
}
</script>
<style>
.table-sticky > thead > tr > th,
.table-sticky > thead > tr > td {
  background: #002233;
  top: -12px;
  position: sticky;
}
.table-height {
  height: 480px;
  /* display: block; */
  /* overflow: scroll; */
  /* width: 50%; */
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.pending {
  color: #ff7d27;
}

.active {
  color: #00bfcf;
}

.table-header-color {
  text-align: center;
  background-color: #002233;
  color: white !important;
}

.clean-filter {
  color: #00bfcf !important;
}

.header {
  font-weight: normal;
  margin-bottom: 0 !important;
}
.header-border {
  border-left: solid 1px #adadad !important;
}

.input-table {
  border: 1.8px solid #adadad;
  border-radius: 5px;
  padding: 1px 4px;
  margin-bottom: 5px;
  height: 35px;
}

.btn-search {
  background-color: #002233 !important;
}

.btn-search-text {
  margin: 0;
  color: white;
  font-weight: bold;
  line-height: 1.5;
}
</style>

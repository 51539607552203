import {treatMoney} from '@/helper/treat_money'
const config = require('../../../../config')

export default {
  async getList ({rootState, state, commit}) {
    let headers = new Headers()
    headers.append("Content-Type", "application/json")
    headers.append('Authorization', 'Bearer '.concat(rootState.auth.session.token))
    const requestOptions = {
      method: "GET",
      headers: headers,
      redirect: "follow",
    };
    let baseGrandTotalMin = treatMoney(state.filters.filter_base_grand_total.min)
    let baseGrandTotalMax = treatMoney(state.filters.filter_base_grand_total.max)
    let grandTotalMin = treatMoney(state.filters.filter_grand_total.min)
    let grandTotalMax = treatMoney(state.filters.filter_grand_total.max)
    let totalRefundedMin = treatMoney(state.filters.filter_total_refunded.min)
    let totalRefundedMax = treatMoney(state.filters.filter_total_refunded.max)
    let subtotalMin = treatMoney(state.filters.filter_subtotal.min)
    let subtotalMax = treatMoney(state.filters.filter_subtotal.max)
    let urlFilters = `orders/?page=${state.paramsOrders.page}&perPage=${state.paramsOrders.perPage}&totalPage=${state.paramsOrders.totalPage}` +
      `&order=${state.paramsOrders.order}&column=${state.paramsOrders.column}&filter_order_id=${state.filters.filter_order_id}` +
      `&filter_business_name=${state.filters.filter_business_name}&filter_taxvat=${state.filters.filter_taxvat}` +
      `&filter_billing_region=${state.filters.filter_billing_region}&filter_billing_full=${state.filters.filter_billing_full}`  +
      `&filter_order_status=${state.filters.filter_order_status}&filter_payment_method=${state.filters.filter_payment_method}` +
      `&filter_commission_min=${state.filters.filter_commission.min}&filter_commission_max=${state.filters.filter_commission.max}` +
      `&filter_created_at_min=${state.filters.filter_created_at.min}&filter_created_at_max=${state.filters.filter_created_at.max}` +
      `&filter_base_grand_total_min=${baseGrandTotalMin}&filter_base_grand_total_max=${baseGrandTotalMax}&filter_grand_total_min=${grandTotalMin}&filter_grand_total_max=${grandTotalMax}` +
      `&filter_billing_address=${state.filters.filter_billing_address}&filter_shipping_address=${state.filters.filter_shipping_address}` +
      `&filter_email_customer=${state.filters.filter_email_customer}&filter_customer_group=${state.filters.filter_customer_group}` +
      `&filter_total_refunded_min=${totalRefundedMin}&filter_total_refunded_max=${totalRefundedMax}&filter_id_order_web_service=${state.filters.filter_id_order_web_service}` +
      `&filter_customer_id=${state.filters.filter_customer_id}&filter_prevenda_min=${state.filters.filter_prevenda.min}` +
      `&filter_prevenda_max=${state.filters.filter_prevenda.max}&filter_shipping_forecast_min=${state.filters.filter_shipping_forecast.min}` +
      `&filter_shipping_forecast_max=${state.filters.filter_shipping_forecast.max}&filter_status_stock=${state.filters.filter_status_stock}` +
      // `&filter_nsu=${state.filters.filter_nsu}` 
      // `&filter_offline_method=${state.filters.filter_offline_method}` +
      `&filter_credit_letter=${state.filters.filter_credit_letter}&filter_subtotal_min=${subtotalMin}&filter_subtotal_max=${subtotalMax}`
    if (rootState.auth.session.profile_id !== '6') {
      urlFilters = urlFilters.concat(
        `&filter_seller_rca=${state.filters.filter_seller_rca}` +
        `&filter_seller_name=${state.filters.filter_seller_name}`
      )
    }
    const url = config.url.concat(urlFilters)
    const resp = await fetch(url, requestOptions)
    console.log(resp)
    if (!resp.ok) {
      let message = resp.status==404?`Error! status: ${resp.status}`:(await resp.json()).message
      console.log(new Error(`Error! status: ${resp.status}`));
      return {"ok": false, "message": message}
    } else {
      const response = await resp.json()
      const orders = response.data.orders
      commit('setOrders', orders)
      commit('setPage', response.data.page)
      commit('setPerPage', response.data.per_page)
    }
  }
}
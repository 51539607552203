<template>
  <v-row class="mx-0 w-100 h-100">
    <v-col
      cols="12"
      sm="10"
      lg="7"
      md="10"
      class="d-none d-lg-flex flex-grow-1 column-left"
    >
      <v-carousel
          cycle
          height="800"
          :show-arrows="false"
          hide-delimiter-background
          hide-delimiters
          interval="3500"
      >
        <v-carousel-item
            v-for="(slide, i) in ImagesCarrosselLogin"
            :key="i"
        >
          <v-sheet
              :color="colors[i]"
              height="100%"
          >
            <div align="center" class="titles" v-if="slide.titleTag === 'h1'">
              <p>{{ slide.title }}</p>
              <h1 class="pl-10">{{ slide.subtitle }}</h1>
            </div>
            <div class="titles" v-else-if="slide.titleTag === 'h2'">
              <h2 align="left" :class="slide.class">{{ slide.title }}</h2>
              <p class="descriptions">{{ slide.description }}</p>
            </div>
            <v-row
                class="fill-height"
                align="center"
                justify="center"
            >
              <v-img
                  class="images-carousel"
                  max-width="400"
                  height="min-content"
                  :src="slide.img"
              >
              </v-img>
            </v-row>
          </v-sheet>
        </v-carousel-item>
      </v-carousel>
    </v-col>
        <v-col
            class="justify-center ml-lg-12"
            cols="12"
            sm="10"
            md="12"
            lg="4"
            align-self="center"
        >
          <div class="d-flex justify-center ml-lg-10 mb-lg-12">
            <v-img
                class="mb-12"
                max-width="50%"
                :src="require('@/assets/Logo-Sou-Energy.webp')"
            ></v-img>
          </div>
          <div v-if="editForm === false">
            <v-col
                class="justify-center ml-lg-12 mt-12">
              <v-card
                  class="card-form" elevation="0">
                <div class="d-flex justify-center" v-if="loading">
                  <v-progress-circular
                      indeterminate
                      color="#FFB74D"
                  ></v-progress-circular>
                </div>
                <div class="px-md-16 px-lg-0" v-else>
                  <v-form ref="formLogin" @input="validate($event)">
                    <v-row>
                     <span class="text-transform-none black--text font-weight-bold ml-3">Usuário</span>
                      <v-col cols="12" lg="12" md="12" sm="4">
                        <v-text-field
                            class="field-user"
                            v-model="username"
                            :rules="[rules.required]"
                            placeholder="Usuário"
                            background-color="#E5E5E5"
                            color="#F54C11"
                            elevation="0"
                            autofocus
                            solo
                            flat
                            filled
                            dense
                            minLength="8"
                            @keydown.enter="keyEnter"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
            <span class="text-transform-none black--text font-weight-bold ml-3">Senha</span>
                      <v-col cols="12" sm="10" md="12" lg="12">
                        <v-text-field
                            ref="password"
                            v-model="password"
                            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                            :rules="[rules.required, rules.min]"
                            :type="show2 ? 'text' : 'password'"
                            placeholder="Senha"
                            color="#F54C11"
                            background-color="#E5E5E5"
                            filled
                            flat
                            dense
                            solo
                            minLength="8"
                            @keydown.enter="login"
                            @click:append="show2 = !show2"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                  <div class="d-flex justify-start">
                    <v-btn
                        text
                        style="text-decoration: none"
                        @click="changePassword">
                    <span style="display: inline;" class="remember-password">
                      Esqueceu sua senha?
                    </span>
                    </v-btn>
                  </div>
                  <v-card-actions>
                    <v-btn
                        type="button"
                        style="top: 20px"
                        class="px-lg-10 py-lg-6 rounded-lg"
                        elevation="0"
                        block
                        :disabled="valid"
                        color="deep-orange"
                        @click="login"
                    >
                      <span class="button-login">Entrar</span>
                    </v-btn>
                  </v-card-actions>
                </div>
              </v-card>
            </v-col>
          </div>
          <div class="px-md-16" v-else>
            <v-col class="justify-center ml-lg-12 mt-12">
              <div class="d-flex justify-center" v-if="loading">
                <v-progress-circular
                    indeterminate
                    color="#FFB74D"
                ></v-progress-circular>
              </div>
              <div v-else>
                <v-card
                    v-model="rememberPassword"
                    elevation="0"
                >
                  <v-card-text
                      style="text-align:left; color:#F9820B;"
                      class="mb-5">
                    <h2>Redefinição de senha</h2>
                  </v-card-text>
                  <v-card-text style="text-align:left" class="mb-5">
                    <span>Digite seu endereço de e-mail. Você receberá um e-mail com um link para redefinir sua senha</span>
                  </v-card-text>
                  <span class="d-flex justify-start text-transform-none black--text font-weight-bold ml-3">Endereço de e-mail</span>
                  <v-col cols="12" lg="12" md="12" sm="4">
                    <v-text-field
                        v-model="emailAddress"
                        :rules="[rules.required, rules.emailValid]"
                        placeholder="Usuário"
                        color="#F54C11"
                        background-color="#E5E5E5"
                        filled
                        flat
                        dense
                        solo
                        elevation="0"
                        minLength="8"
                    ></v-text-field>
                    <v-card-actions>
                      <v-btn
                          type="button"
                          style="top: 20px"
                          class="px-lg-10 py-lg-6 rounded-lg"
                          elevation="0"
                          block
                          color="deep-orange"
                          @click="resetPassword"
                      >
                        <span class="button-login">Entrar</span>
                      </v-btn>
                    </v-card-actions>
                  </v-col>
                  <v-btn
                      text
                      class="d-flex justify-start ml-1 mt-5"
                      @click="backLogin">
                  <span style="display: inline; color:#F9820B;" class="remember-password">
                Voltar ao Login
                  </span>
                  </v-btn>
                </v-card>
              </div>
            </v-col>
          </div>
        </v-col>
  </v-row>
</template>

<script>
import login_mixin from '@/mixins/login_mixin.js'
export default {
  name: "Login-View",
  mixins: [login_mixin],
  data: () => {
    return {
      rememberPassword: false,
      editPassword: false,
      editForm: false,
      loading: false,
      username: '',
      password: '',
      emailAddress: '',
      show1: false,
      show2: false,
      valid: false,
      rules: {
        required: (value) => !!value || "Campo obrigatório.",
        min: (v) => v.length >= 8 || "O número mínimo caracteres é 8",
        emailValid:  v => /.+@.+\..+/.test(v) || 'O endereço de e-mail precisa ser válido',
      },
      colors: [
        '#F54C11',
        '#F54C11',
        '#F54C11',
        '#F54C11',
        '#F54C11',
      ],
    };
  },
  methods: {
    resetPassword () {
      this.loading = true
      this.$store.dispatch('auth/resetPassword', {'emailAddress': this.emailAddress})
      .then((resp) => {
        if (!resp.ok) {
          console.log(resp.message)
        }
        this.loading = false
      })
    },
    changePassword () {
      this.editForm = true
    },
    backLogin () {
      this.editForm = false
    },
    login () {
      this.loading = true
      this.$store.dispatch('auth/login', {'username': this.username, 'password': this.password})
        .then((resp) => {
            if (!resp.ok) {
              console.log(resp.message)
            }
            this.$router.push('/')
            this.loading = false
        })
    },
    keyEnter (e) {
      if (e.keyCode === 13) {
        this.$refs.password.focus()
      }
    },
    validate(event) {
      this.valid = !event
    },
  },
};
</script>


<style>

.button-login {
  font-weight: bold;
  color: white;
}

.remember-password {
  color: #f54c11;
}

.column-left {
  background-color: #f54c11;
}

.field-user {
  font-size: 1.6rem;
  border-radius: 1px;
}

.images-carousel {
  margin-bottom: 380px;
}

.titles {
  font-family: 'Kumbh Sans', sans-serif;
  line-height: 110px;
  font-size: 54px;
  margin-top: 80px;
  color: white;
  font-weight: 900;
}

.descriptions {
  font-family: 'Kumbh Sans', sans-serif;
  font-weight: 400;
  line-height: 30px;
  font-size: 28px;
  padding-left: 13rem;
  padding-right: 13rem;
  text-transform: none;
  text-align: left;
}

.title-carousel {
  font-family: 'Kumbh Sans', sans-serif;
  line-height: 80px;
  padding-right: 13rem;
  padding-left: 13rem;
  font-weight: 900;
  font-size: 48px;
}

.secondary-titles {
  font-family: 'Kumbh Sans', sans-serif;
  line-height: 80px;
  padding-right: 13rem;
  padding-left: 13rem;
  font-weight: 900;
  font-size: 48px;
}
</style>



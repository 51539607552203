<template>
  <div class="text-left">
    <v-btn
      elevation="0"
      small
      color="souPrimary"
      @click="dialog = true"
    >
      <v-icon
        color="#fff"
        dense
        left
      >mdi-filter-outline</v-icon>
      <span class="white--text text-transform-none">Todos os Filtros</span>
    </v-btn>
    <v-dialog
      :key="filtersKey"
      v-model="dialog"
      persistent
      max-width="900px"
    >
      <v-card
        class="px-3"
        color="#fff"
      >
        <v-card-actions class="pt-9">
          <h3 class="text-left">Filtros</h3>
          <v-spacer></v-spacer>
          <v-icon
            color="souPrimary"
            large
            @click="dialog = false"
          >mdi-close-circle-outline</v-icon>
        </v-card-actions>
        <v-container class="my-4 px-4">
          <v-row>
            <v-col
              v-for="(column, i) in activeColumns"
              :key="i"
              cols="12"
              lg="3"
              md="6"
              sm="12"
            >
              <div v-if="column.fieldType === 'text'">
                <field-text :infoField="column" />
              </div>
              <div v-if="column.fieldType === 'textDuo'">
                <field-text-duo :infoField="column" />
              </div>
              <div v-if="column.fieldType === 'select'">
                <field-select :infoField="column" />
              </div>
              <div v-if="column.text === 'Repasse'">
                <v-menu
                  ref="commissionMenu"
                  v-model="commissionMenu"
                  :close-on-content-click="false"
                  :return-value.sync="commissionDates"
                  transition="scale-transition"
                  bottom
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <h4 class="font-weight-bold subtitle-2 text-left souPrimary--text mb-2">{{column.text}}</h4>
                    <div @click="commissionMenu = true">
                      <v-text-field
                        class="filter-data-orders"
                        placeholder="Selecione as datas"
                        v-model="dateRangeTextCommissionDate"
                        append-icon="mdi-calendar"
                        background-color="#fff"
                        color="souPrimary"
                        outlined
                        dense
                        solo
                        flat
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </div>
                  </template>
                  <v-date-picker
                    v-model="commissionDates"
                    no-title
                    scrollable
                    color="souPrimary"
                    range
                    locale="PT-br"
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="souPrimary"
                      @click="commissionMenu = false"
                    >
                      Cancelar
                    </v-btn>
                    <v-btn
                      text
                      color="souPrimary"
                      :disabled="commissionMenuDisabled"
                      @click="saveCommission"
                    >
                      Salvar
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </div>
              <div v-if="column.text === 'Data de Compra'">
                <v-menu
                  ref="createdDatesMenu"
                  v-model="createdDatesMenu"
                  :close-on-content-click="false"
                  :return-value.sync="createdDates"
                  transition="scale-transition"
                  bottom
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <h4 class="font-weight-bold subtitle-2 text-left souPrimary--text mb-2">{{column.text}}</h4>
                    <div @click="createdDatesMenu = true">
                      <v-text-field
                        class="filter-data-orders"
                        placeholder="Selecione as datas"
                        v-model="dateRangeTextCreatedDate"
                        append-icon="mdi-calendar"
                        background-color="#fff"
                        color="souPrimary"
                        outlined
                        dense
                        solo
                        flat
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </div>
                  </template>
                  <v-date-picker
                    v-model="createdDates"
                    no-title
                    scrollable
                    color="souPrimary"
                    range
                    locale="PT-br"
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="souPrimary"
                      @click="createdDatesMenu = false"
                    >
                      Cancelar
                    </v-btn>
                    <v-btn
                      text
                      color="souPrimary"
                      :disabled="createdDatesMenuDisabled"
                      @click="saveCreatedDates"
                    >
                      Salvar
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </div>
              <div v-if="column.text === 'Pré-venda'">
                <v-menu
                  ref="preSaleMenu"
                  v-model="preSaleMenu"
                  :close-on-content-click="false"
                  :return-value.sync="preSaleDates"
                  transition="scale-transition"
                  bottom
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <h4 class="font-weight-bold subtitle-2 text-left souPrimary--text mb-2">{{column.text}}</h4>
                    <div @click="preSaleMenu = true">
                      <v-text-field
                        class="filter-data-orders"
                        placeholder="Selecione as datas"
                        v-model="dateRangeTextPreSaleDate"
                        append-icon="mdi-calendar"
                        background-color="#fff"
                        color="souPrimary"
                        outlined
                        dense
                        solo
                        flat
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </div>
                  </template>
                  <v-date-picker
                    v-model="preSaleDates"
                    no-title
                    scrollable
                    color="souPrimary"
                    range
                    locale="PT-br"
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="souPrimary"
                      @click="preSaleMenu = false"
                    >
                      Cancelar
                    </v-btn>
                    <v-btn
                      text
                      color="souPrimary"
                      :disabled="preSaleMenuDisabled"
                      @click="savePreSaleDates"
                    >
                      Salvar
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </div>
              <div v-if="column.text === 'Previsão de Expedição'">
                <v-menu
                  ref="newPreSaleMenu"
                  v-model="newPreSaleMenu"
                  :close-on-content-click="false"
                  :return-value.sync="newPreSaleDates"
                  transition="scale-transition"
                  bottom
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <h4 class="font-weight-bold subtitle-2 text-left souPrimary--text mb-2">{{column.text}}</h4>
                    <div @click="newPreSaleMenu = true">
                      <v-text-field
                        class="filter-data-orders"
                        placeholder="Selecione as datas"
                        v-model="dateRangeTextNewPreSaleDate"
                        append-icon="mdi-calendar"
                        background-color="#fff"
                        color="souPrimary"
                        outlined
                        dense
                        solo
                        flat
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </div>
                  </template>
                  <v-date-picker
                    v-model="newPreSaleDates"
                    no-title
                    scrollable
                    color="souPrimary"
                    range
                    locale="PT-br"
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="souPrimary"
                      @click="newPreSaleMenu = false"
                    >
                      Cancelar
                    </v-btn>
                    <v-btn
                      text
                      color="souPrimary"
                      :disabled="newPreSaleMenuDisabled"
                      @click="saveNewPreSaleDates"
                    >
                      Salvar
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </div>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions class="pb-9">
          <v-btn
            elevation="0"
            color="souPrimary"
            @click="apply"
          >
            <span class="white--text text-transform-none">
              Aplicar Filtros
            </span>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            elevation="0"
            color="souPrimary"
            outlined
            @click="cleanFilter"
          >
            <span class="text-transform-none">
              Limpar Filtros
            </span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import OperationMixin from '@/mixins/operations_mixin'
import FieldText from '@/components/templates/orders/field_types/FieldText'
import FieldTextDuo from '@/components/templates/orders/field_types/FieldTextDuo'
import FieldSelect from '@/components/templates/orders/field_types/FieldSelect'
import { mapGetters } from 'vuex'

export default {
  mixins: [OperationMixin],
  props: {
    filters: Array
  },
  components: {
    FieldText,
    FieldTextDuo,
    FieldSelect
  },
  data: () => ({      
    dialog: false,
    filtersKey: 0,
    commissionDates: [],
    commissionMenu: false,
    commissionMenuDisabled: true,
    preSaleDates: [],
    preSaleMenu: false,
    preSaleMenuDisabled: true,
    newPreSaleDates: [],
    newPreSaleMenu: false,
    newPreSaleMenuDisabled: true,
    createdDates: [],
    createdDatesMenu: false,
    createdDatesMenuDisabled: true      
  }),
  beforeMount () {
    this.valueCommission()
    this.valueCreatedDates()
    this.valuePreSaleDates()
    this.valueNewPreSaleDates()
  },
  computed: {
    ...mapGetters('auth', ['getSession']),
    ...mapGetters('orders', ['getFilters']),
    activeColumns() {
      return this.getSession.profile_id !== '6' ? 
        this.filters.filter(header => header.active) :
        this.filters.filter(
          header => header.active &&
          header.filter !== 'filter_seller_rca' &&
          header.filter !== 'filter_seller_name'
        )
    },
  },
  watch: {
    commissionDates (value) {
      this.commissionMenuDisabled = value.length > 1 ? false : true
    },
    createdDates (value) {
      this.createdDatesMenuDisabled = value.length > 1 ? false : true
    },
    preSaleDates (value) {
      this.preSaleMenuDisabled = value.length > 1 ? false : true
    },
    newPreSaleDates (value) {
      this.newPreSaleMenuDisabled = value.length > 1 ? false : true
    },
    deliveryTimeDates (value) {
      this.deliveryTimeMenuDisabled = value.length > 1 ? false : true
    }
  },
  methods: {
    valueCommission () {
      this.commissionDates = [
        this.getFilters.filter_commission.min,
        this.getFilters.filter_commission.max
      ]
    },
    valueCreatedDates () {
      this.createdDates = [
        this.getFilters.filter_created_at.min,
        this.getFilters.filter_created_at.max
      ]
    },
    valuePreSaleDates () {
      this.preSaleDates = [
        this.getFilters.filter_prevenda.min,
        this.getFilters.filter_prevenda.max
      ]
    },
    valueNewPreSaleDates () {
      this.newPreSaleDates = [
        this.getFilters.filter_shipping_forecast.min,
        this.getFilters.filter_shipping_forecast.max
      ]
    },
    saveCommission () {
      if (this.commissionDates[0] <= this.commissionDates[1]) {
        this.$store.commit(
          'orders/setFilterDate',
          {
            'filter': 'filter_commission',
            'value': {
              'min': this.commissionDates[0],
              'max': this.commissionDates[1] 
            }
          }
        )
      } else if (this.commissionDates[1] <= this.commissionDates[0]) {
        this.$store.commit(
          'orders/setFilterDate',
          {
            'filter': 'filter_commission',
            'value': {
              'min': this.commissionDates[1],
              'max': this.commissionDates[0] 
            }
          }
        )
      }
      this.$refs.commissionMenu[0].save(this.commissionDates)
    },
    saveCreatedDates () {
      if (this.createdDates[0] <= this.createdDates[1]) {
        this.$store.commit(
          'orders/setFilterDate',
          {
            'filter': 'filter_created_at',
            'value': {
              'min': this.createdDates[0],
              'max': this.createdDates[1] 
            }
          }
        )
      } else if (this.createdDates[1] <= this.createdDates[0]) {
        this.$store.commit(
          'orders/setFilterDate',
          {
            'filter': 'filter_created_at',
            'value': {
              'min': this.createdDates[1],
              'max': this.createdDates[0] 
            }
          }
        )
      }
      this.$refs.createdDatesMenu[0].save(this.createdDates)
    },
    savePreSaleDates () {
      if (this.preSaleDates[0] <= this.preSaleDates[1]) {
        this.$store.commit(
          'orders/setFilterDate',
          {
            'filter': 'filter_prevenda',
            'value': {
              'min': this.preSaleDates[0],
              'max': this.preSaleDates[1] 
            }
          }
        )
      } else if (this.preSaleDates[1] <= this.preSaleDates[0]) {
        this.$store.commit(
          'orders/setFilterDate',
          {
            'filter': 'filter_prevenda',
            'value': {
              'min': this.preSaleDates[1],
              'max': this.preSaleDates[0] 
            }
          }
        )
      }
      this.$refs.preSaleMenu[0].save(this.preSaleDates)
    },
    saveNewPreSaleDates () {
      if (this.newPreSaleDates[0] <= this.newPreSaleDates[1]) {
        this.$store.commit(
          'orders/setFilterDate',
          {
            'filter': 'filter_shipping_forecast',
            'value': {
              'min': this.newPreSaleDates[0],
              'max': this.newPreSaleDates[1] 
            }
          }
        )
      } else if (this.newPreSaleDates[1] <= this.newPreSaleDates[0]) {
        this.$store.commit(
          'orders/setFilterDate',
          {
            'filter': 'filter_shipping_forecast',
            'value': {
              'min': this.newPreSaleDates[1],
              'max': this.newPreSaleDates[0] 
            }
          }
        )
      }
      this.$refs.newPreSaleMenu[0].save(this.newPreSaleDates)
    },
    apply () {
      this.dialog = false
      this.$emit('applyFilters')
    },
    cleanFilter () {
      this.dialog = false
      this.filtersKey += 1
      this.$store.commit('orders/cleanFilter')
      this.$emit('applyFilters')
    }
  }
}
</script>
<template>
  <div class="text-center">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-bottom="10"
      :nudge-left="365"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="btn-filter-theme"
          elevation="0"
          color="#fff"
          small
          block
          v-bind="attrs"
          v-on="on"
        >
          <v-icon
            color="#000"
            dense
            left
          >mdi-cog-outline</v-icon>
          <span class="black--text text-transform-none">Colunas</span>
        </v-btn>
      </template>

      <v-card
        max-width="500px"
      >
        <v-list>
          <v-subheader class="font-weight-bold">Colunas</v-subheader>
        </v-list>
        <v-divider></v-divider>
        <div class="px-4 box-columns">
          <v-row class="my-4">
            <v-col
              v-for="(column, i) in columns"
              :key="i"
              cols="4"
              class="pt-0 pb-2"
            >
              <v-checkbox
                class="mt-0 pt-0"
                color="souPrimary"
                v-model="column.active"
                :label="column.text"
                :hide-details="true"
              ></v-checkbox>
            </v-col>
          </v-row>
        </div>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            text
            @click="menu = false"
          >
            <span class="text-transform-none">Cancelar</span>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            v-if="viewRca == true"
            elevation="0"
            color="souPrimary"
            outlined
            @click="replace('visão cliente')"
          >
            <span class="text-transform-none">Visão do Cliente</span>
          </v-btn>
          <v-btn
            v-else
            elevation="0"
            color="souPrimary"
            outlined
            @click="replace('visão rca')"
          >
            <span class="text-transform-none">Visão do Consultor</span>
          </v-btn>
          <v-btn
            elevation="0"
            color="souPrimary"
            @click="reset"
          >
            <span class="white--text text-transform-none">
              Reiniciar
            </span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: 'columns-widgets',
  props: {
    columns: Array,
    viewRca: Boolean
  },
  data: () => ({
    menu: false
  }),
  methods: {
    reset() {
      this.menu = false
      this.$emit('reset')
    },
    replace (view) {
      this.menu = false
      this.$emit('replaceView', {view})
    }
  }
}
</script>

<style scoped>
.box-columns {
  height: 250px;
  overflow: auto;
  position: relative;
}
</style>
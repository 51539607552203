<template>
  <v-col cols="12" lg="6" md="8" sm="10" class="">
    <table class="table align-middle">
      <thead class=""></thead>

      <tbody>
        <tr class="">
          <td class="text-center border col-4">
            <h5>Código do Cupom</h5>
          </td>
          <td class="text-center border col-4">
            <h5>Quantidade</h5>
          </td>
          <td class="text-center border col-4">
            <h5>Valor</h5>
          </td>
        </tr>
        <tr
          scope="row"
          v-for="(couponActive, i) in couponDetails.coupons_added"
          :key="i"
        >
          <td class="text-center border col-4">{{ couponActive.name }}</td>
          <td class="text-center border">
            {{ couponActive.salesrule_qty }}
          </td>
          <td class="text-center border col-4">
            {{
              parseFloat(couponActive.discount_amount).toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })
            }}
          </td>
        </tr>

        <tr scope="row">
          <td class="text-center border">Total</td>
          <td class="text-center border">
            {{ total.qty }}
          </td>
          <td class="text-center border">
            {{
              total.value.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })
            }}
          </td>
        </tr>
      </tbody>
    </table>
  </v-col>
</template>

<script>

export default {
  name: 'DetailsCouponAttach',
  props: { 
    couponDetails: {
      admin_id: Number,
      budget_id: Number,
      budget_value: String,
      coupon_code: String,
      discount: String,
      discount_value: String,
      discount_total: String,
      initial_discount: String,
      generated_id: Number,
      rule_id: Number,
      dialog: Boolean,
      coupon_name: String,
      description: String,
      integrator_id: Number,
      integrator_name: String,
      integrator_trade: String,
      integrator_trade_name: String,
      active: Boolean,
      max_discount: Number,
      value_equipment: Number,
      unused: Boolean,
      coupon_type: String,
      generator_type: String,
      payment_type: String,
      status: String,
    }
  },
  data: () => {
    return {
      total: {
        qty: 0,
        value: 0,
      }
    }
  },
  watch:{
    'couponDetails.coupons_added'() {
      this.couponDetails.coupons_added.forEach((value) => {
        this.total.qty += value.salesrule_qty;
        this.total.value += parseFloat(value.discount_amount) * value.salesrule_qty
      });
    }
  }
}

</script>

<style>
.title-dialog-generate {
  font-weight: bold;
  color: black !important;
}

.buttom-close:hover {
  cursor: pointer;
}

.message-dialog {
  font-family: "Roboto", sans-serif;
  line-height: 1.5;
}

.buttom-coupon {
  color: white !important;
}
</style>

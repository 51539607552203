<template>
  <div class="values px-10">
    <v-row class="py-10">
      <v-col
          class="col-lg-3 col-md-3 col-sm-6 col-12 value border-sm-right-none"
      >
        <p class="mx-auto my-0 text-center text-truncate text-h6">
          Valor de Equipamentos
        </p>
        <h1 class="text-center informations text-truncate">
          {{
            budget.equipamentValue.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })
          }}
        </h1>
      </v-col>

      <v-col
          class="col-lg-3 col-md-3 col-sm-6 col-12 value border-md-right-none"
      >
        <p class="mx-auto text-center my-0 text-truncate text-h6">
          Valor de Repasse
        </p>
        <h1 class="text-center informations text-truncate">
          {{
            budget.transferValue.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })
          }}
        </h1>
      </v-col>

      <v-col
          class="col-lg-3 col-md-3 col-sm-6 col-12 value border-sm-right-none"
      >
        <p class="mx-auto my-0 text-center text-truncate text-h6">
          Valor do Orçamento
        </p>
        <h1 class="text-center informations text-truncate">
          {{
            budget.value.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })
          }}
        </h1>
      </v-col>

      <v-col class="col-lg-3 col-md-3 col-sm-6 col-12">
        <p class="mx-auto my-0 text-center text-truncate text-h6">
          Desconto Permitido
        </p>
        <h1 class="text-center informations text-truncate">
          {{
            budget.discountMaxBrl.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })
          }}
        </h1>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters('coupon', {budget: "getBudget"})
  }
}
</script>

<style>
@media (max-width: 959.98px) {
  .border-md-right-none {
    border-right: none !important;
  }
}

@media (max-width: 599.98px) {
  .border-sm-right-none {
    border-right: none !important;
  }
}

.values {
  background: #f8f8f8;
  border-top: 1px solid #adadad;
  border-bottom: 1px solid #adadad;
  /* display: flex; */
  /* flex-flow: row wrap; */
}

.value {
  border-right: 2px solid #adadad;
}
.informations {
  color: #00bfcf;
}
</style>

'use strict'
// Template version: 1.3.1
// see http://vuejs-templates.github.io/webpack for documentation.

// const path = require('path')

module.exports = {

    // url: 'http://localhost:9001/api/',
    url: 'https://apidbadminh.souenergy.com.br/api/',
    // baseUrlSite: 'https://souenergy.com.br/',
    baseUrlSite: 'https://homolog.souenergy.com.br/',
    // urlGenerator: 'http://localhost:8000/api/',
    urlGenerator: 'https://apigch.souenergy.com.br/api/'

}

const config = require('../../../../config')

export default {


    async login ({commit, dispatch}, params) {
        let formData = new FormData
        formData.append('username', params.username)
        formData.append('password', params.password)
        var url = config.url.concat('login')
        var requestOptions = {
            method: 'POST',
            body: formData,
            redirect: 'follow'
        }
        const resp = await fetch(url, requestOptions)
        if (!resp.ok) {
            let message = resp.status==404?`Error! status: ${resp.status}`:(await resp.json()).message
            console.log(new Error(`Error! status: ${resp.status}`));
            return {"ok": false, "message": message}
        }else{
            const response = await resp.json();
            commit('setToken', response.token)
            await dispatch("info")
            return {"ok": true, "message": response}
        }
    },

    async logout ({commit, state}) {
        let headers = new Headers()
        headers.append('Authorization', 'Bearer '.concat(state.session.token))
        let formData = new FormData
        formData.append('id', 247)
        var url = config.url.concat('logout')
        var requestOptions = {
            method: 'POST',
            headers: headers,
            body: formData,
            redirect: 'follow'
        }
        const resp = await fetch(url, requestOptions)
        if (!resp.ok) {
            let message = resp.status==404?`Error! status: ${resp.status}`:(await resp.json()).message
            console.log(new Error(`Error! status: ${resp.status}`));
            return {"ok": false, "message": message}
        }else{
            commit('setToken', '')
            location.reload()
        }
    },

    async info ({commit, state}) {
        let headers = new Headers()
        headers.append('Authorization', 'Bearer '.concat(state.session.token))

        var url = config.url.concat('info')
        var requestOptions = {
            method: 'POST',
            headers: headers,
            redirect: 'follow'
        }
        const resp = await fetch(url, requestOptions)
        if (!resp.ok) {
            let message = resp.status==404?`Error! status: ${resp.status}`:(await resp.json()).message
            console.log(new Error(`Error! status: ${resp.status}`));
            return {"ok": false, "message": message}
        }else{
            const response = await resp.json();
            commit('setUserInfo', response.data)
        }
    },

    async resetPassword ({commit}, params) {
        let formData = new FormData
        formData.append('emailAddress', params.emailAddress)
        var url = config.url.concat('login')
        var requestOptions = {
            method: 'POST',
            body: formData,
            redirect: 'follow'
        }
        const resp = await fetch(url, requestOptions)
        if (!resp.ok) {
            let message = resp.status==404?`Error! status: ${resp.status}`:(await resp.json()).message
            console.log(new Error(`Error! status: ${resp.status}`));
            return {"ok": false, "message": message}
        } else {
            commit('setEmailAddress')
        }
    },

}




<template>
  <div>
    <h4 class="font-weight-bold subtitle-2 text-left souPrimary--text mb-2">{{infoField.text}}</h4>
    <v-select
      :items="fieldItems"
      v-model="fieldValue"
      multiple
      chips
      color="souPrimary"
      item-color="souPrimary"
      dense
      outlined
    ></v-select>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  props: {
    infoField: Object
  },
  data: () => ({
    fieldItems: [],
    fieldValue: ''
  }),
  beforeMount() {
    this.getFieldOptions()
    this.verifyField()
  },
  computed: {
    ...mapGetters(
      'orders',
      [
        'getFilters',
        'getOrderStatusOptions',
        'getPaymentMethodsOptions',
        'getCustomerGroupOptions',
        'getOffilineMethodOptions',
        'getCreditLetterOptions'
      ])
  },
  watch: {
    fieldValue (value) {
      this.$store.commit(
        'orders/setFilterTextAndSelect',
        {
          'filter': this.infoField.filterLabel,
          'value': value
        }
      )
    }
  },
  methods: {
    getFieldOptions () {
      switch (this.infoField.filterLabel) {
        case 'filter_order_status':
          this.fieldItems = this.getOrderStatusOptions
          break
        case 'filter_payment_method':
          this.fieldItems = this.getPaymentMethodsOptions
          break
        case 'filter_customer_group':
          this.fieldItems = this.getCustomerGroupOptions
          break
        // case 'filter_offline_method':
        //   this.fieldItems = this.getOffilineMethodOptions
        //   break
        case 'filter_credit_letter':
          this.fieldItems = this.getCreditLetterOptions
          break
      }
    },
    verifyField () {
      switch (this.infoField.filterLabel) {
        case 'filter_order_status':
          this.fieldValue = this.getFilters.filter_order_status
          break
        case 'filter_payment_method':
          this.fieldValue = this.getFilters.filter_payment_method
          break
        case 'filter_customer_group':
          this.fieldValue = this.getFilters.filter_customer_group
          break
        // case 'filter_offline_method':
        //   this.fieldValue = this.getFilters.filter_offline_method
        //   break
        case 'filter_credit_letter':
          this.fieldValue = this.getFilters.filter_credit_letter
          break
        default :
          this.fieldValue = ''
      }
    }
  }
}
</script>
export default {
    session: {
        id: 0,
        token: "",
        profile: "",
        profile_id: undefined,
        role_id: undefined,
    },
    drawer: true,
    setEmailAddress: ''
}


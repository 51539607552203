<template>
<!--    <v-progress-circular-->
<!--        v-model="loading"-->
<!--        indeterminate-->
<!--        size="64"-->
<!--    ></v-progress-circular>-->
  <v-row
  class="mt-10"
  >
    <v-col cols="12">
      <form-budget />
    </v-col>

    <v-col cols="12" v>
      <data-integrator />
    </v-col>

    <v-col cols="12">
      <data-budget />
    </v-col>

    <v-col cols="12">
      <form-coupon />
    </v-col>

  </v-row>
</template>

<script>
import FormBudget from "@/components/templates/GeneratorCoupon/FormBudget"
import DataIntegrator from "@/components/templates/GeneratorCoupon/DataIntegrator"
import DataBudget from "@/components/templates/GeneratorCoupon/DataBudget"
import FormCoupon from "@/components/templates/GeneratorCoupon/FormCoupon"

import store from "@/store"
import PerfilMixin from "@/mixins/perfil_mixin"

export default {
  mixins: [PerfilMixin],
  beforeRouteEnter (to, from, next) {
    const permission = PerfilMixin.computed.couponGeneratorViewPermissions()
    const userInfo = store('auth/getSession').getters['auth/getSession']
    if (permission.includes(userInfo.profile_id)) {
      next()
    } else {
      next({ path: '/' })
    }
  },
  components: {
    FormBudget,
    DataIntegrator,
    DataBudget,
    FormCoupon
  },
  mounted () {
    this.loadIndex()
  },
  data: () => {
    return {
      loading: true
    }
  },
  methods: {
    loadIndex() {
      this.$store.dispatch("coupon/getSessionUser").then((response) => {
        if (response) {
          this.show = true;
          this.overlay = false;
        } else {
          this.$router.replace("/");
        }
      });
    }
  }
}

</script>

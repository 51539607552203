<template>
    <svg :width="width" :height="height" viewBox="0 0 56 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M41.14 22.4751H9.35498V27.1451H41.14V22.4751Z" :fill="hover?hoverColor:iconColor"/>
        <path d="M31.645 36.4602H9.35498V31.7852H36.15L31.645 36.4602Z" :fill="hover?hoverColor:iconColor"/>
        <path d="M23.655 45.795C23.19 45.795 9.35498 45.795 9.35498 45.795V41.125H27.08C27.08 41.12 24.185 43.85 23.655 45.795Z" :fill="hover?hoverColor:iconColor"/>
        <path d="M43.52 4.405H41.565V0H36.895V4.405H27.6V0H22.93V4.405H13.515V0H8.845V4.405H7.01C3.145 4.405 0 7.55 0 11.415V52.97C0 56.835 3.145 59.98 7.01 59.98H20.22L21.215 55.31H7.01C5.72 55.31 4.675 54.26 4.675 52.975V11.415C4.675 10.125 5.725 9.08 7.01 9.08H8.845V10.54C8.845 11.83 9.89 12.875 11.18 12.875C12.47 12.875 13.515 11.83 13.515 10.54V9.08H22.925V10.54C22.925 11.83 23.97 12.875 25.26 12.875C26.55 12.875 27.595 11.83 27.595 10.54V9.08H36.885V10.54C36.885 11.83 37.93 12.875 39.22 12.875C40.51 12.875 41.555 11.83 41.555 10.54V9.08H43.51C44.8 9.08 45.845 10.13 45.845 11.415V23.135L50.515 27.8V11.415C50.525 7.55 47.385 4.405 43.52 4.405Z" :fill="hover?hoverColor:iconColor"/>
        <path d="M55.2699 39.25L45.4099 29.395L27.9599 46.845C27.6099 47.195 27.3649 47.6 27.2099 48.035L27.1949 48.02L27.1649 48.155C27.0999 48.37 27.0549 48.59 27.0349 48.81L24.7749 60L36.1699 57.58C36.7749 57.46 37.3499 57.17 37.8149 56.705L55.2699 39.25ZM45.4099 35.215L49.4499 39.255L35.5499 53.155L31.5099 49.115L45.4099 35.215Z" :fill="hover?hoverColor:iconColor"/>
    </svg>
</template>
<script>
export default {
    props: {
        width: {
            type: [Number, String],
            default: 18
        },
        height: {
            type: [Number, String],
            default: 18
        },
        iconColor: {
            type: String,
            default: '#F9820B'
        },
        hoverColor: {
            type: String,
            default: '#F9820B'
        },
        hover:{
            type: Boolean,
            default: false
        }
    }
}
</script>

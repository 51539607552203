<template>
  <v-app :class="withMenu() ? 'bg-theme-app' : ''">
    <v-app-bar v-if="withMenu()" id="header-page" clipped-left color="#ffffff" app>
      <header-page @retract="retract" />
    </v-app-bar>
    <div v-if="withMenu()" :class="breakPointApp ? '' : 'd-flex'">
      <v-navigation-drawer
        :id="getDrawer == false ? 'mini-menu' : 'drawer-mini-menu'"
        v-model="drawerResponsive"
        width="300px"
        :mini-variant.sync="mini"
        mini-variant-width="90px"
        :permanent="breakPointPermanent"
        :app="breakPointApp"
      >
        <div class="bg-white">
          <sidebar-page
            :key="sidebarKey"
            @resetMenu="forceRerender"
            :drawer="getDrawer"
          />
        </div>
      </v-navigation-drawer>
      <v-main :id="getDrawer == false ? 'menu-main' : 'drawer-menu-main'">
        <router-view />
      </v-main>
    </div>
    <v-main v-else>
      <router-view/>
    </v-main>
    <footer-page
      @resetMenu="forceRerender"
      v-if="withMenu()"
    />
  </v-app>
</template>

<script>
import HeaderPage from '@/components/templates/Header'
import SidebarPage from '@/components/templates/Sidebar'
import FooterPage from '@/components/templates/Footer'
import { mapGetters } from 'vuex';

export default {
  name: 'App',
  components: {
    HeaderPage,
    SidebarPage,
    FooterPage
  },
  beforeMount() {
    this.breakPointDrawer()
    this.breakPointMini()
    this.setBreakPointApp()
    this.setBreakPointPermanent()
  },
  data: () => ({
    drawerResponsive: true,
    mini: true,
    breakPointApp: false,
    breakPointPermanent: true,
    sidebarKey: 0
  }),
  computed: {
    ...mapGetters('auth', ['getSession', 'getDrawer']),
  },
  methods: {
    forceRerender () {
      this.sidebarKey += 1
    },
    withMenu () {
      if (this.$router.currentRoute.name == 'login'||
          this.$router.currentRoute.name == 'not-found'){
        return false
      }else{
        return true
      }
    },
    retract () {
      this.$store.commit('auth/setDrawer', !this.getDrawer)
      this.drawerResponsive = !this.getDrawer
      this.breakPointMini()
    },
    breakPointMini () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          this.mini = false
          break
        case 'sm':
          this.mini = false
          break
        case 'md':
          this.mini = false
          break
        case 'lg':
          this.mini = true
          break
        case 'xl':
          this.mini = true
          break
      }
    },
    breakPointDrawer () {
      if (
        this.$vuetify.breakpoint.name === 'xs' ||
        this.$vuetify.breakpoint.name === 'sm' ||
        this.$vuetify.breakpoint.name === 'md'
      ) {
        this.drawerResponsive = false
      } else {
        this.drawerResponsive = true
      }
    },
    setBreakPointApp () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          this.breakPointApp = true
          break
        case 'sm':
          this.breakPointApp = true
          break
        case 'md':
          this.breakPointApp = true
          break
        case 'lg':
          this.breakPointApp = false
          break
        case 'xl':
          this.breakPointApp = false
          break
      }
    },
    setBreakPointPermanent () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          this.breakPointPermanent = false
          break
        case 'sm':
          this.breakPointPermanent = false
          break
        case 'md':
          this.breakPointPermanent = false
          break
        case 'lg':
          this.breakPointPermanent = true
          break
        case 'xl':
          this.breakPointPermanent = true
          break
      }
    }
  }
};
</script>

<style>
@media screen and (min-width: 1264px) {
  #drawer-mini-menu {
    flex-basis: 300px;
  }
  #mini-menu {
    flex-basis: 90px;
  }
  #drawer-menu-main {
    flex: auto;
    width: 50%;
  }
  #menu-main {
    flex: auto;
    width: 50%;
  }
  .theme--light.v-navigation-drawer .v-navigation-drawer__content {
    height: min-content;
  }
  .theme--light.v-navigation-drawer .v-navigation-drawer__border {
    display: none;
  }
}

.f-orange {
  color: #F54C11;
}

.w-100 {
  width:100%;
}

.h-100 {
  height:100%;
}

.v-application .btn-filter-theme {
  border-color: #9e9e9e !important;
  border-style: solid;
  border-width: thin;
}

.v-application .btn-filter-theme:hover {
  border-color: #242424 !important;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.theme--light .theme--light.v-navigation-drawer {
  background-color: transparent;
}
.theme--light.v-navigation-drawer .v-navigation-drawer__content {
  background-color: #fff;
}
#header-page.v-app-bar.v-app-bar--fixed {
  z-index: 8;
}
.bg-theme-app.theme--light.v-application {
  background-color: #f5f5f5;
}
.text-transform-none {
  text-transform: none;
}
.menu-main {
  padding: 64px 0px 0px 300px;
}
.v-application .f-orange {
  color: #f54c11;
}

#app .v-application .primary--text {
  color: #f54c11 !important;
  caret-color: #f54c11 !important;
}
</style>

<template>
  <v-col cols="12" lg="6" md="8" sm="10" class="">
    <div class="table-responsive">
      <table class="table table-responsive align-middle">
        <tbody>
          <tr scope="row">
            <td class="text-center border col-4 col-md-2">Id do Orçamento</td>
            <td class="text-center border col-4">
              {{ coupon.budget_id }}
            </td>
            <td class="text-center border col-4"></td>
          </tr>

          <tr scope="row">
            <td class="text-center border">Nome do Consultor</td>
            <td class="text-center border">
              {{ coupon.budget_sou.consultantName }}
            </td>
            <td class="text-center border"></td>
          </tr>

          <tr scope="row">
            <td class="text-center border">Tipo de Pagamento</td>
            <td class="text-center border">
              {{ coupon.payment_type }}
            </td>
            <td class="text-center border"></td>
          </tr>

          <tr scope="row">
            <td class="text-center border">Valor do Orçamento</td>
            <td class="text-center border">
              {{
                parseFloat(coupon.budget_value).toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })
              }}
            </td>
            <td class="text-center border"></td>
          </tr>

          <tr scope="row">
            <td class="text-center border">Valor do Repasse</td>
            <td class="text-center border">
              {{
                parseFloat(this.budget.transferValue).toLocaleString(
                  "pt-br",
                  {
                    style: "currency",
                    currency: "BRL",
                  }
                )
              }}
            </td>
            <td class="text-center border"></td>
          </tr>

          <tr scope="row">
            <td class="text-center border">Valor do Equipamento</td>
            <td class="text-center border">
              {{
                parseFloat(coupon.value_equipment).toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })
              }}
            </td>
            <td class="text-center border boder-left">
              {{
                parseFloat(coupon.competitor.valueBudget).toLocaleString(
                  "pt-br",
                  {
                    style: "currency",
                    currency: "BRL",
                  }
                )
              }}
            </td>
          </tr>

          <tr scope="row">
            <td class="text-center border">Tipo do Gerador</td>
            <td class="text-center border">
              {{
                coupon.generator_type == "Múltiplos"
                  ? coupon.generator_type
                  : coupon.generator_type == "mono"
                  ? "Monofásico"
                  : "Trifásico"
              }}
            </td>
            <td class="text-center border">
              {{ coupon.competitor.typeStructure }}
            </td>
          </tr>

          <tr scope="row">
            <td class="text-center border">Marca do(s) Inversore(s)</td>
            <td class="text-center border">
              {{ coupon.budget_sou.brandInverter }}
            </td>
            <td class="text-center border">
              {{ coupon.competitor.brandInverter }}
            </td>
          </tr>

          <tr scope="row">
            <td class="text-center border">Quantidade de Inversores</td>
            <td class="text-center border">
              {{ coupon.budget_sou.amountInverters }}
            </td>
            <td class="text-center border">
              {{ coupon.competitor.amountInverter }}
            </td>
          </tr>

          <tr scope="row">
            <td class="text-center border">Potência do(s) Inversor(es)</td>
            <td class="text-center border">
              {{ coupon.budget_sou.powerInverter }} kWp
            </td>
            <td class="text-center border">
              {{ coupon.competitor.powerInverter }} kWp
            </td>
          </tr>
          <tr scope="row">
            <td class="text-center border">Marca dos Painéis</td>
            <td class="text-center border">
              {{ coupon.budget_sou.brandPanel }}
            </td>
            <td class="text-center border">
              {{ coupon.competitor.brandPanel }}
            </td>
          </tr>

          <tr scope="row">
            <td class="text-center border">Quantidade de Painéis</td>
            <td class="text-center border">
              {{ coupon.budget_sou.amountPanel }}
            </td>
            <td class="text-center border">
              {{ coupon.competitor.amountPanel }}
            </td>
          </tr>

          <tr scope="row">
            <td class="text-center border">Potência dos Painéis</td>
            <td class="text-center border">
              {{ coupon.budget_sou.powerPanel }} W
            </td>
            <td class="text-center border">
              {{ coupon.competitor.powerPanel }} W
            </td>
          </tr>

          <tr scope="row">
            <td class="text-center border">Valor do kWp</td>
            <td class="text-center border">
              {{
                (
                  this.budget.equipamentValue /
                  this.budget.powerSystem ? false : 0
                ).toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })
              }}
            </td>
            <td class="text-center border">
              {{
                coupon.competitor.wattValue.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })
              }}
            </td>
          </tr>

          <tr scope="row">
            <td class="text-center border">Cobertura/Desconto</td>
            <td class="text-center border">
              {{
                coupon.competitor.valueRoof.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })
              }}
            </td>
            <td class="text-center border">
              {{
                parseFloat(
                  coupon.budget_sou.value - coupon.budget_sou.valueRoof ? false : 0
                ).toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })
              }}
            </td>
          </tr>

          <tr scope="row">
            <td class="text-center border">PDF Orçamento</td>
            <td class="text-center border">
              <!-- <v-btn
                class="btn btn-small"
                height="50"
                small
                elevation="0"
                color="#00bb2d"
                @click="downloadPdfSouEnergy(coupon.budget_id)"
              >
                <p class="my-auto text-white">Orçamento SouEnergy</p>
              </v-btn> -->
            </td>
            <td class="text-center">
              <v-btn
                class="btn btn-small"
                small
                height="50"
                elevation="0"
                color="#00bb2d"
                @click="downloadPdfCompetitor()"
              >
                <p class="my-auto text-white">Orçamento Concorrente</p>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </v-col>
</template>


<script>

import {mapGetters} from "vuex";

export default {
  name: 'DetailsCompetitor',
  props: {
    coupon: {
      generated_id: Number,
      budget_id: Number,
      budget_value: Number,
      discount: Number,
      discount_value: Number,
      initial_discount: Number,
      discount_total: Number,
      coupon_code: String,
      admin_id: Number,
      rca_id: Number,
      rule_id: Number,
      coupon_name: String,
      integrator_id: Number,
      description: String,
      integrator_trade: String,
      integrator_trade_name: String,
      integrator_name: String,
      active: Boolean,
      max_discount: Number,
      value_equipment: Number,
      budget_sou: undefined,
      generator_type: String,
      competitor: undefined,
      coupon_type: String,
      payment_type: String,
      status: String,
      coupons_added: undefined,
      competition_budget_pdf: String
    },
  },
  computed: {
    //...mapGetters('coupon', {competitor: "getCompetitor",}),
    ...mapGetters('coupon', {budget: "getBudget"}),
  },
  methods: {
    downloadPdfCompetitor() {
      this.$store.dispatch("coupon/downloadPdf", { "pdf_competition" : this.coupon.competition_budget_pdf})
          .then((response) => {
            console.log(response)
            window.open(window.URL.createObjectURL(response), "_blank");
          });
    }
  }
}

</script>


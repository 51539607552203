export default {
  orders: [],
  paramsOrders: {
    page: 1,
    perPage: 20,
    totalPage: 1,
    order: 'DESC',
    column: 'order_id',
  },
  filters: {
    filter_order_id: '',
    filter_business_name: '',
    filter_taxvat:'',
    filter_billing_region:'',
    filter_billing_full: '',
    filter_order_status: '',
    filter_payment_method: '',
    filter_commission: {
      min:'',
      max: ''
    },
    filter_created_at: {
      min: '',
      max: ''
    },
    filter_base_grand_total: {
      min: '',
      max:''
    },
    filter_grand_total: {
      min: '',
      max:''
    },
    filter_billing_address: '',
    filter_shipping_address: '',
    filter_email_customer:'',
    filter_customer_group: '',
    filter_subtotal: {
      min: '',
      max: ''
    },
    filter_total_refunded: {
      min: '',
      max: ''
    },
    filter_id_order_web_service: '',
    filter_customer_id: '',
    filter_seller_rca: '',
    filter_seller_name: '',
    filter_prevenda: {
      min: '',
      max: ''
    },
    filter_shipping_forecast: {
      min: '',
      max: ''
    },
    filter_status_stock: '',
    // filter_nsu: '',
    // filter_offline_method: '',
    filter_credit_letter: ''
  },
  order_status_options: ['ab', 'ac', 'ad', 'ae', 'af', 'ag', 'ah'],
  payment_method_options: ['ai', 'aj', 'ak', 'al', 'am', 'an', 'ao'],
  customer_group_options: ['ap', 'aq', 'ar', 'as', 'at', 'au', 'av'],
  // offline_method_options: ['aw', 'ax', 'ay','az', 'aa'],
  credit_letter_options: ['não', 'sim']
}
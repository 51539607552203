const defaultFilter = {
  filter_order_id: '',
  filter_business_name: '',
  filter_taxvat:'',
  filter_billing_region:'',
  filter_billing_full: '',
  filter_order_status: '',
  filter_payment_method: '',
  filter_commission: {
    min:'',
    max: ''
  },
  filter_created_at: {
    min: '',
    max: ''
  },
  filter_base_grand_total: {
    min: '',
    max:''
  },
  filter_grand_total: {
    min: '',
    max:''
  },
  filter_billing_address: '',
  filter_shipping_address: '',
  filter_email_customer:'',
  filter_customer_group: '',
  filter_subtotal: {
    min: '',
    max: ''
  },
  filter_total_refunded: {
    min: '',
    max: ''
  },
  filter_id_order_web_service: '',
  filter_customer_id: '',
  filter_seller_rca: '',
  filter_seller_name: '',
  filter_prevenda: {
    min: '',
    max: ''
  },
  filter_shipping_forecast: {
    min: '',
    max: ''
  },
  filter_status_stock: '',
  // filter_nsu: '',
  // filter_offline_method: '',
  filter_credit_letter: ''
}

export default {
  setOrders (state, orders) {
    state.orders = orders
  },
  setPage (state, page) {
    state.paramsOrders.page = page
  },
  setPerPage (state, perPage) {
    state.paramsOrders.perPage = perPage
  },
  setFilterTextAndSelect(state, params) {
    switch (params.filter) {
      case 'filter_order_id':
        state.filters.filter_order_id = params.value
        break
      case 'filter_business_name':
        state.filters.filter_business_name = params.value
        break
      case 'filter_taxvat':
        state.filters.filter_taxvat = params.value
        break
      case 'filter_billing_region':
        state.filters.filter_billing_region = params.value
        break
      case 'filter_billing_full':
        state.filters.filter_billing_full = params.value
        break
      case 'filter_order_status':
        state.filters.filter_order_status = params.value
        break
      case 'filter_payment_method':
        state.filters.filter_payment_method = params.value
        break
      case 'filter_billing_address':
        state.filters.filter_billing_address = params.value
        break
      case 'filter_shipping_address':
        state.filters.filter_shipping_address = params.value
        break
      case 'filter_email_customer':
        state.filters.filter_email_customer = params.value
        break
      case 'filter_customer_group':
        state.filters.filter_customer_group = params.value
        break
      case 'filter_id_order_web_service':
        state.filters.filter_id_order_web_service = params.value
        break
      case 'filter_customer_id':
        state.filters.filter_customer_id = params.value
        break
      case 'filter_seller_rca':
        state.filters.filter_seller_rca = params.value
        break
      case 'filter_seller_name':
        state.filters.filter_seller_name = params.value
        break
      case 'filter_status_stock':
        state.filters.filter_status_stock = params.value
        break
      // case 'filter_nsu':
      //   state.filters.filter_nsu = params.value
      //   break
      // case 'filter_offline_method':
      //   state.filters.filter_offline_method = params.value
      //   break
      case 'filter_credit_letter':
        state.filters.filter_credit_letter = params.value
        break
    }
  },

  setFilterTextDuo (state, params) {
    switch (params.filter) {
      case 'filter_base_grand_total':
        state.filters.filter_base_grand_total.min = params.value.min
        state.filters.filter_base_grand_total.max = params.value.max
        break
      case 'filter_grand_total':
        state.filters.filter_grand_total.min = params.value.min
        state.filters.filter_grand_total.max = params.value.max
        break      
      case 'filter_subtotal':
        state.filters.filter_subtotal.min = params.value.min
        state.filters.filter_subtotal.max = params.value.max
        break
      case 'filter_total_refunded':
        state.filters.filter_total_refunded.min = params.value.min
        state.filters.filter_total_refunded.max = params.value.max
        break
    }
  },

  setFilterDate (state, params) {
    switch (params.filter) {
      case 'filter_commission':
        state.filters.filter_commission.min = params.value.min
        state.filters.filter_commission.max = params.value.max
        break
      case 'filter_created_at':
        state.filters.filter_created_at.min = params.value.min
        state.filters.filter_created_at.max = params.value.max
        break
      case 'filter_prevenda':
        state.filters.filter_prevenda.min = params.value.min
        state.filters.filter_prevenda.max = params.value.max
        break
      case 'filter_shipping_forecast':
        state.filters.filter_shipping_forecast.min = params.value.min
        state.filters.filter_shipping_forecast.max = params.value.max
        break
    }
  },

  cleanFilter (state) {
    state.filters = defaultFilter
  }
}